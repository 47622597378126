import SearchBar from "../SearchBar/SearchBar";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./ItemForm.css";
import { API, AVATAR_API, BEARER } from "../../../constants";
import { getToken } from "../../../helpers";


function ItemForm(props) {
  const [value, setValue] = useState([]);
  const [value2, setValue2] = useState([]);
  const [value3, setValue3] = useState([]);
  const [namesData, setNamesData] = useState([]);
  const [persoonData, setPersoonData] = useState([]);

  function bewerkPersoon(x) {
    const persoonID = x.target.value;
    const url = `${API}/users/${persoonID}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToken()}`,
      }
    })
        .then(response => response.json())
        .then(persoonData => {
          setPersoonData(persoonData);
          // console.log(persoonData);
        })
        .catch(error => {
          console.error("error fetching names data: ", error);
        })
  }

  // function handleChange(e) {
  //   const newValue = e.target.value;
  //   console.log(newValue);
  //   if (localStorage.getItem("itemsList") === null) {
  //     if (Array.isArray(value)) {
  //       setValue([...value, newValue]);
  //       console.log(value);
  //     } else {
  //     setValue([newValue]);
  //     }
  //     console.log(value);
  //     localStorage.setItem("itemsList", value);
  //   } 
  //   if (localStorage.getItem("itemsList") != null) {
  //     if (Array.isArray(value)) {
  //       setValue(localStorage.getItem("itemsList"));
  //       setValue([...value, newValue]);
  //     }
      
  //   }
    
    
  // }
  function calculateKans() {

  }

  function compareTimes() {
    const storedTimesList = JSON.parse(localStorage.getItem("timesList"));
    const storedItemsList = JSON.parse(localStorage.getItem("itemsList"));
    const storedNamesList = JSON.parse(localStorage.getItem("namesList"));
  
    if (Array.isArray(storedTimesList) && Array.isArray(storedItemsList) && Array.isArray(storedNamesList)) {
      if (storedTimesList.length === storedItemsList.length && storedTimesList.length === storedNamesList.length) {
        const oldestDateIndex = storedTimesList
          .map((date) => new Date(date))
          .reduce((oldestIndex, currentDate, currentIndex) =>
            currentDate < new Date(storedTimesList[oldestIndex]) ? currentIndex : oldestIndex
          , 0);
        const oldestItem = storedItemsList[oldestDateIndex];
        const oldestName = storedNamesList[oldestDateIndex];
        // console.log("Oldest date in timesList:", storedTimesList[oldestDateIndex]);
        // console.log("Corresponding oldest item in itemsList:", oldestItem);
        // console.log("Corresponding oldest name in namesList:", oldestName);
        const newValue = oldestItem;
        const newValueNames = oldestName;
        const newValueTimes = storedTimesList[oldestDateIndex];
        // console.log(newValueNames);
        storedItemsList.push(newValue);
        storedNamesList.push(newValueNames);
        storedTimesList.push(newValueTimes);
      
        setValue(storedItemsList);
        setValue2(storedNamesList);
        setValue3(storedTimesList);
        localStorage.setItem("namesList", JSON.stringify(storedNamesList));
        localStorage.setItem("itemsList", JSON.stringify(storedItemsList));
        localStorage.setItem("timesList", JSON.stringify(storedTimesList));
        // console.log(value2);
        // console.log(storedTimesList);
      } else {
        if(!alert('timesList, itemsList, and namesList have different lengths')){window.location.reload();}
        console.log("timesList, itemsList, and namesList have different lengths");
      }
    } else {
      alert("timesList, itemsList, or namesList is not an array");
      console.log("timesList, itemsList, or namesList is not an array");
    }
  }
  
  
  
  
  
  
  

  function handleChange(e) {
    const newValue = e.target.value;
    const newValueNames = e.target.getAttribute('data-key');
    const newValueTimes = e.target.getAttribute('data-time');
    let storedItems = localStorage.getItem("itemsList");
    let storedNamesList = localStorage.getItem("namesList");
    let storedTimesList = localStorage.getItem("timesList");
  
    if (storedItems === null) {
      storedItems = [];
    } else {
      storedItems = JSON.parse(storedItems);
    }
  
    if (storedNamesList === null) {
      storedNamesList = [];
    } else {
      storedNamesList = JSON.parse(storedNamesList);
    }
  
    if (storedTimesList === null) {
      storedTimesList = [];
    } else {
      storedTimesList = JSON.parse(storedTimesList);
    }
    // console.log(newValueNames);
    storedItems.push(newValue);
    storedNamesList.push(newValueNames);
    storedTimesList.push(newValueTimes);
  
    setValue(storedItems);
    setValue2(storedNamesList);
    setValue3(storedTimesList);
    localStorage.setItem("namesList", JSON.stringify(storedNamesList));
    localStorage.setItem("itemsList", JSON.stringify(storedItems));
    localStorage.setItem("timesList", JSON.stringify(storedTimesList));
    // console.log(value2);
    // console.log(storedTimesList);
  
    // Compare times
    // const currentTime = new Date(); // Get the current time
  
    // // Check if parsedTimesList is an array and not empty
    // if (Array.isArray(storedTimesList) && storedTimesList.length > 0) {
    //   // Sort the dates in ascending order
    //   storedTimesList.sort((a, b) => new Date(a) - new Date(b));
  
    //   // Get the oldest date (the first element in the sorted array)
    //   const oldestDate = new Date(storedTimesList[0]);
  
    //   // Compare the current time to the oldest date
    //   if (currentTime > oldestDate) {
    //     console.log("Current time is greater than the oldest date in timesList.");
    //   } else {
    //     console.log("Oldest date in timesList is greater than or equal to the current time.");
    //   }
    // } else {
    //   console.log("timesList is empty or not an array");
    // }
  }
  
  
  useEffect(() => {
    // console.log(fetchedlist)
            Shuffle();
}, []);
  
  function Shuffle() {
    const url = `${API}/users?filters[meedoen][$eq]=true`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      })
        .then(response => response.json())
        .then(namesData => {
          setNamesData(namesData);
          // console.log(namesData)
        })
        .catch(error => {
          console.error("error fetching names data: ", error);
        })
        // console.log(namesData);
        // var array = namesData.map((namen) =>  {namen.attributes.naam});
    var array = namesData;
    let currentIndex = namesData.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    setValue(array);
  }

  function RemoveDuplicate() {
    let uniqueItems = [...new Set(value.map((s) => s.trim()))];
    setValue(Array.from(uniqueItems));
  }

  return (
    <div className="mt-10">
      <div
        className="
      d-flex 
      flex-column 
      flex-sm-column 
      flex-md-column 
      flex-lg-row 
      justify-content-lg-start 
     "
      >
        <button
          id="shuffleButton"
          className="
          btn btn-primary
          flex-lg-grow-1 
          me-lg-2
          mb-md-2 
          mb-sm-2 
          mb-lg-0
          mb-2"
          variant="light"
          onClick={Shuffle}
        >
          <i className="bi bi-shuffle me-3"></i>Voeg Namen Toe
        </button>
        {/* <button
          id="removeButton"
          className="btn btn-primary flex-lg-grow-1"
          variant="light"
          onClick={RemoveDuplicate}
        >
          <i class="bi bi-trash me-3"></i>
          Remove duplicate
        </button> */}
      </div>
      {/* <SearchBar placeholder="Search..." data={value} /> */}
      {
      
    }
      {/* <textarea
        id="inputTextArea"
        className="form-control w-100 mt-2 p-10"
        value={value?.join("\n")}
        onChange={handleChange}
        rows={17}
        cols={15}
      ></textarea> */}
      {
                        (namesData ?? []).map((namen) =>
                            <div className="
                            d-flex 
                            flex-column 
                            flex-sm-column 
                            flex-md-column 
                            flex-lg-row 
                            justify-content-lg-start 
                           ">
                            {/* <button className="btn btn-primary
          flex-lg-grow-1 
          me-lg-2
          mb-md-2 
          mb-sm-2 
          mb-lg-0
          mb-2" key={namen.id} value={namen.attributes.naam} onClick={handleChange}>Voeg {namen.attributes.naam} toe</button>
                            <button className="btn btn-primary flex-lg-grow-1" key={namen.id} value={namen.id} onClick={bewerkPersoon}>Wijzig {namen.attributes.naam}</button> */}
                            {/* <button className="btn btn-primary disableElement w-100 mt-2" key={namen.username} data-key={namen.username} value={namen.id} onClick={handleChange}>Voeg {namen.username} toe</button> */}
                            <button className="btn btn-primary disableElement w-100 mt-2" data-time={namen.laatsteBestelTijd} key={namen.username} data-key={namen.username} value={namen.id} onClick={handleChange}>Voeg {namen.username} toe</button>

                            </div>
                        )
                        // (namesData ?? []).map((namen) =>
                        //     <button className="btn btn-primary disableElement w-100 mt-2" key={namen.id} value={namen.id} onClick={bewerkPersoon}>Wijzig {namen.attributes.naam}</button>
                        // )
                    }
                    <button

id="calculateKans"
className="btn btn-primary disableElement w-100 mt-2"
onClick={compareTimes}
>
kans berekenen
</button>
      <button
        id="updateButton"
        className="btn btn-primary disableElement w-100 mt-2"
        onClick={() => {
          props.onClick(value2);
        }}
      >
        Update
      </button>
      
    </div>
  );
}

export default ItemForm;
import useSocketConnection from "../../socket";
import React, { useEffect, useState } from "react";
import { API } from "../../constants";
import { getToken } from "../../helpers";
import { useAuthContext } from "../../context/AuthContext";
import {
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import "./stats.css"
import { CheckCircleTwoTone } from "@mui/icons-material";
import { BEARER } from "../../constants";
import useProfileStats from "../Level/levels";
const Board = () => {
    const { user, setUser } = useAuthContext();
    const [profiles, setProfiles] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [levels, setLevels] = useState([])
    const socket = useSocketConnection();
    const check = useProfileStats();




    const fetchProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users?populate=*`, {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            });
            const data = await response.json();
            setProfiles(data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLevel = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(`${API}/levels?populate=*`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setLevels(data.data ?? []);
            // console.log(level)
        } catch (error) {
            console.error(error);
            message.error("Error while fetching levels!");
        } finally {
            setIsLoading(false)
        }
    }




    useEffect(() => {
        fetchProfiles();
        fetchLevel()
    }, [user]);

    useEffect(() => {
        // Listen for socket events and handle them
        if (socket) {
            socket.on("connect", () => {
                socket.on("users-permissions:update", (data) => {
                    fetchProfiles();
                    // console.log("test")

                });

                socket.on("disconnect", () => {
                    // console.log("Socket disconnected");
                });
            });
        }
    }, [socket]);

    return (
        <div className="Board">
            <div className="table">
                <h1 className="leaderboard">Statistieken</h1>
                <div className="table-header">
                    <span className="header">Naam</span>
                    <span className="header">Xp</span>
                    <span className="header">Level</span>
                    <span className="header">Laatsupdate</span>
                </div>
                {profiles.map((profile, index) => (
                    <div className={`profiles ${index % 2 === 0 ? 'even-row' : 'odd-row'}`} key={index}>
                        <span>{profile.username}</span>
                        <span>{profile.xp}</span>
                        <span>{profile?.levels.map((ja) => (
                            ja.level
                        ))}</span>
                        <span>{profile.updatedAt.split("T")[0]}</span>

                    </div>
                ))}
                {/* <button onClick={updateLevel}>Levels</button> */}
            </div>
        </div>


    )



}



export default Board;
import {
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import { AddCircleOutline } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { API } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
// import "../Producten/Producten.css"
import "../Producten/Producten.css"
import Popup from "./popup";
import Bestellijst from "./bestellijst";
import { Nav } from "react-bootstrap";
import NavMenu from "../../components/Appheader/NavMenu";
import { BEARER } from "../../constants";
import ScrollButton from "../../components/scrollToTop/ScrollButton";


const Producten = () => {
    const [snack, setSnack] = useState([]);
    const [patat, setPatat] = useState([]);
    const [raspatat, setRasPatat] = useState([]);
    const [halalsnack, setHalalSnack] = useState([]);
    const [burger, setBurger] = useState([]);
    const [broodje, setBroodjes] = useState([]);
    const [extras, setExtras] = useState([]);
    const [milkshake, setMilkshakes] = useState([]);
    const [dranken, setDranken] = useState([]);
    const [profile, setProfiles] = useState([]);
    const { user, setUser } = useAuthContext();
    const [isLoadingg, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [IDmimi, setIDmimi] = useState("");
    const [desiredProduct, setDesiredProduct] = useState("");
    const [desiredProductPrice, setDesiredProductPrice] = useState("");
    const [updatingDatabase, setUpdatingDatabase] = useState(false);
    const [counter, setCounter] = useState(60);
    const [updateBestellijst, setUpdateBestellijst] = useState(false);
    const [orders, setOrders] = useState([]);
    const [populair, setPopulair] = useState([]);
    const [populairPerson, setPopulairPerson] = useState([]);
    const [threeProducts, setThreeProducts] = useState([]);
    const [threeProductsPerson, setThreeProductsPerson] = useState([]);
    const [products, setProducts] = useState([]);
    const [guestName, setGuestName] = useState("");

    const fetchProducts = async () => {
        try {
            // console.log("getscalled", user?.id);
            const response = await fetch(`${API}/products?populate=*`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setProducts(data.data ?? []);
            // console.log(data.data);


        } catch (error) {
            // console.log(error);
        } finally {
            // console.log("finally")
            // products.forEach((product) => {
            //     console.log(product)
            // })

        }
    };

    const fetchPopulair = async () => {
        try{
            // console.log("getscalled", user?.id);
            const response = await fetch(`${API}/orders?populate=*`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${getToken()}`,
                }
              });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setOrders(data.data ?? []);
            console.log(data.data)

            // console.log(data.data);
            orderPopulairuseEffect(data.data);
        } catch(error) {
            // console.log(error);
        } finally {
            // console.log("finally")
            
        }
    };
    // console.log(orders)
    const fetchPopulairProductenPerson = async (product1, product2, product3, topThreeProductsPerson) => {
        try{
            // console.log(product1, product2, product3);
            const response = await fetch(`${API}/products?filters[$or][0][name][$eq]=${product1?.name}&filters[$or][1][name][$eq]=${product2?.name}&filters[$or][2][name][$eq]=${product3?.name}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${getToken()}`,
                }
              });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const dataToSort = data.data ?? [];
            // console.log(threeProductsPerson);
            const populairInOrder = topThreeProductsPerson.map(product => dataToSort.find(item => item.attributes.name === product.name));
            // setPopulairPerson(populairInOrder ?? []);
            console.log("personpopulairinorder", populairInOrder);
            if(populairInOrder[0] !== undefined && populairInOrder[1] !== undefined && populairInOrder[2] !== undefined) {
                setPopulairPerson(populairInOrder ?? []);
            }

            // console.log(dataToSort, populairInOrder);
            
        } catch(error) {
console.log(error);
        }
    };

    const fetchPopulairProducten = async (product1, product2, product3, topThreeProducts) => {
        try{
            // console.log(product1, product2, product3);
            const response = await fetch(`${API}/products?filters[$or][0][name][$eq]=${product1.name}&filters[$or][1][name][$eq]=${product2.name}&filters[$or][2][name][$eq]=${product3.name}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${getToken()}`,
                }
              });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const dataToSort = data.data ?? [];
            // console.log(threeProducts);
            const populairInOrder = topThreeProducts.map(product => dataToSort.find(item => item.attributes.name === product.name));
            console.log("setpopulair", populairInOrder);
            if(populairInOrder[0] !== undefined && populairInOrder[1] !== undefined && populairInOrder[2] !== undefined) {
                setPopulair(populairInOrder ?? []);
            }
            // setPopulair(populairInOrder ?? []);

            // setPopulair(populairInOrder ?? []);
            // console.log(dataToSort, populairInOrder);
            // console.log(data.data);
        } catch(error) {
console.log(error);
        } finally {
            fetchProducts();
            // fetchPatat();
            // fetchRasPatat();
            // fetchSnack();
            // fetchHalalSnacks();
            // fetchBurgers();
            // fetchBroodjes();
            // fetchExtras();
            // fetchMilkshakes();
            // fetchDrankjes();
            // fetchExtraPopulair();
        }
    };

    const fetchExtraPopulair = async () => {
        // try {
        //     console.log(populair, populairPerson);
        //     if(populair.length ===0){
        //         fetchPopulair();
        //     } else if(populairPerson.length ===0){
        //         fetchPopulair();
        //     }
            
        // } catch (error) {
        //     console.error(error);
        // } finally {

        // }
    };

//   useEffect(() => {
//     fetch(`${API}/orders`, {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//         }
//       }) // Replace with your actual endpoint
//       .then(response => response.json())
//       .then(data => {
//         setOrders(data); 
//       })
//       .catch(error => {
//         console.error('Error fetching orders:', error);
//       });
//   }, []);

//   useEffect(() => {
//     if (orders.length > 0) {
//       // Logic to find the most occurring product name
//       const productOccurrences = orders.reduce((acc, order) => {
//         const productName = order.attributes.productnaam;
//         acc[productName] = (acc[productName] || 0) + 1;
//         console.log(acc);
//         return acc;
//       }, {});

//       // Find the product with the most occurrences
//       const mostOccurringProduct = Object.keys(productOccurrences).reduce((a, b) =>
//         productOccurrences[a] > productOccurrences[b] ? a : b
//       );

//       console.log('Most occurring product:', mostOccurringProduct);
//     }
//   }, [orders]);

const orderPopulairuseEffect = async (orderdata) => {
    // console.log(orderdata);
    // console.log(user);
        if (orderdata.length > 0) {
          const productOccurrences = orderdata.reduce((acc, order) => {
              // console.log(order.attributes.users.data.attributes.username, user?.username);
            // if(order.attributes.users.data.attributes.username === user?.username){
            //     console.log("YESS");
            //            }
            const productName = order.attributes.productnaam;
            if (acc[productName]) {
                for (let i = 0; i < order.attributes.quantity; i++) {
                    acc[productName]++;
                }            
             
            } else {
              acc[productName] = order.attributes.quantity;
            }
            return acc;
          }, {});
          const productOccurrencesPerson = orderdata.reduce((ret, order) => {
            if (order.attributes && order.attributes.users && order.attributes.users.data && order.attributes.users.data.attributes && order.attributes.users.data.attributes.username === user?.username) {
                const productName = order.attributes.productnaam;
                if (ret[productName]) {
                    for (let x = 0; x < order.attributes.quantity; x++) {
                        ret[productName]++;
                    }
                } else {
                    ret[productName] = order.attributes.quantity;
                }
            }
            return ret;
        }, {});
        
    
          // Convert occurrences object to an array of objects
          const occurrencesArray = Object.keys(productOccurrences).map(product => ({
            name: product,
            occurrences: productOccurrences[product],
          }));
    
            //   console.log(productOccurrencesPerson);
          const occurrencesArrayPerson = Object.keys(productOccurrencesPerson).map(product => ({
            name: product,
            occurrences: productOccurrencesPerson[product],
          }));
          // Sort by occurrences in descending order
          occurrencesArray.sort((a, b) => b.occurrences - a.occurrences);
    
          occurrencesArrayPerson.sort((a, b) => b.occurrences - a.occurrences);
    
          // Retrieve the top three most occurring products
          const topThreeProducts = occurrencesArray.slice(0, 3);
    
          const topThreeProductsPerson = occurrencesArrayPerson.slice(0, 3);
    
            // console.log(topThreeProducts);
            //       console.log(topThreeProductsPerson);
          // Log the top three most occurring products
        //   console.log('Top three most occurring products:', topThreeProducts);
        setThreeProductsPerson(topThreeProductsPerson);
          setThreeProducts(topThreeProducts);
          fetchPopulairProductenPerson(topThreeProductsPerson[0], topThreeProductsPerson[1], topThreeProductsPerson[2], topThreeProductsPerson);
          fetchPopulairProducten(topThreeProducts[0], topThreeProducts[1], topThreeProducts[2], topThreeProducts);
        }
    
    
};

// useEffect(() => {
//     console.log(orders);
//     console.log(user);
//     if (orders.length > 0) {
//       const productOccurrences = orders.reduce((acc, order) => {
//         console.log(order.attributes.users.data.attributes.username, user?.username);
//         // if(order.attributes.users.data.attributes.username === user?.username){
//         //     console.log("YESS");
//         //            }
//         const productName = order.attributes.productnaam;
//         if (acc[productName]) {
//             for (let i = 0; i < order.attributes.quantity; i++) {
//                 acc[productName]++;
//             }            
         
//         } else {
//           acc[productName] = order.attributes.quantity;
//         }
//         return acc;
//       }, {});
//       const productOccurrencesPerson = orders.reduce((ret, order) => {
//         if (order.attributes && order.attributes.users && order.attributes.users.data && order.attributes.users.data.attributes && order.attributes.users.data.attributes.username === user?.username) {
//             const productName = order.attributes.productnaam;
//             if (ret[productName]) {
//                 for (let x = 0; x < order.attributes.quantity; x++) {
//                     ret[productName]++;
//                 }
//             } else {
//                 ret[productName] = order.attributes.quantity;
//             }
//         }
//         return ret;
//     }, {});
    

//       // Convert occurrences object to an array of objects
//       const occurrencesArray = Object.keys(productOccurrences).map(product => ({
//         name: product,
//         occurrences: productOccurrences[product],
//       }));

//       console.log(productOccurrencesPerson);
//       const occurrencesArrayPerson = Object.keys(productOccurrencesPerson).map(product => ({
//         name: product,
//         occurrences: productOccurrencesPerson[product],
//       }));
//       // Sort by occurrences in descending order
//       occurrencesArray.sort((a, b) => b.occurrences - a.occurrences);

//       occurrencesArrayPerson.sort((a, b) => b.occurrences - a.occurrences);

//       // Retrieve the top three most occurring products
//       const topThreeProducts = occurrencesArray.slice(0, 3);

//       const topThreeProductsPerson = occurrencesArrayPerson.slice(0, 3);

// console.log(topThreeProducts);
//       console.log(topThreeProductsPerson);
//       // Log the top three most occurring products
//     //   console.log('Top three most occurring products:', topThreeProducts);
//     setThreeProductsPerson(topThreeProductsPerson);
//       setThreeProducts(topThreeProducts);
//       fetchPopulairProductenPerson(topThreeProductsPerson[0], topThreeProductsPerson[1], topThreeProductsPerson[2]);
//       fetchPopulairProducten(topThreeProducts[0], topThreeProducts[1], topThreeProducts[2]);
//     }
//   }, [orders]);

    const fetchProfile = async () => {
        setIsLoading(true);
        try {
            if (user) { // Check if user is defined
                const response = await fetch(`${API}/users/${user.id}`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${getToken}`,
                    }
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setProfiles(data ?? []);
                // console.log(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            // checkBesteller();
        }
    };

    const checkBesteller =  () => {
        fetchPopulair();
            
    }


    useEffect(() => {
        getToken();
        // if (user) { // Check if user is defined
            fetchProfile();
            checkBesteller();
        // }

    }, [user]);


    //hier haalt hij de scrollbar weg wanneer de popup komt
    useEffect(() => {
        const body = document.body;
        if (isOpen) {
            body.style.overflow = 'hidden'; // Hide scrollbar when popup is open
        } else {
            body.style.overflow = 'auto'; // Show scrollbar when popup is closed
        }

        // Cleanup: Restore scrollbar when the component unmounts
        return () => {
            body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const refreshBestelling = () => {
        // console.log("ditdoetiewel");
        if (updateBestellijst === false){
            setUpdateBestellijst(true);
        } else if(updateBestellijst === true){
            setUpdateBestellijst(false);
        }
    };

    const renderProductList = (customHeading, category, productClass, buttonClass) => (
        <div>
            {customHeading && <h3 id={customHeading}>{customHeading}</h3>}
            <ul>
                {products
                    .filter((product) => product.attributes.category.data.attributes.name === category)
                    .map((product) => {
                        let listItemClass = 'list';
                        let buttonListItemClass = 'options-btn';

                        if (product.attributes.name === threeProducts[0].name) {
                            listItemClass = `listgold ${productClass}`;
                            buttonListItemClass = `options-btn-gold ${buttonClass}`;
                        } else if (product.attributes.name === threeProducts[1].name) {
                            listItemClass = `listsilver ${productClass}`;
                            buttonListItemClass = `options-btn-silver ${buttonClass}`;
                        } else if (product.attributes.name === threeProducts[2].name) {
                            listItemClass = `listbronze ${productClass}`;
                            buttonListItemClass = `options-btn-bronze ${buttonClass}`;
                        }

                        return (
                            <li key={product.id} className={listItemClass}>
                                {product.attributes.name} {product.attributes.price}
                                <button
                                    onClick={() => {
                                        setIsOpen(true);
                                        setDesiredProduct(product.attributes.name);
                                        setDesiredProductPrice(product.attributes.price);
                                        setIDmimi(product.attributes.IDmimi);
                                    }}
                                    className={buttonListItemClass}
                                    value={product.attributes.name}
                                >
                                    +
                                </button>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );

    const renderListItem = (patats, category, threeProducts) => {
        const className = getCategoryClassName(patats, threeProducts);

        return (
            <li className={className} key={patats.id}>
                {patats.attributes.name} {patats.attributes.price}
                <button
                    onClick={() => handleButtonClick(patats)}
                    className={`options-btn-${getMedalColor(patats, threeProducts)}`}
                    value={patats.attributes.name}
                >
                    +
                </button>
            </li>
        );
    };

    const handleButtonClick = (patats) => {
        setIsOpen(true);
        setDesiredProduct(patats.attributes.name);
        setDesiredProductPrice(patats.attributes.price);
        setIDmimi(patats.attributes.IDmimi);
    };

    const getCategoryClassName = (patats, threeProducts) => {
        if (patats.attributes.name === threeProducts[0]?.name) {
            return 'listgold';
        } else if (patats.attributes.name === threeProducts[1]?.name) {
            return 'listsilver';
        } else if (patats.attributes.name === threeProducts[2]?.name) {
            return 'listbronze';
        } else {
            return 'listbronze'; // Default class if not gold, silver, or bronze
        }
    };

    const getMedalColor = (patats, threeProducts) => {
        if (patats.attributes.name === threeProducts[0]?.name) {
            return 'gold';
        } else if (patats.attributes.name === threeProducts[1]?.name) {
            return 'silver';
        } else if (patats.attributes.name === threeProducts[2]?.name) {
            return 'bronze';
        } else {
            return ''; // Default color if not gold, silver, or bronze
        }
    };



    return (
        <div className="producten">
            <label>Vul naam in van persoon: </label>
            <input
                type="text"
                value={guestName}
                onChange={(e) => {
                    setGuestName(e.target.value);
                    console.log(e.target.value); // Log the guestName as the user types
                }}
            />
            <br></br>
            <br></br>
            <NavMenu id="navMenu" />
            <ul><li className="block glow"></li></ul>
            <>
                <div>
                    <h5 id="algemeenpopulair">algemeen</h5>
                    <ul>
                        {populair.map((patats) => renderListItem(patats, 'algemeen', threeProducts))}
                    </ul>
                </div>

                <div>
                    <h5 id="persoonlijkpopulair">persoonlijk</h5>
                    <ul>
                        {populairPerson.map((patats) => renderListItem(patats, 'persoonlijk', threeProductsPerson))}
                    </ul>
                </div>
            </>

            {renderProductList('patat', 'patat', '', 'options-btn')}
            {renderProductList('raspatat', 'raspatat', '', 'options-btn')}
            {renderProductList('snacks', 'snacks', '', 'options-btn')}
            {renderProductList('halalsnacks', 'halal-snacks', '', 'options-btn')}
            {renderProductList('burger', 'burgers', '', 'options-btn')}
            {renderProductList('broodjes', 'broodjes', '', 'options-btn')}
            {renderProductList('extras', 'saus-extras', '', 'options-btn')}
            {renderProductList('milkshake', 'milkshake-ijs', '', 'options-btn')}
            {renderProductList('dranken', 'dranken', '', 'options-btn')}


            {isOpen && (
                <div className="overlay-popup">
                    <Popup onClose={() => setIsOpen(false)} isOpen={isOpen} guestName={guestName} desiredProduct={desiredProduct} desiredProductPrice={desiredProductPrice} IDmimi={IDmimi} refreshBestelling={refreshBestelling}>
                    </Popup>
                </div>
            )}
            <ScrollButton className="scrollbutton" />
            <Bestellijst updateBestellijst={updateBestellijst}/>
        </div>

    );
};

export default Producten;

import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Spin,
    Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'; // Import Axios
import { API } from "../../constants";

const Reset = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            // Send a reset password request to your server using Axios
            await axios.post(`${API}/auth/forgot-password`, {
                email: values.email,
                // url: `${API}/auth/reset-password`,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            // Handle success
            // console.log('A password reset email has been sent');
            setSuccessMessage("Password reset email sent. Check your inbox.");
        } catch (error) {
            // Handle error
            console.error('An error occurred:', error.response);
            setErrorMessage("Something went wrong!");
            console.log(error.response);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <Row align="middle">
                <Col span={8} offset={8}>
                    <Card title="Forgot Password">
                        {errorMessage && (
                            <Alert
                                className="alert_error"
                                message={errorMessage}
                                type="error"
                                closable
                                afterClose={() => setErrorMessage("")}
                            />
                        )}
                        {successMessage && (
                            <Alert
                                className="alert_success"
                                message={successMessage}
                                type="success"
                                closable
                                afterClose={() => setSuccessMessage("")}
                            />
                        )}
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Please enter a valid email address.",
                                    },
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="reset_submit_btn"
                                    loading={isLoading}
                                >
                                    Send Reset Email
                                </Button>
                            </Form.Item>
                        </Form>
                        <Typography.Paragraph className="form_help_text">
                            Back to <Link to="/">Sign In</Link>
                        </Typography.Paragraph>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Reset;

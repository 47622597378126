import useSocketConnection from "../../socket";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
import { API, AUTH_TOKEN } from "../../constants";
import { message, Switch } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  Modal as antdModal,
  Card,
  Image,
  Space,
  Spin,
  Typography,
} from "antd";
import './RussianRoulette.css';
import darkbackground from '../../darkbackground.jpeg';
import pistol from '../../pistol.png';
import revolver from '../../revolver.png';
import CanvasStickman from "../../components/Canvas/CanvasStickman";
import useProfileStats from "../../components/Level/levels.js";
import bang from '../../bang.png';
import darkroom from '../../darkroombackground.jpeg';

function RussianRoulette() {
  const [spinning, setSpinning] = useState(false);
  const [state, updateState] = useState(localStorage.getItem("wheelColor"));
  const [wheelColors, setWheelColors] = useState([]);
  const [state2, updateState2] = useState(localStorage.getItem("fontColor"));
  const [winners, setWinners] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [player, setPlayer] = useState();
  const [winnersData, setWinnersData] = useState([]);
  const [winnersDataBestellingen, setWinnersDataBestellingen] = useState([]);
  const [winnerName, setWinnerName] = useState("");
  // const [toggle, setToggle] = useState(false);
  const [switchChange, setSwitchChange] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const { user, setUser } = useAuthContext();
  const [counter, setCounter] = useState(60);
  const [updatedProducts, setUpdatedProducts] = useState(false);
  const [bestelling, setBestelling] = useState([]);
    const [options, setOptions] = useState([]);
    const [dataWheelID, setdataWheelID] = useState([]);
  const socket = useSocketConnection();
  const [kansPercentagePersoon, setKanspercentagepersoon] = useState([]); 
  const [rotation, setRotation] = useState(0);
  const [hoveredName, setHoveredName] = useState(null);
  const [closing, setClosing] = useState(false);
  const [noShot, setNoShot] = useState(false);
  const [shot, setShot] = useState(false);
  const [poll, setPoll] = useState([]);
  const [booleanOn, setBooleanOn] = useState(false); 


    // const [updatingDatabase, setUpdatingDatabase] = useState(false);
  // const toggler = () => {
  //   toggle ? setToggle(false): setToggle(true);
  // }
  const numberOfSections = 3; 

  if (window.localStorage.getItem("duration") === null)
    localStorage.setItem("duration", 10);

  if (window.localStorage.getItem("wheelColor") === null)
    localStorage.setItem("wheelColor", "#d38c12");

  if (window.localStorage.getItem("fontColor") === null)
    localStorage.setItem("fontColor", "#FFFFFF");

  const [duration, setDuration] = useState(
    window.localStorage.getItem("duration")
  );

  const [wheelColor, setWheelColor] = useState(
    window.localStorage.getItem("wheelColor")
  );

  const [fontColor, setFontColor] = useState(
    window.localStorage.getItem("fontColor")
  );
  
  // const [names, setNames] = useState(() => {
  //   var myItem = localStorage.getItem(AUTH_TOKEN);
    
  //   localStorage.clear();
  //   localStorage.setItem(AUTH_TOKEN, myItem)
  //  const valueNames = window.localStorage.getItem("namesList");
  //   console.log(valueNames);
  //   return valueNames !== null
  //     ? valueNames
  //     : [
  //       "No Names",
  //       "No Names",
  //       ];
        
  // });
  const [names, setNames] = useState([]);
  // const {dataOrders, setDataOrders} = useState(() => {
  //   async function getthatdata() {

  //   }
  // })

  const [items, setItems] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["itemsList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [times, setTimes] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["timesList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [namesMeedoen, setNamesMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const valueNames = window.localStorage.getItem("namesList");
    return valueNames !== null
      ? valueNames
      : [
        "No Names",
        "No Names",
        ];
  });

  const [namesNietMeedoenOpWheel, setNamesNietMeedoenOpWheel] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const valueNames = window.localStorage.getItem("namesListNietMeedoenOpWheel");
    return valueNames !== null
      ? valueNames
      : [
        "No Names",
        "No Names",
        ];
  });

  const [itemsMeedoen, setItemsMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["itemsList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [timesMeedoen, setTimesMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["timesList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  async function addData() {
    // console.log("hijdoetdit");
    // console.log(user);
    try {
      const response = await fetch(`${API}/users?filters[meedoenOpWheel]=true`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
  
      if (response.ok) {
        // console.log("OKRESPONSE");
        const dataOrder = await response.json();
        const names = dataOrder.map(user => user.username);
        const items = dataOrder.map(user => user.id);
        const times = dataOrder.map(user => user.laatsteBestelTijd);
        const kansen = dataOrder.map(user => user.kans);
        const bestellingen = dataOrder.map(user => user.bestellingen);
        const kanspercentagepersoon = kansen.map((kans, index) => {
          return 100 - ((bestellingen[index] / kans) * 100);
        });
        const totalKanspercentagePersoon = kanspercentagepersoon.reduce((acc, percentage) => acc + percentage, 0);
        const multiplier = 100 / totalKanspercentagePersoon;
        const berekendkanspercentagepersoon = kanspercentagepersoon.map((kans) => {
          return (kans * multiplier).toFixed(0);
        });
        // console.log(names);
        localStorage.setItem("namesList", JSON.stringify(names));
        localStorage.setItem("itemsList", JSON.stringify(items));
        localStorage.setItem("timesList", JSON.stringify(times));
        localStorage.setItem("kansenList", JSON.stringify(kansen));
        localStorage.setItem("bestellingenList", JSON.stringify(bestellingen));
        localStorage.setItem("procentkans", JSON.stringify(berekendkanspercentagepersoon));
        setNames(names);
        setItems(items);
        setTimes(times);
        setKanspercentagepersoon(berekendkanspercentagepersoon);
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // console.log(names);
      try {
        const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });
  
        if (responsemeedoen.ok) {
          const datameedoen = await responsemeedoen.json();
          const names = datameedoen.map(user => user.username);
          const items = datameedoen.map(user => user.id);
          const times = datameedoen.map(user => user.laatsteBestelTijd);
  
          setNamesMeedoen(names);
          setItemsMeedoen(items);
          setTimesMeedoen(times);
          // console.log(items);
          localStorage.setItem("namesListMeedoen", JSON.stringify(names));
          localStorage.setItem("itemsListMeedoen", JSON.stringify(items));
          localStorage.setItem("timesListMeedoen", JSON.stringify(times));
        } else {
          console.error("WERKT NIET !");
        }
      } catch (error) {
        console.log("Error fetching meedoen data:", error);
      } finally {
        try {
          const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true&filters[meedoenOpWheel]=false`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          });
  
          if (responsemeedoen.ok) {
            const datameedoen = await responsemeedoen.json();
            const names = datameedoen.map(user => user.username);
  
            setNamesNietMeedoenOpWheel(names);
            localStorage.setItem("namesListNietMeedoenOpWheel", JSON.stringify(names));
          } else {
            console.error("WERKT NIET !!");
          }
        } catch (error) {
          console.log("Error fetching meedoen op wheel data:", error);
        }
      }
    }
  }

  const spinCanvasWheel = async () => {
    try {
      const response = await fetch(`${API}/users?populate=*`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${getToken()}`,
            },
      });
      const data = await response.json();
      const profiles = (data ?? []);
      const allMeeBestellenFalse = profiles.every(user => user.meeBestellen !== true);
    if (!allMeeBestellenFalse) {
      alert("vorige bestelling nog niet voltooid");
      console.log("vorige bestelling nog niet voltooid");
      return;
    } else {
      if (items.length === 0 || spinning === true) {
        alert("Can't spin a empty wheel or spinning wheel");
        console.log("Can't spin a empty wheel or spinning wheel");
        return;
    }
    const procentkansString = localStorage.getItem("procentkans");
      const procentkans = JSON.parse(procentkansString);
      // console.log(procentkans);
      // if (selectedItem === null) {
        // const selectedItem = Math.floor(Math.random() * this.props.items.length);
        const randomgenerator = Math.floor(Math.random() * 100);
        const range = procentkans.length;
        let totalvalue = 0
        let amount = 0
        
        while (totalvalue < randomgenerator) {
          const numberprocentkans = parseInt(procentkans[amount]);
          // console.log(totalvalue);
          // console.log(procentkans[amount]);
          // console.log(numberprocentkans + parseInt(totalvalue));
          if((numberprocentkans + parseInt(totalvalue)) > randomgenerator){
            // console.log("AGAIN");
            break;
          }
            // console.log("AGAIN");
            totalvalue = (parseInt(totalvalue) + parseInt(procentkans[amount]));
            amount = (amount+1);
        }
        // console.log(amount);
        const selectedItem = amount;
        // console.log(randomgenerator);
        // console.log(selectedItem);
        // console.log(selectedItem);
        // selectResultEventHandler(selectedItem);
        // setRotation(randomgenerator); 
        wheelSpinData(selectedItem, randomgenerator);
        // if (this.props.onSelectItem) {
        //   this.props.onSelectItem(selectedItem);
        // }
        // this.setState({ selectedItem });
      // } else {
        // this.setState({ selectedItem: null });
        // setTimeout(this.selectItem, 500);
    //   // }
    //   console.log("klik");
    // const randomRotations = Math.floor(Math.random() * 5) + numberOfSections * 5;
    // const randomStopSection = names[Math.floor(Math.random() * numberOfSections)];
  
    // const targetIndex = names.indexOf(randomStopSection);
    // const targetRotation = (targetIndex * 360) / numberOfSections;
  
    
      }
    } catch (error) {
        console.error(error);
        message.error("Error while fetching profiles!");
    } finally {
      // console.log(names);
        
    }
  
    
  };

  const wheelSpinData = async (data, randomgenerator) => {
    setIsLoading(true);
    const data2 = {
      'data': {
        IDinWinnersArray: data,
        ripclicked: false,
        rotation: randomgenerator,
      }
    };

    try {
        const response = await fetch(`${API}/wheels`, {
          method: "POST",
          headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data2),
        });
    } catch (error) {
        console.error(error);
       
    } finally {
        setIsLoading(false);
    }
};


const setReadyQuestion = async () => {
    antdModal.confirm({
      title: 'Weet je zeker dat iedereen er klaar voor is? De bestelling begint als je op OK drukt',
      onOk() {
        spinCanvasWheel();
      },
      onCancel() {
        // console.log("hoi");
      },
    });
  }

  
  
  async function addData2(data) {
    try {
      const response = await fetch(`${API}/users?filters[meedoenOpWheel]=true`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
  
      if (response.ok) {
        // console.log("OKRESPONSE");
        const dataOrder = await response.json();
        const names = dataOrder.map(user => user.username);
        const items = dataOrder.map(user => user.id);
        const times = dataOrder.map(user => user.laatsteBestelTijd);
        const kansen = dataOrder.map(user => user.kans);
        const bestellingen = dataOrder.map(user => user.bestellingen);
        const kanspercentagepersoon = kansen.map((kans, index) => {
          return 100 - ((bestellingen[index] / kans) * 100);
        });
        const totalKanspercentagePersoon = kanspercentagepersoon.reduce((acc, percentage) => acc + percentage, 0);
        const multiplier = 100 / totalKanspercentagePersoon;
        const berekendkanspercentagepersoon = kanspercentagepersoon.map((kans) => {
          return (kans * multiplier).toFixed(0);
        });
        // console.log(names, items);
        await selectResultEventHandler(data.data.attributes.IDinWinnersArray, items, names);
        localStorage.setItem("namesList", JSON.stringify(names));
        localStorage.setItem("itemsList", JSON.stringify(items));
        localStorage.setItem("timesList", JSON.stringify(times));
        localStorage.setItem("kansenList", JSON.stringify(kansen));
        localStorage.setItem("bestellingenList", JSON.stringify(bestellingen));
        localStorage.setItem("procentkans", JSON.stringify(berekendkanspercentagepersoon));
        setNames(names);
        setItems(items);
        setTimes(times);
        setKanspercentagepersoon(berekendkanspercentagepersoon);
        
      }
      if (!response.ok){
        // console.log("OHNEE");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // console.log(names, "huh");
      try {
        const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });
  
        if (responsemeedoen.ok) {
          const datameedoen = await responsemeedoen.json();
          const names = datameedoen.map(user => user.username);
          const items = datameedoen.map(user => user.id);
          const times = datameedoen.map(user => user.laatsteBestelTijd);
  
          setNamesMeedoen(names);
          setItemsMeedoen(items);
          setTimesMeedoen(times);
  
          localStorage.setItem("namesListMeedoen", JSON.stringify(names));
          localStorage.setItem("itemsListMeedoen", JSON.stringify(items));
          localStorage.setItem("timesListMeedoen", JSON.stringify(times));
        }
      } catch (error) {
        console.log("Error fetching meedoen data:", error);
      } finally {
        try {
          const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true&filters[meedoenOpWheel]=false`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          });
  
          if (responsemeedoen.ok) {
            const datameedoen = await responsemeedoen.json();
            const names = datameedoen.map(user => user.username);
  
            setNamesNietMeedoenOpWheel(names);
            localStorage.setItem("namesListNietMeedoenOpWheel", JSON.stringify(names));
          }
        } catch (error) {
          console.log("Error fetching meedoen op wheel data:", error);
        } finally {
          // console.log(names);
          // console.log(data.data.attributes.IDinWinnersArray, data.data.attributes.rotation);
          // selectResultEventHandler(data.data.attributes.IDinWinnersArray, items, names);
          setRotation(data.data.attributes.rotation);
        }
      }
    }
  }

  async function removeWinnerModal() {
    // console.log(dataWheelID);
    try {
      const response = await fetch(`${API}/wheels/${dataWheelID.data.id}`, {
        headers:{
          Authorization: `Bearer ${getToken()}`,
        }
      });
      const data = await response.json();
      // console.log(data);
      const check = (data.data.attributes.ripclicked);
      // console.log(check);
      if(data.data.attributes.ripclicked === true){
        window.location = '/producten';
      }
  } catch (error) {
      console.error(error);
     
  }

  
try {
  const dataformeedoenOpWheel = {
    'data': {
    ripclicked: true,
    }
  }
  const response = await fetch(`${API}/wheels/${dataWheelID.data.id}`, { 
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(dataformeedoenOpWheel),
  });

  const responseData = await response.json();

  if (response.ok) {
    // console.log("updatakans");
  } else {
    console.error("Error updating user:", responseData);
    message.error("Error While Updating the Profile!");
  }
} catch (error) {
  console.error(error);
  message.error("Network Error While Updating the Profile!");
}

try{
  const responseGET = await fetch(`${API}/polls?populate=*&filters[spinnerFinished][$eq]=false`, {
    headers:{
      Authorization: `Bearer ${getToken()}`,
    }
  });
  
  if (!responseGET.ok) {
    throw new Error('Network response was not ok');
  }

  // Parse the response as JSON
  const dataGET = await responseGET.json();
  // console.log(dataGET);
  if(dataGET.data[dataGET.data.length - 1] !== undefined){
    const pollID = dataGET.data[dataGET.data.length - 1].id;

  const dataSpinnerDone = {
    'data': {
      spinnerFinished: true,
    }
  }
  const response = await fetch(`${API}/polls/${pollID}`, {
    method: "PUT",
    headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(dataSpinnerDone),
  });
  } else {
    // console.log("we have got a problem here")
  }


} catch(error) {
  console.log(error)
}

  // console.log(winnersData);
    const winner = winners[winners.length - 1];
    // console.log(winner);
    // const index = items.indexOf(winner);
    const today = new Date();
    // items.splice(index, 1);
    setItems(items);
    setOpenModal(false);
    // console.log(winner);
    // const url = `http://localhost:1337/api/snackvreters/${winner}`;
    // fetch(url)
    //     .then(response => response.json())
    //     .then(persoonData => {
    //       setWinnersData(persoonData.data.attributes.kans);
    //       setWinnersDataBestellingen(persoonData.data.attributes.bestellingen);
    //       console.log(persoonData.data.attributes.kans);
    //     })
    //     .catch(error => {
    //       console.error("error fetching names data: ", error);
    //     })
    //     console.log(currentDate[0]);
    if (winnersData !== "") {
      // console.log(localStorage.getItem("namesList"));
      // console.log("hoi");
      // console.log(winner);
      // console.log(winnersData);
      // console.log(winnersDataBestellingen);
      // console.log("ifwinnersdata")
      updateBestellers();
      updateKans (winner, {
        bestellingen: winnersDataBestellingen + 1,
        laatsteBestelTijd: new Date().toJSON(),
        laatsteBestelling: true,
        meedoen: false,
        meedoenOpWheel: false,
      }
      )

    }
    // console.log(winnersDataBestellingen);
    // console.log(winnersData);
  }

  const updateBestellers = async () => {
    const bestellerNamesProduct = await JSON.parse(window.localStorage.getItem("itemsListMeedoen"));
    // console.log(bestellerNamesProduct);
    // console.log(localStorage.getItem("itemsList"));
    const bestellerNamesLenght = await bestellerNamesProduct.length;
    // console.log(bestellerNamesLenght);
    for (let i = 0; i < bestellerNamesLenght; i++) {
      try {
        const responseGET = await fetch(`${API}/users/${bestellerNamesProduct[i]}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        const responseGETData = await responseGET.json();
//  console.log("ghsifughdifughdf", responseGETData);
      
 const data =
 responseGETData.meedoenOpWheel === false && responseGETData.laatsteBestelling === false
   ? {
       meeBestellen: true,
       klaarBestellen: false,
       meedoen: false,
       meedoenOpWheel: false,
     }
   : {
       meeBestellen: true,
       klaarBestellen: false,
       meedoen: false,
       meedoenOpWheel: false,
       kans: responseGETData.kans + 1,
       xp: responseGETData.xp + 50,
     };

      
      // console.log(data);
      const response = await fetch(`${API}/users/${bestellerNamesProduct[i]}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        message.success("bestellers updated successfully!");
      } else {
        console.error("Error updating bestellers:", responseData);
        message.error("Error While Updating the bestellers!");
      }
      
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating the bestellers!");
    }
    }
    
  }

  const updateKans = async (IDUser, data) => {
    try {
      const response = await fetch(`${API}/users/${IDUser}`, { // Add await keyword here
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json(); 
  
      if (response.ok) {
        // setUser(responseData);
        message.success("Data saved successfully!");
      } else {
        console.error("Error updating user:", responseData);
        message.error("Error While Updating the Profile!");
      }
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating the Profile!");
    } finally {
      // setLoading(false);
    }
  };

  function startAnimation(realfake) {
    if(realfake === "real"){
      // console.log("OHHHRIP");
    } else if(realfake === "fake") {
      // console.log("HAHAH FAKE ANIMATION");
    }
  }

  function selectResultEventHandler(data, items, names) {
    if (items.length > 0 && spinning !== true) {
      if(names && names.length > 0){
 // var selectedIndex = data;
      // console.log(selectedIndex);
      // console.log(data, names, items);
      setWinnerName(names[data]);
      // console.log(user?.username, names[data])
      toggleEyes();
      if (user && names[data] === user?.username) {
        // console.log("startanimation");
        startAnimation("real");
      } else {
        // console.log("startfakeanimation");
        startAnimation("fake");
      }
      // console.log(names, items);
      // wheelSpinData(data);
      // console.log(winnerName);
      // console.log(localStorage.getItem("oldestDate"))
      // set this state to disable tab and wheel click when spinning
      setSpinning(true);
      setWinners(winners.concat(items[data]));
      const winner = items[data];
      // const winner = winners[winners.length - 1];
      setTimeout(() => {
        setSpinning(false);
      }, window.localStorage.getItem("duration") * 1000);
      setTimeout(() => {
        // console.log(winners);
        // console.log(winner);
        
        const url = `${API}/users/${winner}`;
            fetch(url, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${getToken()}`,
              }
            })
              .then(response => response.json())
              .then(persoonData => {
                setWinnersData(persoonData.kans);
                setWinnersDataBestellingen(persoonData.bestellingen);
                // console.log(persoonData.kans);
              })
              .catch(error => {
                console.error("error fetching names data: ", error);
              })
              // console.log(currentDate[0]);
        // setTimeout(() => {
        //   console.log(winners);
        //   const currentWinner = winners[winners.length - 1];
        //   console.log(currentWinner);
        //   setTimeout(() => {
        //     const url = `http://localhost:1337/api/snackvreters/${currentWinner}`;
        //     fetch(url)
        //       .then(response => response.json())
        //       .then(persoonData => {
        //         console.log(persoonData.data.attributes.naam);
        //         setWinnersData(persoonData.data);
        //     })
        //       .catch(error => {
        //       console.error("error fetching names data: ", error);
        //     })
        //   }, 100)
        // }, 100)
        setTimeout(() => {
          // console.log(winnersData);
        }, 110)
        
      }, window.localStorage.getItem("duration") * 1000);
      setTimeout(() => {
        // console.log("party");
        toggleEyes();
        if (user && names[data] === user?.username) {
          // console.log("startanimation");
          startAnimation("real");
          setShot(true);
        } else {
          // console.log("startfakeanimation");
          startAnimation("fake");
          setNoShot(true);
        }
        // setNoShot(true);
        // setShot(true);
      }, 8000);
      setTimeout(() => {
        setOpenModal(true);
      }, window.localStorage.getItem("duration") * 1010);
      } else {
        // console.log("OK");
        setTimeout(() => {
          selectResultEventHandler(data);
        }, 500)
      }
     
    }
  }

  function cancelModal() {
    setOpenModal(false);
  }
  
  const toggleEyes = () => {
  // console.log(closing);
  setClosing((prevClosing) => !prevClosing);
};

useEffect(() => {
  if(user === undefined){
    // console.log("FU")
  } else {
  // console.log(user);
  addData();
  }
}, [user]);

useEffect(() => {
  if (user && dataWheelID.length !== 0) {
    addData2(dataWheelID);
  }
}, [user, dataWheelID]);

  useEffect(() => {
    // Listen for socket events and handle them
    if (socket) {
      // console.log(names);
      socket.on("connect", () => {
        console.log("HET WIEL");
        socket.on("users-permissions:update", (data) => {
          
        });
        socket.on("wheel:create", (data) => {
          console.log(data);
          setdataWheelID(data);
          // addData2(data);
        });

        socket.on("roulette:create", (data) => {
          console.log(data.data.attributes);
          

          // startShootingAnimation(data.data.attributes);
        });


        socket.on("disconnect", () => {
          console.log("Socket disconnected");
        });
      });
    }
  }, [socket]);

    return (
      <div className={`closing-eyes ${closing ? 'closed' : ''}`} style={{ display: 'flex', width: '100%', height: '100%' }}>
          <Modal show={openModal} onHide={cancelModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>We have a LOSER 😝</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{winnerName}</p>
        </Modal.Body>
        <Modal.Footer>                   
          <Button variant="primary" onClick={removeWinnerModal}>
            rip {winnerName}
          </Button>
        </Modal.Footer>
      </Modal>
      <div onClick={event =>  window.location.href='/spinapp'} className="backArrowRoulette" style={{ position: 'absolute', zIndex: '2' }}>&#8592;
</div>
<div onClick={event =>  window.location.href='/producten'} className="menuButtonRoulette" style={{ position: 'absolute', zIndex: '2' }}>&#8594;
</div>
            <div>
              {noShot === true && (
                <div style={{ position: 'absolute', top: '20%', left: '10%', fontSize: '30px', fontWeight: 'bold', zIndex: 4 }}>*klik*</div>
              )}
              {shot === true && (
                <>
                <img src={bang} style={{ position: 'absolute', top: '-5%', left: '60px', height: '700px', fontSize: '30px', fontWeight: 'bold', zIndex: 4 }} />
                <p style={{ position: 'absolute', top: '220px', left: '420px', height: '700px', fontSize: '30px', fontWeight: 'bold', zIndex: 4 }}>*BANG*</p>
                </>
              )}
           <CanvasStickman imageRevolver={revolver} width='1000px' height='800px' style={{ position: 'absolute', zIndex: '1', top: '100px', left: '-5%'  }} />
</div>
      <button onClick={setReadyQuestion} className="igilljust">START</button>
      <button onClick={toggleEyes}>Toggle Eyes</button>
     
    <div style={{ position: 'absolute', backgroundColor: 'black', top: '-33px', left: '-4.55%', width: '100vw', height: '100vh', objectFit: 'cover', zIndex: 5, pointerEvents: 'none', opacity: `${closing ? '0.8' : '0'}`, transition: `opacity ${closing ? '8s' : '1s'} ease` }}></div>
        <img src={darkroom} style={{ position: 'absolute', top: '-33px', left: '-4.55%', width: '100vw', zIndex: '0', objectFit: 'cover', height: '100vh' }}></img>
        
        <div className="divpercentagesroulette"><div className="titlepercentagesRoulette">Wie doen mee op het wheel?</div>
                  {/* <div className="fullbar">
                    <div className="percentagebarRoulette" style={{ width: `20%` }}>20%</div>
                  </div> */}
                  {kansPercentagePersoon?.map((kansenmap, index) => (
  <div className="fullbarRoulette" key={index}>
    {kansenmap > 85 ? (
      <>
        <div className="percentagebarRoulette" style={{ width: kansenmap > 10 ? `${kansenmap}%` : '10%' }}>
        <p className="othername"><p className="othernameshorter">{names[index]}</p></p><p className="atpercentage">{kansenmap}%</p>
        </div>
        <div style={{ width: kansenmap > 10 ? `${100 - kansenmap}%` : '90%' }}>
          
        </div>
      </>
    ) : (
      <>
        <div className="percentagebarRoulette" style={{ width: kansenmap > 10 ? `${kansenmap}%` : '10%' }}>
          <p className="atpercentage">{kansenmap}%</p>
        </div>
        <div className="divnamesatpercentagebarRoulette" style={{ 
            width: kansenmap > 10 ? `${100 - kansenmap}%` : '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '10px',
            overflow: 'hidden'
          }}>
          <p style={{ height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
     overflow: 'auto',
        textOverflow: 'ellipsis',
     width: '95%',
     margin: 0, }} className="nameatpercentagebarRoulette">{names[index]}</p>
        </div>
      </>
    )}
  </div>
))}



{namesNietMeedoenOpWheel.length > 0 && (
  <div className="titlepercentagesRoulette">Wie eten mee maar niet op het wheel?</div>
)}

{namesNietMeedoenOpWheel?.map((naam, index) => (
  <div className="fullbarRoulettegrey" key={index}>
  <div className="percentagebarRoulettegrey" style={{ width: `37px` }}>
    <p className="atpercentage">0%</p>
  </div>
  <div style={{ 
            width: '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '10px'
          }}>
    <p className="nameatpercentagebar">{naam}</p>
  </div>
</div>
))}</div>
        
        
        </div>
    )

}

export default RussianRoulette;
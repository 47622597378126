import useSocketConnection from "../../socket";
import React, { useEffect, useState, useRef } from "react";
import { API, BEARER } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
import "../Producten/bestellijst.css";
import {
    Modal,
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import { Refresh } from "@mui/icons-material";
import { render } from "react-dom";
import copy from "copy-to-clipboard";
import { copyProducts } from "./apiUtils"; // Import the copyProducts function


const Bestellijst = ({ updateBestellijst, groupedProducts }) => {
  const preferredTime = useRef();
    const [bestellijst, setBestelLijst] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user, setUser } = useAuthContext();
    const [increment, setIncrement] = useState(false);
    const [fetchedlist, setFetchedList] = useState(false);
    const [updatedProducts, setUpdatedProducts] = useState(false);
    const [profile, setProfiles] = useState([]);
    const [bestelling, setBestelling] = useState([]);
    const [options, setOptions] = useState([]);
  const [lastOrder, setLastOrder] = useState([]);
  const [details, setDetails] = useState(null);
  const [orders, setOrders] = useState([]);
  const [productDetails, setProductDetails] = useState([])
  const socket = useSocketConnection();
  const [guestOrders, setGuestOrders] = useState([]);

    useEffect(() => {
        // console.log(fetchedlist)
        if (fetchedlist === false){
           if(user){
                fetchProfile();
               
            }
            
        }
            
    }, [user]);

    useEffect(() => {
        // console.log(fetchedlist)
        if (fetchedlist === false){
           if(user){
            fetchBestelling();
            fetchHeleBestelling();
            fetchAlleOptions();
               
            }
            
        }
            
    }, [updateBestellijst]);

    const fetchProfile = async () => {
        setIsLoading(true);
        try {
            if (user) { // Check if user is defined
                const response = await fetch(`${API}/users/${user.id}`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${getToken()}`,
                    }
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setProfiles(data ?? []);
                // console.log("Fetched Profile:", user);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            fetchBestelling();
            fetchHeleBestelling();
            fetchAlleOptions();
        }
    };

    const fetchHeleBestelling = async () => {
        try {
            const ordersResponse = await fetch(`${API}/products`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${getToken()}`,
              }
            });
            const datahele = await ordersResponse.json();
            setBestelling(datahele.data);
            if (!ordersResponse.ok) {
              console.error('Error fetching products:', ordersResponse);
              message.error('Error fetching products!');
            }
    } catch {

    }
}

const fetchAlleOptions = async () => {
    try {
        const ordersResponse = await fetch(`${API}/options`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });
        const datahele = await ordersResponse.json();
        setOptions(datahele.data);
        if (!ordersResponse.ok) {
          console.error('Error fetching products:', ordersResponse);
          message.error('Error fetching products!');
        }
    } catch {

    }
}



    const fetchBestelling = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/orders?filters[users]=${user.id}&filters[huidigeOrder][$eq]=true`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${getToken()}`,
                }
              });
            const data = await response.json();
          setBestelLijst(data.data ?? []);
          const guestnames = data.data.map(item => item.attributes.guestname);
          console.log(guestnames);

            // console.log(data.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            // setFetchedList(true);
            // console.log(bestellijst);
            // console.log("HELLOOOOOO")
        }
    };

  // const handleIncrement = async (index, operation) => {
  //     if (increment === false) {
  //         setIncrement(true);
  //     } else {
  //         setIncrement(false);
  //     }
  //     // console.log(increment);
  //     if (profile.klaarBestellen === false && profile.meeBestellen === true) {
  //         try {
  //         const updatedBestellijst = [...bestellijst];
  //         if (operation === '+') {
  //             updatedBestellijst[index].attributes.quantity += 1;
  //         } else if (operation === '-') {
  //             updatedBestellijst[index].attributes.quantity -= 1;

  //         }

  //         // Parse product price and total price into valid numbers for calculations
  //         const productPrice = parseFloat(updatedBestellijst[index].attributes.productprijs.replace("€", "").replace(",", ".").trim());
  //         const totalPrice = (productPrice * updatedBestellijst[index].attributes.quantity).toFixed(2);

  //         // console.log("net gedaanm", totalPrice)


  //         if (updatedBestellijst[index].attributes.quantity >= 1) {
  //         const response = await fetch(`${API}/orders/${updatedBestellijst[index].id}`, {
  //             method: "PUT",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${getToken()}`,
  //             },
  //             body: JSON.stringify({
  //                 data:
  //                 {
  //                     quantity: updatedBestellijst[index].attributes.quantity,

  //                 },
  //             },
  //             ),
  //         });
  //         // console.log("totaaaaaal", totalPrice)

  //         const responseData = await response.json();

  //         if (response.ok) {
  //             setBestelLijst(updatedBestellijst);
  //             fetchBestelling();
  //             message.success("Order list updated successfully!");
  //             // console.log("resonse ok", totalPrice)
  //             // console.log(updatedBestellijst[index].attributes.quantity)
  //         } else {
  //             console.error("Error updating order list:", responseData);
  //             message.error("Error While Updating the Order List!");
  //         }
  //         } else {
  //             const response = await fetch(`${API}/orders/${updatedBestellijst[index].id}`, {
  //                 method: "DELETE",
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Authorization: `Bearer ${getToken()}`,
  //                 },
  //             });

  //             const responseData = await response.json();

  //             if (response.ok) {
  //                 setBestelLijst(updatedBestellijst);
  //                 // window.location.reload();
  //                 if (increment === false) {
  //                     setIncrement(true);
  //                 } else {
  //                     setIncrement(false);
  //                 }
  //                 fetchBestelling();
  //                 message.success("Order list deleted successfully!");
  //             } else {
  //                 console.error("Error deleted order list:", responseData);
  //                 message.error("Error While deleted the Order List!");
  //             }
  //         }


  //     } catch (error) {
  //         console.error(error);
  //         message.error("Network Error While Updating the Order List!");
  //     }
  //     }


  // };

  const handleIncrement = async (username, index, operation) => {
    try {
      setIncrement(!increment);

      if (profile.klaarBestellen === false && profile.meeBestellen === true) {
        const updatedBestellijst = [...bestellijst];

        // Find the order in the bestellijst array based on both username and index
        const orderInBestellijst = updatedBestellijst.findIndex(order =>
          order.attributes.guestname === username && order.id === groupedOrders[username][index].id
        );

        if (orderInBestellijst !== -1) {
          if (operation === '+') {
            updatedBestellijst[orderInBestellijst].attributes.quantity += 1;
          } else if (operation === '-') {
            updatedBestellijst[orderInBestellijst].attributes.quantity -= 1;
          }
          // Parse product price and total price into valid numbers for calculations
          const productPrice = parseFloat(updatedBestellijst[orderInBestellijst].attributes.productprijs.replace("€", "").replace(",", ".").trim());
          const totalPrice = (productPrice * updatedBestellijst[orderInBestellijst].attributes.quantity).toFixed(2);

          if (updatedBestellijst[orderInBestellijst].attributes.quantity >= 1) {
            const response = await fetch(`${API}/orders/${updatedBestellijst[orderInBestellijst].id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify({
                data: {
                  quantity: updatedBestellijst[orderInBestellijst].attributes.quantity,
                },
              }),
            });

            const responseData = await response.json();

            if (response.ok) {
              setBestelLijst(updatedBestellijst);
              fetchBestelling();
              message.success("Order list updated successfully!");
            } else {
              console.error("Error updating order list:", responseData);
              message.error("Error While Updating the Order List!");
            }
          } else {
            const response = await fetch(`${API}/orders/${updatedBestellijst[orderInBestellijst].id}`, {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
            });

            const responseData = await response.json();

            if (response.ok) {
              setBestelLijst(updatedBestellijst);
              if (increment === false) {
                setIncrement(true);
              } else {
                setIncrement(false);
              }
              fetchBestelling();
              message.success("Order list deleted successfully!");
            } else {
              console.error("Error deleting order list:", responseData);
              message.error("Error While Deleting the Order List!");
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating/Deleting the Order List!");
    }
  };


    const finalprice = bestellijst.reduce((total, bestelling) => {
        return parseFloat(total) + parseFloat(bestelling.attributes.totaalprijs) * bestelling.attributes.quantity;
    }, 0);

    const totaalUitgegeven = () => {
        return finalprice + user.totaalUitgegeven;
    }


    const sendStats = async () => {
        const currentdate = new Date();
        const hours = currentdate.getHours().toString().padStart(2, '0');
        const minutes = currentdate.getMinutes().toString().padStart(2, '0');
        const seconds = currentdate.getSeconds().toString().padStart(2, '0');
        const milliseconds = currentdate.getMilliseconds().toString().padStart(3, '0');
        const data = {
            'data': {
                datum: currentdate,
                tijd: `${hours}:${minutes}:${seconds}:${milliseconds}`,
                totaalOntvangen: user.totaalOntvangen,
                totaalUitgegeven: finalprice,
                users: {
                    id: user.id
                }
            }
        };

        const response = await fetch(`${API}/statistics`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`
            },
            body: JSON.stringify(data),


        })
        const responseData = await response.json();

        if (response.ok) {
            message.success("stats updated successfully!");
            window.location = '/bestellingen';
            
        } else {
            console.error("Error updating order list:", responseData);
            message.error("Error While Updating the Order List!");
        }
    }

    const handlePreferredTimeChange = (e) => {
      const preferredTimeInput = e?.target?.value;
      
      console.log(preferredTimeInput);
      const [hours, minutes] = preferredTimeInput.split(':');
      if(preferredTimeInput === "") {
        const formattedTime = null;
        preferredTime.current = formattedTime;
      } else {
        const formattedTime = `${hours}:${minutes}:00.000`;
    preferredTime.current = formattedTime;
      }
      
  };

  const removeTime = (e) => {
    const preferredTimeInput = null;
    preferredTime.current = preferredTimeInput;
    console.log(preferredTimeInput);
    handlePreferredTimeChange(e);
};

  const confirmReady = async () => {
    if(profile.klaarBestellen === true){

      Modal.confirm({
        title: 'Weet je zeker dat je toch niet klaar bent?',
        onOk() {
          setReady();
        },
        onCancel() {
          // console.log("hoi");
        },
      });
    } else {
      Modal.confirm({
      title: 'Weet je zeker dat je alles hebt?',
      content: (
        <>
        <p>Heb je een voorkeurstijd?</p>
        <input type="time" value={preferredTime.current} onChange={handlePreferredTimeChange}/>{
        preferredTime.current !== null && (
          <button onClick={removeTime}>remove</button>
        )}
        
        </>
      ),
      onOk() {
        setReady();
      },
      onCancel() {
        // console.log("hoi");
      },
    });
    }
    
  }


  function convertToMilliseconds(timeString) {
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const formattedTime = date.toISOString().substr(11, 12);
    return formattedTime;
  }

    const setReady = async () => {
        if(profile.meeBestellen === true && profile.klaarBestellen === false){
          console.log(preferredTime.current);
            try {
            const data = {
                klaarBestellen: true,
                preferredOrderTime: preferredTime.current || null,
              // totaalUitgegeven: totaalUitgegeven(),                
            }
            // console.log(data);
            const response = await fetch(`${API}/users/${user.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
            });
                if (profile.klaarBestellen === false) {
                  // sendStats();
                }

            const responseData = await response.json();

            if (response.ok) {
                message.success("bestellers updated successfully!");
            } else {
                console.error("Error updating bestellers:", responseData);
                message.error("Error While Updating the bestellers!");
            }
        } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the bestellers!");
        } finally {
            fetchProfile();
        }
        } else if(profile.meeBestellen === true && profile.klaarBestellen === true){
          try {
            const response = await fetch(`${API}/users?filters[meeBestellen][$eq]=true`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${getToken()}`,
              }
            })
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            const allKlaarBestellen = data.every(item => item.klaarBestellen === true);

            if(allKlaarBestellen){
              message.error("de besteller is al aan het bestellen");
            } else {
              try {
                const data = {
                    klaarBestellen: false,
                  // totaalUitgegeven: totaalUitgegeven(),                
                }
                // console.log(data);
                const response = await fetch(`${API}/users/${user.id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(data),
                });
                    if (profile.klaarBestellen === false) {
                      // sendStats();
                    }
    
                const responseData = await response.json();
    
                if (response.ok) {
                    message.success("bestellers updated successfully!");
                } else {
                    console.error("Error updating bestellers:", responseData);
                    message.error("Error While Updating the bestellers!");
                }
            } catch (error) {
                console.error(error);
                message.error("Network Error While Updating the bestellers!");
            } finally {
                fetchProfile();
            }
            }
            // console.log(data);
            // console.log(data.id);
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoading(false);
            // console.log(alleBestellers);
          }
        }
        
    }

    const updateMenu = async () => {
        const optionsscript = `const uniqueData = {};

        const scrapeData = async () => {
            try{
                const response = await fetch('${API}/products/delete', {
                    method: 'DELETE',
                    headers: {
                        Authorization: 'Bearer ${getToken()}',
                      },
                  });
            
                  if (response.status === 200) {
                    // console.log('Data saved successfully.');
                  } else {
                    console.error('Error saving data. Server responded with:', response.status);
                    console.error(response);
                  }
                  const response2 = await fetch('${API}/options/delete', {
                    method: 'DELETE',
                    headers: {
                        Authorization: 'Bearer ${getToken()}',
                      },
                  });
            
                  if (response2.status === 200) {
                    // console.log('Data saved successfully.');
                  } else {
                    console.error('Error saving data. Server responded with:', response2.status);
                    console.error(response2);
                  }
            } catch(error) {
                console.log(error);
            } finally {

            
            try{
          const data2 = ft_allSidedishCombos;
        
          for (const IDmimi in data2) {
            if (data2.hasOwnProperty(IDmimi)) {
              for (const item of data2[IDmimi]) {
                if (item.sides && Array.isArray(item.sides)) {
                  for (const side of item.sides) {
                    const label = side.label;
                    const price = side.price;
                    const identifier = label + '-' + price; // Use concatenation with plus sign
        
                    // Use setTimeout to introduce a delay between calls
                    setTimeout(() => {
                      updateList(IDmimi, label, price); // Implement this function
                    }, 1000); // 1000 milliseconds = 1 second
        
                    if (!uniqueData[identifier]) {
                      uniqueData[identifier] = { label, price };
                    }
                  }
                }
              }
            }
          }} finally {
            setTimeout( async () => {
  const productRows = document.querySelectorAll('.ft-product-row');
  const productDataList = [];

  for (const productRow of productRows) {
    const name = productRow.querySelector('.ft-product-name').textContent.trim();
    const price = productRow.querySelector('.ft-price').textContent.trim();
    const button = productRow.querySelector('.ft-plusminbuttons button[data-choices]');
    const dataChoices = button ? button.getAttribute('data-choices') : 'NVT';

    // Get the category ID from the parent <a> element with class "cat-anchor"
    const categoryID = productRow.closest('a.cat-anchor').getAttribute('id');
    let realID;

    if (categoryID === 'patat') {
        realID = 1;
      } else if (categoryID === 'raspatat') {
        realID = 2;
      } else if (categoryID === 'snacks') {
        realID = 3;
      } else if (categoryID === 'halal-snacks') {
        realID = 4;
      } else if (categoryID === 'burgers') {
        realID = 5;
      } else if (categoryID === 'broodjes') {
        realID = 6;
      } else if (categoryID === 'saus-extras') {
        realID = 7;
      } else if (categoryID === 'milkshake-ijs') {
        realID = 8;
      } else if (categoryID === 'dranken') {
        realID = 9;
      }

    productDataList.push({
      name,
      price,
      dataChoices,
      realID,
    });
  }



  // console.log(productDataList); 


                for (const productData of productDataList) {
    // console.log('Product Name:', productData.name);
    // console.log('Price:', productData.price);
    // console.log('IDmimi:', productData.dataChoices);
    // console.log('Category ID:', productData.realID);

    try {
      const response = await fetch('${API}/products', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ${getToken()}',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
          name: productData.name,
          price: productData.price,
          IDmimi: productData.dataChoices,
          category: {
            id: productData.realID,
        }
        },
          })
        
      });

      if (response.status === 200) {
      } else {
        console.error('Error saving data. Server responded with:', response.status);
        console.error(response);
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Status code:', error.response.status);
      }
    }
  }
                
}, 10000);
            }}
        };
        
        const updateList = async (IDmimi, label, price) => { // Include IDmimi parameter
            try {
              const data = {
                'data': {
                  label: label,
                  price: price,
                  IDmimi: IDmimi, // Include IDmimi in the data object
                }
              };
          
              const response = await fetch('${API}/options', {
                method: 'POST', // Use single quotes for string values
                headers: {
                  'Authorization': 'Bearer ${getToken()}',
                  'Content-Type': 'application/json', // Use single quotes for string values
                },
                body: JSON.stringify(data),
              });
          
              const responseData = await response.json();
          
              if (response.ok) {
                // console.log('Order list updated successfully!');
              } else {
                console.error('Error updating order list:', responseData);
                console.log('Error While Updating the Order List!');
              }
            } catch (error) {
              console.error(error);
              console.log('Network Error While Updating the Order List!');
            }
          };
        scrapeData();`;
        let isCopy = copy(optionsscript);

        //Dispalying notification
        if (isCopy) {
          message.success("Copied to Clipboard");
        }
    }

  const copyLastOrder = async () => {
    setIsLoading(true);
    if (profile.meeBestellen === true) {
      try {
      // Fetch the last order-list
      const orderListsResponse = await fetch(`${API}/order-lists/all`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        },
      });

      if (orderListsResponse.ok) {
        const orderListsData = await orderListsResponse.json();
        const sortedOrderLists = orderListsData.sort((a, b) => b.id - a.id);
        const latestOrderList = sortedOrderLists[0];
        // console.log(latestOrderList.orders)
        // Fetch products from the latest order-list
        const latestOrderListProducts = latestOrderList.orders;
        // console.log(latestOrderListProducts)
        // Fetch products from orders with populate=*
        const ordersResponse = await fetch(`${API}/orders?populate=*`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          },
        });

        if (ordersResponse.ok) {
          const ordersData = await ordersResponse.json();
          const ordersProducts = ordersData.data;
          {
            ordersProducts.map((prod) => {
              // console.log("map prod", "ID", prod.id, "users id", prod.attributes.users.data.id, "product users naam", prod.attributes.users.data.attributes.username)
            })
          }
          // Filter products from orders that match the products in the latest order-list
          const productsToCopy = ordersProducts.filter(orderProduct =>
            latestOrderListProducts.some(listProduct => listProduct.id === orderProduct.id)

          );
          // console.log("dit moet hij hebben", productsToCopy)


          // Create an array to store the promises for each POST request
          const postRequests = productsToCopy.map(async (product) => {
            console.log("mapped product", product.attributes.users.data.id)
            if (product.attributes.users.data.id === user.id && product.attributes.guestname === user.username) {
            // console.log(product.attributes.guestname)
              // console.log("testttt", "product user id", product.attributes.users.data.id, "user id", user.id)

              const productData = {
                'data': {
                  productnaam: product.attributes.productnaam,
                  productprijs: product.attributes.productprijs,
                  lijst: product.attributes.lijst,
                  totaalprijs: product.attributes.totaalprijs,
                  quantity: product.attributes.quantity,
                  users: {
                    id: user.id,
                  },
                  huidigeOrder: true,
                  guestname: user.username,
                },
              };

              const response = await fetch(`${API}/orders`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${getToken()}`,
                },
                body: JSON.stringify(productData),
              });
              if (response.ok) {
                // Product successfully copied
                // console.log(`Product ${product.id} copied successfully.`);
              }
              else {
                // Error copying product
                console.error(`Error copying product ${product.id}.`);
              }
            }






            // try {
            //   const productData = {
            //     'data': {
            //       productnaam: product.attributes.productnaam,
            //       productprijs: product.attributes.productprijs,
            //       lijst: product.attributes.lijst,
            //       totaalprijs: product.attributes.totaalprijs,
            //       users: {
            //         id: user.id,
            //       },
            //       huidigeOrder: false,
            //     },
            //   };

            //   const response = await fetch(`${API}/orders`, {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json',
            //       'Authorization': `Bearer ${getToken()}`,
            //     },
            //     body: JSON.stringify(productData),
            //   });

            //   if (response.ok) {
            //     // Product successfully copied
            //     console.log(`Product ${product.id} copied successfully.`);
            //   } else {
            //     // Error copying product
            //     console.error(`Error copying product ${product.id}.`);
            //   }
            // }
            //  catch (error) {
            //   console.error(error);
            //   message.error('Error while copying product!');
            // }
          });

          await Promise.all(postRequests);
        } else {
          // Error fetching products from orders
          console.error('Error fetching products from orders.');
        }
      } else {
        // Error fetching latest order-list
        console.error('Error fetching latest order-list.');
      }
    } catch (error) {
      console.error(error);
      message.error('Error while copying last order!');
    } finally {
      setIsLoading(false);
    }
    } else {
      message.error('Je bestelt niet mee')

    }

  };


  useEffect(() => {
    // Listen for socket events and handle them
    if (socket) {
      socket.on("connect", () => {
        // console.log("bestellijst on")
        socket.on("order:update", (data) => {
          fetchBestelling();
        });
        socket.on("order:create", (data) => {
          // console.log("bestellijst creat test")
          fetchBestelling();
        });
        socket.on("order:delete", (data) => {
          // copyLastOrder();
        })

        socket.on("disconnect", () => {
          // console.log("Socket disconnected");
        });
      });
    }
  }, [socket]);


  const groupedOrders = {};

  bestellijst.forEach(order => {
    const username = order.attributes.guestname;

    if (!groupedOrders[username]) {
      groupedOrders[username] = [];
    }

    if (order.attributes.huidigeOrder === true) {
      groupedOrders[username].push(order);
    }
  });

  console.log("groupppasdsad", groupedOrders)

    return (
      <div className="hoofd-container">
        <div className="bestellijst-container">
          {/* <ul>
                {bestellijst.map((bestelling, index) => (
                    <li className="bestellist" key={bestelling.id}>
                        {bestelling.attributes.productnaam}
                        {bestelling.attributes.productprijs}
                        <br></br>
                        extra: {bestelling.attributes.lijst.split('\n').map((item, index) => (
                            <p className="space" key={index}>{item.trim()}</p>
                        ))}
                        €{parseFloat(bestelling.attributes.totaalprijs).toFixed(2)}<br></br>
                        Quantity: {bestelling.attributes.quantity} <br></br>
                    Voor wie: {bestelling.attributes.guestname}<br></br>
                        <button className="bonbutton" value="-" onClick={() => handleIncrement(index, '-')}>-</button>
                        <button className="bonbutton" value="+" onClick={() => handleIncrement(index, '+')}>+</button>
                    </li>
                ))}
            </ul> */}
          {Object.keys(groupedOrders).map(username => (
            <div key={username}>
              <h2>{username}</h2>
              <ul>
                {groupedOrders[username].map((order, index) => (
                  <li className="bestellist" key={order.id}>
                    {order.attributes.productnaam}<br></br>
                    {order.attributes.quantity}<br></br>
                    €{parseFloat(order.attributes.totaalprijs).toFixed(2)}<br></br>
                    <button className="bonbutton" value="-" onClick={() => handleIncrement(username, index, '-')}>-</button>
                    <button className="bonbutton" value="+" onClick={() => handleIncrement(username, index, '+')}>+</button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
            <div className="totaalprijs">
                <h3>totaalprijs</h3>
                €{finalprice.toFixed(2)}
            </div>
            {profile.klaarBestellen !== true ? (
            <button className="bestellist-done" onClick={confirmReady}>Klaar</button>
            ) : (
              <button className="bestellist-done" onClick={confirmReady}>Niet klaar</button>
            )}
            
          <button className="bestellist-done" onClick={updateMenu}>Update Menu</button>
          <button className="bestellist-done" onClick={copyLastOrder}>
            Copy Last Order
          </button>
        </div>
      </div>
    );
};

export default Bestellijst;
import useSocketConnection from "../../socket";
import {
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { CgWebsite } from "react-icons/cg";
import { SiGmail } from "react-icons/si";
import { API, AVATAR_API } from "../../constants";
import { getToken } from "../../helpers";
import { BEARER } from "../../constants";
import useProfileStats from "../Level/levels";

const SocialCards = () => {
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const { profilesStats, isLoadingStats, levelsStats, fetchLevelsAndProfiles } = useProfileStats();

    const socket = useSocketConnection();
    const fetchProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users?populate=*`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                  },
            });
            const data = await response.json();
            setProfiles(data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfiles();
    }, []);
    const token = getToken()
    // console.log(token)

    useEffect(() => {
        // Listen for socket events and handle them
        if (socket) {
            socket.on("connect", () => {
                // console.log("socialcards on")
                // socket.onAny((data) => {
                //     console.log("socialcard test", data)
                //     fetchProfiles();
                // })
                socket.on("users-permissions:update", (user) => {
                    try {
                        // console.log("update user test", user);
                        // fetchProfiles();
                    } catch (error) {
                        console.error("Error in user:update event:", error);
                    }
                });
                socket.on("disconnect", () => {
                    // console.log("Socket disconnected");
                });
            });
        }   
    }, [socket]);

    if (isLoading) {
        return <Spin size="large" />;
    }

    return (
        <Row gutter={[32, 32]}>
            {profiles.map((profile, index) => (
                <Col md={8} lg={8} sm={24} xs={24} key={`${profile.id}_${index}`}>
                    <Card className="social_card">
                        <Space
                            className="social_card_space"
                            direction="vertical"
                            align="center"
                        >
                            <Image
                                className="social_image"
                                max-height={319}
                                preview={true}
                                src={
                                    profile.avatar_url ? `${API}/../${profile.avatar_url.url}` : `${AVATAR_API}?name=${profile.username}&background=1890ff&color=fff`
                                }
                            />
                            <Typography.Title level={5}>{profile.username}</Typography.Title>
                            <Typography.Title level={5}>{profile.totaalOntvangen}</Typography.Title>
                            <Typography.Paragraph>{profile.about}</Typography.Paragraph>
                            <Space size={16} wrap>
                                {profile.email && (
                                    <Button
                                        className="social_button gmail"
                                        href={`mailto:${profile.email}`}
                                        type="link"
                                        target="_blank"
                                    >
                                        <SiGmail size={24} />
                                    </Button>
                                )}
                            </Space>

                        </Space>
                    </Card>
                </Col>
            ))}
        </Row>
    );
};

export default SocialCards;

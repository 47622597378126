import '../Card/card.css';
import './flippable-card.css';
import Cardd from '../Card/card';
import { CSSTransition } from 'react-transition-group';
import { useState, useEffect } from 'react';
import { API } from '../../constants';
import { getToken } from '../../helpers';
import { message } from 'antd';
import { useAuthContext } from '../../context/AuthContext';

function FlippableCard({ card, id }) {
    const [showFront, setShowFront] = useState(true);
    const [cardFlipped, setCardFlipped] = useState(false);
    const { user, setUser } = useAuthContext();


    useEffect(() => {

        // Set the initial state based on the turned attribute when the component mounts
        setShowFront(!card.attributes.turned);

    }, [card.attributes.turned]);


    const test = async () => {
        if (card.attributes.turned === false && !cardFlipped) {

            const data = {
            'data': {
                turned: true,
            }
        };

        try {
            const response = await fetch(`${API}/levelitems/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();
            console.log(data.data)

            if (response.ok) {
                message.success("Achievement unlocked!!");
                if (card.attributes.itemname === "xp") {
                    try {
                        // Fetch the current user data including XP
                        const response = await fetch(`${API}/users/${user.id}`, {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${getToken()}`,
                            },
                        });

                        const userData = await response.json();

                        if (!response.ok) {
                            console.error(`Error fetching user data for user ${user.id}:`, userData);
                            return; // Stop execution if there's an error fetching user data
                        }
                        var min = 100;
                        var max = 600;
                        var rand = min + (Math.random() * (max - min));
                        const currentxp = userData.xp;
                        const extraxp = currentxp + parseInt(rand);

                        const data = {
                            id: user.id,
                            xp: extraxp,
                        };

                        const updateResponse = await fetch(`${API}/users/${user.id}`, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${getToken()}`,
                            },
                            body: JSON.stringify(data),
                        });

                        const updateData = await updateResponse.json();

                        if (!updateResponse.ok) {
                            console.error(`Error updating xp for user ${user.id}:`, updateData);
                        } else {
                            message.success(`You got ${rand.toFixed(0)} XP!!`);

                            console.log(`Successfully updated xp for user ${user.id}`);
                        }
                    } catch (error) {
                        console.error(error);
                        message.error("Network Error While Updating the Profile!");
                    }
                }

                setCardFlipped(true); // Set the state to indicate that the card has been flipped

            } else {
                console.error("Error turning card:", responseData);
                message.error("Error While turning card!");
            }


        } catch (error) {
            console.error("Error in consoleStats:", error);
            message.error("An error occurred while turning card.");
        }
        } else {
            console.log("is al geflipt")
        }   



    }


    // console.log(card.attributes.itemname)


    return (
        <div className="flippable-card-container">
            <CSSTransition
                in={showFront}
                timeout={300}
                classNames='flip'
            >
                <div className="cardd" onClick={() => {
                    setShowFront((v) => !v);
                    test();
                }}>
                    <div className="card-back">
                        <div>{card.attributes.itemname}</div>
                        <div>{card.attributes.description}</div>
                        {/* <button>no</button> */}
                    </div>
                    <div className="card-front">Kaart</div>
                </div>
            </CSSTransition>
        </div>
    );
}

export default FlippableCard;
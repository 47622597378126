// import { useEffect, useState } from "react";
// import { API } from "../../constants";
// import { getToken } from "../../helpers";
// import { message } from "antd";
// import { useAuthContext } from "../../context/AuthContext";
// import debounce from "lodash/debounce";
// import useSocketConnection from "../../socket";

// const useProfileStats = () => {
//     const [profiles, setProfiles] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [levels, setLevels] = useState([]);
//     const { user, setUser } = useAuthContext();
//     const [userLevel, setUserLevel] = useState(null);
//     const [userprofiles, setUserProfiles] = useState([]);
//     const [prevUserLevel, setPrevUserLevel] = useState(null);
//     const [isDataReady, setIsDataReady] = useState(false);
//     const [XP, setXP] = useState(false);
//     const [achievedLevels, setAchievedLevels] = useState([]);



//     const fetchProfiles = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/users?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             setProfiles(data ?? []);
//             // Map profiles to get current XP
//             console.log("all users", data)
//             data.map((alldata) => {
//                 console.log(alldata.levels[0]?.level)
//             })
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchUserProfiles = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/users/${user.id}?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             // console.log('User Profiles Data:', data);
//             setUserProfiles(data ?? []);
//             setUserLevel(data.levels[0]?.level);
//             console.log("user", userLevel)
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         } finally {
//             setIsLoading(false);
//         }
//     };




//     const fetchLevels = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/levels?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             setLevels(data.data ?? []);
//             // console.log(levels)
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching levels!");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchUserProfiles();
//         fetchProfiles();
//         fetchLevels();

//     }, []);



//     // const updateLevelofXP = () => {
//     //     profiles.forEach((profile) => {
//     //         const userXP = profile.xp || 0;
//     //         const closestLevel = levels.reduce((closest, level) => {

//     //             const levelXP = level.attributes.xp;
//     //             return levelXP <= userXP && levelXP > closest.attributes.xp ? level : closest;
//     //         }, levels[0]);
//     //         // console.log("close level", closestLevel)

//     //         if (closestLevel) {
//     //             // Check if XP has changed
//     //             if (userXP !== profile.lastXP) {
//     //                 setXP(true)
//     //                 profile.lastXP = userXP;
//     //                 console.log("laatste xp ", profile.lastXP)
//     //                 // console.log(userXP)
//     //                 // Update the profile's levels based on the calculated closest level
//     //                 profile.levels = [closestLevel];
//     //                 // console.log("user levels", profile.levels)
//     //             }
//     //         }
//     //     });
//     // };

//     // const updateLevelofXP = () => {
//     //     const profilesWithChangedXP = [];

//     //     profiles.forEach((profile) => {
//     //         const userXP = profile.xp || 0;
//     //         const closestLevel = levels.reduce((closest, level) => {
//     //             const levelXP = level.attributes.xp;
//     //             return levelXP <= userXP && levelXP > closest.attributes.xp ? level : closest;
//     //         }, levels[0]);

//     //         if (closestLevel) {
//     //             // Check if XP has changed
//     //             if (userXP !== profile.lastXP) {
//     //                 setXP(false);
//     //                 console.log("iets werkt hier met xp", userXP, profile.lastXP);
//     //                 profile.lastXP = userXP;
//     //                 profile.levels = [closestLevel];
//     //                 profilesWithChangedXP.push(profile);
//     //             }
//     //         }
//     //     });
//     //     console.log("hier vernderdedn profiel xp ja", profilesWithChangedXP)
//     //     return profilesWithChangedXP;

//     // };




//     const updateLevelofXP = () => {
//         const profilesWithChangedXP = [];

//         profiles.forEach((profile) => {
//             const userXP = profile.xp || 0;
//             const closestLevel = levels.reduce((closest, level) => {
//                 const levelXP = level.attributes.xp;
//                 return levelXP <= userXP && levelXP > closest.attributes.xp ? level : closest;
//             }, levels[0]);

//             if (closestLevel) {
//                 // Check if XP has changed
//                 if (userXP !== profile.lastXP) {
//                     setXP(false);
//                     console.log("iets werkt hier met xp", userXP, profile.lastXP);
//                     profile.lastXP = userXP;
//                     profile.levels = [closestLevel];
//                     profilesWithChangedXP.push(profile);

//                     // Check if the level is not already achieved and update the achieved levels array
//                     if (!achievedLevels.includes(closestLevel.id)) {
//                         setAchievedLevels((prevLevels) => [...prevLevels, closestLevel.id]);
//                     }
//                 }
//             }
//         });

//         console.log("hier vernderdedn profiel xp ja", profilesWithChangedXP);
//         return profilesWithChangedXP;
//     };


//     const updateLevel = async () => {
//         const profilesWithChangedXP = updateLevelofXP();
//         console.log("profileschangedxp", profilesWithChangedXP)

//         try {
//             // Map profiles to the data format expected by the server
//             const data = profilesWithChangedXP.map((profile) => ({
//                 id: profile.id,
//                 data: {
//                     levels: [profile.levels[0]?.id || 1],
//                 },
//             }));
//             // console.log("profiel met veranderde xp", profilesWithChangedXP);
//             // Introduce a delay between each update to reduce simultaneous requests
//             const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//             // Use Promise.all to wait for all updates to complete
//             await Promise.all(data.map(async (entry) => {
//                 const response = await fetch(`${API}/users/${entry.id}`, {
//                     method: "PUT",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${getToken()}`,
//                     },
//                     body: JSON.stringify(entry.data),
//                 });
//                 const responseData = await response.json();

//                 // Check if the update was successful
//                 if (!response.ok) {
//                     console.error(`Error updating user ${entry.id}:`, responseData);
//                 } else {
//                     // Update prevXP after successfully updating profiles
//                     const updatedProfile = profiles.find((profile) => profile.id === entry.id);
//                     if (updatedProfile) {
//                         updatedProfile.lastXP = updatedProfile.xp;
//                     }
//                 }

//                 // Introduce a delay between updates
//                 await delay(50); // Adjust the delay duration as needed
//             }));
//             setXP(false);
//         } catch (error) {
//             console.error(error);
//             message.error("Network Error While Updating the Profile!");
//         }
//     };

//     // profiles.map((ja) => {
//     //     console.log("test aaxxxpxp", ja.lastXP)

//     // })
//     useEffect(() => {

//         const fetchData = async () => {
//             await fetchUserProfiles();
//             await fetchLevels();
//             // The userLevel state is updated by now, so call updateLevel here
//             updateLevel();


//         };

//         fetchData();
//     }, [user, userLevel, prevUserLevel]);


//     const randomAchievement = async (userId) => {
//         try {
//             const response = await fetch(`${API}/achievements`, {
//                 method: "GET",
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             const allAchievements = data.data ?? [];

//             if (allAchievements.length > 0) {
//                 const randomIndex = Math.floor(Math.random() * allAchievements.length);
//                 const randomAchievement = allAchievements[randomIndex];

//                 const data = {
//                     'data': {
//                         itemname: randomAchievement.attributes.name,
//                         users: {
//                             id: userId
//                         }
//                     }
//                 };

//                 const response = await fetch(`${API}/levelitems`, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${getToken()}`
//                     },
//                     body: JSON.stringify(data),
//                 })

//                 const responseData = await response.json();

//                 if (response.ok) {
//                     // Show achievement to all users
//                     message.success(`Achievement received: ${randomAchievement.attributes.name}`);
//                 } else {
//                     console.error("Error updating order list:", responseData);
//                     message.error("Error While Updating the Order List!");
//                 }
//             } else {
//                 console.log("No achievements available.");
//             }
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         }
//     };


//     // useEffect(() => {
//     //     if (prevUserLevel !== null && userLevel > prevUserLevel) {
//     //         message.success(`Congratulations! You leveled up to Level ${userLevel}`);
//     //         // Trigger achievement for the specific user
//     //         randomAchievement(user.id);
//     //     }

//     //     // Update the previous user level
//     //     setPrevUserLevel(userLevel);
//     // }, [userLevel, prevUserLevel, profiles]);


//     useEffect(() => {
//         // Check if any user leveled up
//         const usersLeveledUp = profiles.filter(profile => profile.levels[0]?.level > prevUserLevel);
//         console.log("hahahaha", prevUserLevel)

//         // Trigger achievement for each user who leveled up
//         usersLeveledUp.forEach(user => {
//             randomAchievement(user.id);
//         });

//         // Update the previous user level
//         setPrevUserLevel(userLevel);
//     }, [userLevel, prevUserLevel, profiles]);




//     // this const is used to call this on in another file
//     const fetchLevelsAndProfiles = () => {
//         fetchLevels();
//         fetchProfiles();

//     }







//     // Automatically run the check when profiles change
//     useEffect(() => {
//         updateLevel();

//     }, [profiles]);

//     return { profiles, isLoading, levels, fetchLevelsAndProfiles };
// };

// export default useProfileStats


// import { useEffect, useState } from "react";
// import { API } from "../../constants";
// import { getToken } from "../../helpers";
// import { message } from "antd";
// import { useAuthContext } from "../../context/AuthContext";
// import debounce from "lodash/debounce";
// import useSocketConnection from "../../socket";

// const useProfileStats = () => {
//     const [profiles, setProfiles] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [levels, setLevels] = useState([]);
//     const [prevLevels, setPrevLevels] = useState([])
//     const { user, setUser } = useAuthContext();
//     const [userLevel, setUserLevel] = useState(null);
//     const [userprofiles, setUserProfiles] = useState([]);
//     const [achievedLevels, setAchievedLevels] = useState([]);
//     const [isUpdating, setIsUpdating] = useState(false);

//     const fetchProfiles = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/users?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             setProfiles(data ?? []);
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchUserProfiles = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/users/${user.id}?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             setUserProfiles(data ?? []);
//             setUserLevel(data.levels[0]?.level);
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const fetchLevels = async () => {
//         setIsLoading(true);
//         try {
//             const response = await fetch(`${API}/levels?populate=*`, {
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             setLevels(data.data ?? []);
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching levels!");
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const updateLevelofXP = () => {
//         profiles.forEach((profile) => {
//             const userXP = profile.xp || 0;
//             const closestLevel = levels.reduce((closest, level) => {
//                 const levelXP = level.attributes.xp;
//                 return levelXP <= userXP && levelXP > closest.attributes.xp ? level : closest;
//             }, levels[0]);

//             if (closestLevel) {
//                 if (userXP) {
//                     profile.levels = [closestLevel];
//                 }
//             }
//         });
//     };

//     const updateLevel = async () => {
//         if (isUpdating) {
//             return;
//         }

//         setIsUpdating(true);
//         updateLevelofXP()

//         try {
//             const data = profiles.map((profile) => ({
//                 id: profile.id,
//                 data: {
//                     levels: [profile.levels[0]?.id || 1],
//                     // prevlevel: [profile.levels[0]?.id - 1 || 1],
//                 },
//             }));

//             const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//             await Promise.all(data.map(async (entry) => {
//                 const response = await fetch(`${API}/users/${entry.id}`, {
//                     method: "PUT",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${getToken()}`,
//                     },
//                     body: JSON.stringify(entry.data),
//                 });

//                 const responseData = await response.json();

//                 if (!response.ok) {
//                     console.error(`Error updating user ${entry.id}:`, responseData);
//                 } else {
//                     const updatedProfile = profiles.find((profile) => profile.id === entry.id);
//                     if (updatedProfile) {
//                         console.log(updatedProfile);
//                     }
//                 }

//                 await delay(50);
//             }));
//         } catch (error) {
//             console.error(error);
//             message.error("Network Error While Updating the Profile!");
//         } finally {
//             setIsUpdating(false);
//         }
//     };

//     useEffect(() => {
//         fetchUserProfiles();
//         fetchProfiles();
//         fetchLevels();
//     }, []);

//     const randomAchievement = async (userId) => {
//         try {
//             const response = await fetch(`${API}/achievements`, {
//                 method: "GET",
//                 headers: {
//                     Authorization: `Bearer ${getToken()}`,
//                 },
//             });
//             const data = await response.json();
//             const allAchievements = data.data ?? [];

//             if (allAchievements.length > 0) {
//                 const randomIndex = Math.floor(Math.random() * allAchievements.length);
//                 const randomAchievement = allAchievements[randomIndex];

//                 const data = {
//                     'data': {
//                         itemname: randomAchievement.attributes.name,
//                         users: {
//                             id: userId
//                         }
//                     }
//                 };

//                 const response = await fetch(`${API}/levelitems`, {
//                     method: "POST",
//                     headers: {
//                         "Content-Type": "application/json",
//                         Authorization: `Bearer ${getToken()}`
//                     },
//                     body: JSON.stringify(data),
//                 });

//                 const responseData = await response.json();

//                 if (response.ok) {
//                     message.success(`Achievement received: ${randomAchievement.attributes.name}`);
//                 } else {
//                     console.error("Error updating order list:", responseData);
//                     message.error("Error While Updating the Order List!");
//                 }
//             } else {
//                 console.log("No achievements available.");
//             }
//         } catch (error) {
//             console.error(error);
//             message.error("Error while fetching profiles!");
//         }
//     };

//     useEffect(() => {
//         const updatePrevLevels = async () => {
//             try {
//                 const updatePromises = profiles.map(async (profile) => {
//                     const data = {
//                         id: profile.id,
//                         prevlevel: profile.levels[0]?.id || 1,
//                     };

//                     const response = await fetch(`${API}/users/${profile.id}`, {
//                         method: "PUT",
//                         headers: {
//                             "Content-Type": "application/json",
//                             Authorization: `Bearer ${getToken()}`,
//                         },
//                         body: JSON.stringify(data),
//                     });

//                     const responseData = await response.json();

//                     if (!response.ok) {
//                         console.error(`Error updating user ${profile.id}:`, responseData);
//                     } else {
//                         const updatedProfile = profiles.find((p) => p.id === profile.id);
//                         if (updatedProfile) {
//                             console.log(updatedProfile);
//                         }
//                     }

//                     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
//                     await delay(50);
//                 });

//                 await Promise.all(updatePromises);
//             } catch (error) {
//                 console.error(error);
//                 message.error("Network Error While Updating the Profile!");
//             }
//         };

//         const handleAchievements = () => {
//             const usersLeveledUp = profiles.filter(profile => profile.levels[0]?.id > profile.prevlevel);

//             usersLeveledUp.forEach(user => {
//                 randomAchievement(user.id);
//             });
//         };

//         const updateAndHandleAchievements = async () => {
//             await updatePrevLevels();
//             handleAchievements();
//         };

//         updateAndHandleAchievements();
//     }, [userLevel, profiles]);

//     const fetchLevelsAndProfiles = () => {
//         fetchLevels();
//         fetchProfiles();
//     }

//     useEffect(() => {
//         updateLevel();
//     }, [profiles]);

//     return { profiles, isLoading, levels, fetchLevelsAndProfiles };
// };

// export default useProfileStats;





import { useEffect, useState } from "react";
import { API } from "../../constants";
import { getToken } from "../../helpers";
import { message } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import debounce from "lodash/debounce";
import useSocketConnection from "../../socket";

const useProfileStats = () => {
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [levels, setLevels] = useState([]);
    const { user, setUser } = useAuthContext();
    const [userLevel, setUserLevel] = useState(null);
    const [userprofiles, setUserProfiles] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [areLevelsUpdated, setAreLevelsUpdated] = useState(false);


    const fetchProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users?populate=*`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setProfiles(data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users/${user?.id}?populate=*`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setUserProfiles(data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching user profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLevels = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/levels?populate=*`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setLevels(data.data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching levels!");
        } finally {
            setIsLoading(false);
        }
    };

    const updateLevel = async () => {
        if (isUpdating) {
            return;
        }

        setIsUpdating(true);

        try {
            const updatedProfiles = profiles.map((profile) => {
                const userXP = profile.xp || 0;
                const userPrevXP = profile.prevxp || 0;

                // Check if there is a change in XP
                if (userXP !== userPrevXP) {
                    const closestLevel = levels.reduce((closest, level) => {
                        const levelXP = level.attributes.xp;
                        return levelXP <= userXP && levelXP > closest.attributes.xp ? level : closest;
                    }, levels[0]);

                    if (closestLevel) {
                        return {
                            id: profile?.id,
                            data: {
                                levels: [closestLevel],
                                prevlevel: profile.levels[0]?.id || 1,
                            },
                            userXP: userXP, // Store userXP for later use
                        };
                    }
                }

                return null;
            }).filter(Boolean);

            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

            await Promise.all(updatedProfiles.map(async (entry) => {
                const response = await fetch(`${API}/users/${entry?.id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(entry.data),
                });

                const responseData = await response.json();
                await delay(150);

                if (!response.ok) {
                    console.error(`Error updating user ${entry.id}:`, responseData);
                } else {
                    console.log(`Successfully updated levels for user ${entry.id}`);
                    // Set prevxp to current xp after the update
                    await updatePrevXP(entry.id, entry.userXP); // Use stored userXP
                }
            }));
        } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Profile!");
        } finally {
            setIsUpdating(false);
        }
    };


    const updatePrevXP = async (userId, currentXP) => {
        try {
            const data = {
                id: userId,
                prevxp: currentXP,
            };

            const response = await fetch(`${API}/users/${userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            if (!response.ok) {
                console.error(`Error updating prevxp for user ${userId}:`, responseData);
            } else {
                console.log(`Successfully updated prevxp for user ${userId} to ${currentXP}`);
            }
        } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Profile!");
        }
    };

    useEffect(() => {
        fetchUserProfiles();
        fetchProfiles();
        fetchLevels();
    }, []);




    useEffect(() => {
        const handleAchievements = async () => {
            const achievementsPromises = profiles.map(async (user) => {
                const prevLevel = user?.prevlevel || 1;
                const currentLevel = user?.levels[0]?.id || 1;

                if (currentLevel > prevLevel) {
                    const levelsGained = currentLevel - prevLevel;
                    const updatedProfile = profiles.find((p) => p.id === user.id);

                    if (updatedProfile) {
                        updatedProfile.prevlevel = currentLevel;
                        await updatePrevLevel(updatedProfile.id, updatedProfile.prevlevel);

                        const promises = Array.from({ length: levelsGained }, () => awardRandomAchievement(user.id));
                        await Promise.all(promises);

                        updatedProfile.hasReceivedAchievement = true;
                    }
                }
            });

            await Promise.all(achievementsPromises);
        };


        const updatePrevLevel = async (userId, prevLevel) => {
            try {
                const data = {
                    id: userId,
                    prevlevel: prevLevel,
                };

                const response = await fetch(`${API}/users/${userId}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(data),
                });

                const responseData = await response.json();

                if (!response.ok) {
                    console.error(`Error updating user ${userId}:`, responseData);
                } else {
                    console.log(`Successfully updated prevlevel for user ${userId} to ${prevLevel}`);
                }
            } catch (error) {
                console.error(error);
                message.error("Network Error While Updating the Profile!");
            }
        };


        const awardedAchievements = new Map();

        const awardRandomAchievement = async (userId) => {
            try {
                // If the user is not in the outer map, add them
                if (!awardedAchievements.has(userId)) {
                    awardedAchievements.set(userId, new Map());
                }

                const response = await fetch(`${API}/achievements`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                });

                const data = await response.json();
                const allAchievements = data.data ?? [];

                if (allAchievements.length > 0) {
                    const randomIndex = Math.floor(Math.random() * allAchievements.length);
                    const randomAchievement = allAchievements[randomIndex];

                    // Check if the achievement has already been awarded to the user
                    if (!hasAchievementBeenAwarded(userId, randomAchievement.id)) {
                        const achievementData = {
                            'data': {
                                itemname: randomAchievement.attributes.name,
                                users: {
                                    id: userId,
                                },
                            },
                        };

                        const achievementResponse = await fetch(`${API}/levelitems`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${getToken()}`,
                            },
                            body: JSON.stringify(achievementData),
                        });

                        const achievementResponseData = await achievementResponse.json();

                        if (achievementResponse.ok && userId) {
                            // Mark the user as having received an achievement
                            message.success(`Achievement received: ${randomAchievement.attributes.name}`);
                            // Set the flag to indicate that the achievement has been awarded
                            markAchievementAsAwarded(userId, randomAchievement.id);
                        } else {
                            console.error("Error updating level items:", achievementResponseData);
                            message.error("Error While Updating Level Items!");
                        }
                    } else {
                        console.log(`Achievement already awarded to user ${userId}.`);
                    }
                } else {
                    console.log("No achievements available.");
                }
            } catch (error) {
                console.error(error);
                message.error("Error while fetching achievements!");
            }
        };

        // Helper function to check if the achievement has already been awarded to the user
        const hasAchievementBeenAwarded = (userId, achievementId) => {
            if (awardedAchievements.has(userId)) {
                const userAchievements = awardedAchievements.get(userId);
                return userAchievements.has(achievementId);
            }
            return false;
        };

        // Helper function to mark the achievement as awarded to the user
        const markAchievementAsAwarded = (userId, achievementId) => {
            if (awardedAchievements.has(userId)) {
                const userAchievements = awardedAchievements.get(userId);
                // userAchievements.set(achievementId, true);
            }
        }


        // Call the function to handle achievements
        handleAchievements();
    }, [profiles]);


    const fetchLevelsAndProfiles = () => {
        fetchLevels();
        fetchProfiles();
    };

    useEffect(() => {
        updateLevel();
    }, [profiles]);

    return { profiles, isLoading, levels, fetchLevelsAndProfiles };
};

export default useProfileStats;
import { socketConnectionPromise } from "../../socket";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Spin,
    Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constants";
import { setToken } from "../../helpers";
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { BEARER } from "../../constants";


const SignIn = () => {
    const { isDesktopView } = useScreenSize();
    const navigate = useNavigate();

    const { setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const onFinish = async (values) => {
        setIsLoading(true);
        try {
            const value = {
                identifier: values.username,
                password: values.password,
            };
            const response = await fetch(`${API}/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();
            if (data?.error) {
                // throw data?.error;
            } else {
                setToken(data.jwt);
                setUser(data.user);
                navigate("/profile");



                message.success(`Welcome back ${data.user.username}!`);


            }
        } catch (error) {
            // console.error(error);
            setError(error?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
            console.log("hoi")

        }
    };

    return (
        <Fragment>
            <Row align="middle">
                <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
                    <Card title="SignIn">
                        {error ? (
                            <Alert
                                className="alert_error"
                                message={error}
                                type="error"
                                closable
                                afterClose={() => setError("")}
                            />
                        ) : null}
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        type: "username",
                                    },
                                ]}
                            >
                                <Input placeholder="username" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login_submit_btn"
                                >
                                    Login {isLoading && <Spin size="small" />}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Typography.Paragraph className="form_help_text">
                            Nieuw met bestellen? <Link to="/signup">Sign Up</Link>
                            <br />
                            Wachtwoord vergeten? <Link to="/forgotpassword">Wachtwoord vergeten
                            </Link>
                        </Typography.Paragraph>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SignIn;


import React, { useEffect, useState } from "react";
import { message } from "antd"; // Assuming you are using Ant Design for the message component
import { API } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
import { BEARER } from "../../constants";
import Bestellijst from "./bestellijst";

const Popup = ({ onClose, isOpen, desiredProduct, desiredProductPrice, IDmimi, refreshBestelling, guestName }) => {
    const [options, setOptions] = useState([]); 
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const { user, setUser } = useAuthContext();
    const [profile, setProfiles] = useState([]);


    useEffect(() => {
        const fetchOptions = async () => {
            // console.log(IDmimi);
            try {
                const response = await fetch(`${API}/options?filters[IDmimi][$eq]=${IDmimi}`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${getToken()}`,
                    }
                  });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setOptions(data.data ?? []);
                // console.log(data.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchProfile = async () => {
            setIsLoading(true);
            try {
                if (user) { // Check if user is defined
                    const response = await fetch(`${API}/users/${user.id}`, {
                        method: 'GET',
                        headers: {
                          'Authorization': `Bearer ${getToken()}`,
                        }
                      });
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const data = await response.json();
                    setProfiles(data ?? []);
                    // console.log("Fetched Profile:", user);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isOpen) {
            setIsLoading(true);
            fetchOptions();
            fetchProfile();
        }
    }, [isOpen]);


    const handleCheckboxChange = (optionId) => {
        if (IDmimi === '921' || IDmimi === '975') {
            setSelectedOptions([optionId]);
        } else {
            setSelectedOptions((prevSelectedOptions) => {
                if (prevSelectedOptions.includes(optionId)) {
                    return prevSelectedOptions.filter((id) => id !== optionId);
                } else {
                    return [...prevSelectedOptions, optionId];
                }
            });
        }
    };


    // const filteredOptions = options.filter((option) =>
    //     option.products.includes(desiredProduct)
    // );


    const sendBestelling = async () => {
        try {
            // console.log(selectedOptions);
            const sanitizedProductPrice = desiredProductPrice.replace(/[^0-9.,]/g, ''); // Remove non-numeric characters except dots and commas
            const productPrice = parseFloat(sanitizedProductPrice.replace(',', '.'));
            // console.log(productPrice)
            // Get selected options based on their IDs
            const selectedOptionsData = selectedOptions.map((optionId) => {
                // console.log(optionId);
                const selectedOption = options.find((option) => option.id === optionId);
                // console.log(selectedOption);
                if (selectedOption.attributes.price) {
                    return {
                    label: selectedOption.attributes.label,
                    price: parseFloat(selectedOption.attributes.price).toFixed(2),
                };
                } else {
                    return {
                        label: selectedOption.attributes.label,
                        price: 0,
                    }
                }

            });


            const totalPrice = productPrice + selectedOptionsData.reduce((total, option) => total + parseFloat(option.price), 0);

            // Format selected options as a string to be stored in the 'lijst' field
            const lijst = selectedOptionsData
                .map((option) => `${option.label} €${option.price}`)
                .join('\n'); // Assuming you want to separate options by new lines


            let guestNam;

            if (guestName === null || guestName === "") {
                guestNam = user.username;
            } else {
                guestNam = guestName;
            }

            const data = {
                'data': {
                    productnaam: desiredProduct,
                    productprijs: desiredProductPrice,
                    lijst: lijst,
                    totaalprijs: totalPrice.toString(),
                    users: {
                        id: user.id
                    },
                    huidigeOrder: true,
                    guestname: guestNam,
                }
            };

            const response = await fetch(`${API}/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                          'Authorization': `Bearer ${getToken()}`,
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            if (response.ok) {
                message.success("Order list updated successfully!");
            } else {
                console.error("Error updating order list:", responseData);
                message.error("Error While Updating the Order List!");
            }
        } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Order List!");
        }
    };


    // const sendStats = async () => {
    //     const currentdate = new Date();
    //     const hours = currentdate.getHours().toString().padStart(2, '0');
    //     const minutes = currentdate.getMinutes().toString().padStart(2, '0');
    //     const seconds = currentdate.getSeconds().toString().padStart(2, '0');
    //     const milliseconds = currentdate.getMilliseconds().toString().padStart(3, '0');
    //     const data = {
    //         'data': {
    //             datum: currentdate,
    //             tijd: `${hours}:${minutes}:${seconds}:${milliseconds}`,
    //             totaalOntvangen: 1,
    //             totaalUitgegeven: 1,
    //             users: {
    //                 id: user.id
    //             }
    //         }
    //     };

    //     const response = await fetch(`${API}/statistics`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${getToken()}`
    //         },
    //         body: JSON.stringify(data),


    //     })
    //     const responseData = await response.json();

    //     if (response.ok) {
    //         message.success("Order list updated successfully!");
    //     } else {
    //         console.error("Error updating order list:", responseData);
    //         message.error("Error While Updating the Order List!");
    //     }
    // }


    const buttonFunction = async () => {
        if (profile.meeBestellen === true && profile.klaarBestellen === false) {
            try {
                await sendBestelling();
                // await sendStats();
                refreshBestelling();
            onClose();
            } catch (error) {
            }
        }

    };



    return (
        <div className="popup">
            <div className="popup-content">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                        <form action="/">
                            <h4>{desiredProduct} {desiredProductPrice}</h4>
                            <ul>
                                {options.map((option) => (
                                    <li className="popuplist" key={option.id}>
                                        {IDmimi === '921' || IDmimi === '975' ? (
                                            <input
                                                type="radio"
                                                id={option.id}
                                                name="radio-group"
                                                checked={selectedOptions.includes(option.id)}
                                                onChange={() => handleCheckboxChange(option.id)}
                                            />
                                        ) : (
                                                <input
                                                    type="checkbox"
                                                    id={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={() => handleCheckboxChange(option.id)}
                                                />
                                        )}
                                        <label htmlFor={option.id}>
                                            {option.attributes.label} +€{parseFloat(option.attributes.price || "0").toFixed(2)}
                                        </label>
                                    </li>
                                ))}
                            </ul> 
                            <p>{guestName}</p>
                            <button type="button" className="verzendbestelling" onClick={buttonFunction}>
                                Toevoegen aan bestelling
                            </button>
                        </form>
                )}
                <button className="close" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Popup;

import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { getToken } from "./helpers";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Profile from "./components/Profile/Profile";
import SocialCards from "./components/SocialCards/SocialCards";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword"
import Producten from "./pages/Producten/Producten";
import Bestellingen from "./pages/Bestellingen/Bestellingen";
import Betalingen from "./pages/Betalingen/betalingen";
import Stats from "./components/stats/stats";
import SpinApp from "./SpinApp";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { useAuthContext } from "./context/AuthContext";
import BestellingenInzichtNieuw from "./components/bestellinginzichtnieuw/bestellinginzichtnieuw";
import RussianRoulette from "./pages/RussianRoulette/RussianRoulette";

const AppRoutes = () => {
    // const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const { isAuthenticated } = useAuthContext();



    return (
        <Routes>
            <Route path="/socialcards" element={getToken() ? <SocialCards /> : <Navigate to="/" />} />
            <Route path="/stats" element={getToken() ? <Stats /> : <Navigate to="/" />} />
            <Route path="/spinapp" element={getToken() ? <SpinApp /> : <Navigate to="/" />} />
            <Route path="/" element={!getToken() ? <SignIn /> : <Navigate to="/" />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/bestellingen" element={getToken() ? <Bestellingen /> : <Navigate to="/" />} />
            <Route path="/bestellingeninzicht" element={getToken() ? < BestellingenInzichtNieuw /> : <Navigate to="/" />} />
            <Route path="/betalingen" element={getToken() ? <Betalingen /> : <Navigate to="/" />} />
            <Route path="/profile" element={getToken() ? <Profile /> : <Navigate to="/" />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/Producten" element={getToken() ? <Producten /> : <Navigate to="/" />} />
            <Route path="/russianroulette" element={getToken() ? <RussianRoulette /> : <Navigate to="/" />} />
        </Routes>
    );
};

export default AppRoutes;



import React from "react";
import { Button, Card, Col, Form, Input, message, Row, Spin, Space } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constants";
import { useState, useEffect } from "react";
import { getToken } from "../../helpers";
import { useFormik } from "formik"
import CheckLevel from "../Level/levels";
import FlippableCard from "../FlippableCard/flippable-card";
import { CSSTransition } from 'react-transition-group';

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const { user, isLoading, setUser } = useAuthContext();
  const check = CheckLevel();
  const [cards, setCards] = useState([])
  const [showFront, setShowFront] = useState(true);
  const [showFrontStates, setShowFrontStates] = useState([]);
  const [achievements, setAchievements] = useState([])
    // const handleProfileUpdate = async (data) => {
    //     setLoading(true);
    //     console.log("values", data);
    //     try {
    //         const response = await fetch(`${API}/users/${user.id}`, {
    //             method: "PUT",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 // set the auth token to the user's jwt
    //                 Authorization: `Bearer ${getToken()}`,
    //             },
    //             body: JSON.stringify(data),
    //         });
    //         const responseData = await response.json();

    //         setUser(responseData);

            
    //     } catch (error) {
    //         console.error(Error);
    //         message.error("Error While Updating the Profile!");
    //     } finally {
    //         setLoading(false);
    //         message.success("Data saved successfully!");
    //     }
    // };

    // if (isLoading) {
    //     return <Spin size="large" />;
    // }

    const formik = useFormik({
        initialValues: {
          title: "",
        },
    
        onSubmit: async (values) => {
          console.log("values", values)
    
          const formData = new FormData()
    
          const data = {
            title: values.title,
          }
          console.log(values.title)
    
          console.log("data", data)
    
          formData.append("data", JSON.stringify(data))
    
          //single-file
    
          formData.append("files.single", values.singleFile)
    
          //multiple-files
        //   for (let i = 0; i < values.multiFile.length; i++) {
        //     formData.append(`files.multi`, values.multiFile[i])
        //   }
    
          //for upload page
          const uploadData = new FormData()
          uploadData.append("files", values.singleFile)
    
          //update collection
    
          const updateArticle = await fetch(
            `http://localhost:1337/api/users/2`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
              body: formData,
            }
          )
          if (updateArticle.ok) {
            // setUser(responseData);
            message.success("Data saved successfully!");
            // console.log("updatakans");
          } else {
            console.error("Error updating user:", updateArticle);
            message.error("Error While Updating the Profile!");
          }
          console.log(formData);
          const updateData = await updateArticle.json()
          console.log("updateArticleRes", updateData)
    
          // simple create new collection with JSON
    
        //   const createArticle = await fetch("http://localhost:1337/api/users", {
        //     method: "POST",
        //     body: formData,
        //     headers: {},
        //   })
        //   const createRes = await createArticle.json()
        //   console.log("createArticleRes", createRes)
    
          //upload file to uploads
    
          const uploadFile = await fetch("http://localhost:1337/api/upload", {
            method: "POST",
            body: uploadData,
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          })
          const uploadRes = await uploadFile.json()
          console.log("uploadRes", uploadRes)
        },
      })

  const fetchCards = async () => {
    // setIsLoading(true);
    try {
      const response = await fetch(`${API}/levelitems?filters[users]=${user.id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      setCards(data.data ?? []);

      // console.log("front states", showFrontStates)
    } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
    } finally {
      // setIsLoading(false);
    }
  };


  const randomAchievement = async () => {
    try {
      const response = await fetch(`${API}/achievements`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      const allAchievements = data.data ?? [];

      // Check if there are any achievements
      if (allAchievements.length > 0) {
        // Get a random index to select a random achievement
        const randomIndex = Math.floor(Math.random() * allAchievements.length);
        const randomAchievement = allAchievements[randomIndex];


        const data = {
          'data': {
            itemname: randomAchievement.attributes.name,
            users: {
              id: user.id
            }
          }
        };

        const response = await fetch(`${API}/levelitems`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`
          },
          body: JSON.stringify(data),


        })
        const responseData = await response.json();

        if (response.ok) {
          message.success("stats updated successfully!");


        } else {
          console.error("Error updating order list:", responseData);
          message.error("Error While Updating the Order List!");
        }
        // Now 'randomAchievement' contains the randomly selected achievement
        console.log(randomAchievement.attributes);
      } else {
        console.log("No achievements available.");
      }
    } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
    } finally {
      // setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchCards();
  }, [user]);


      const onSingleFileChange = (e) => {
        const file = e.target.files
        console.log("onSingleFileChange", file)
        console.log("onSingleFileChange1", file[0])
    
        formik.setFieldValue("singleFile", file[0])
      }

  const handleCardClick = (index) => {
    // Toggle the showFront state for the clicked card
    setShowFrontStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const test = () => {
    console.log("hoi")
  }

    return (

        <Card className="profile_page_card">


            {/* <Form
                layout="vertical"
                initialValues={{
                    username: user?.username,
                    email: user?.email,
                    avatar_url: user?.avatar_url,
                    about: user?.about,
                }}
                onFinish={handleProfileUpdate}
            >
                <Row gutter={[16, 16]}>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Username is required!",
                                    type: "string",
                                },
                            ]} 
                        >
                            <Card name="username" placeholder="Username" />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Email is required!",
                                    type: "email",
                                },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>
                    <Col md={8} lg={8} sm={24} xs={24}>
                        <Form.Item
                            
                            rules={[
                                {
                                    type: "url",
                                },
                            ]}
                        >
                            <Input placeholder="Avatar Url" />
                        </Form.Item>
                        <Form.Item label="Avatar Url"
                            name="avatar_url"
                            rules={[
                                {
                                    type: "file"
                                }
                            ]}><Input
          type="file"
        /></Form.Item>
                        

                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="About"
                            name="about"
                            rules={[
                                {
                                    required: true,
                                    type: "string",
                                    max: 120,
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="About" rows={6} />
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    className="profile_save_btn"
                    htmlType="submit"
                    type="primary"
                    size="large"
                >
                    {loading ? (
                        <>
                            <Spin size="small" /> Saving
                        </>
                    ) : (
                        "Save"
                    )}
                </Button>
            </Form> */} 


            <form
      onSubmit={formik.handleSubmit}
      className={{
        display: "flex",
      }}
    >
      {/* <div>
        <label htmlFor="title">
          Title
        </label>
        <input
          id="title"
          name="title"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.title}
          
        />
      </div> */}
      <div>
        <label htmlFor="email">
          single-file-upload
        </label>
        <input
          id="file"
          name="file"
          type="file"
          onChange={onSingleFileChange}
          value={formik.values.email}
          
        />
      </div>
      <button type="submit">
        Submit
      </button>
    </form>
        <br></br>

        <Row gutter={[50, 50]}>
          {cards?.map((card) => (
            <Col md={8} lg={6} sm={12} xs={24}>
              <FlippableCard key={card?.id} card={card} id={card.id} />
            </Col>
          ))}
        </Row>
        {/* <button onClick={randomAchievement}>random achievement</button> */}
        {/* <Row gutter={[32, 32]}>
          {cards.map((card, index) => (
            <Col md={8} lg={8} sm={24} xs={24} key={`${card.id}_${index}`}>
              <Card className="social_card">
                <Space
                  className="social_card_space"
                  direction="vertical"
                  align="center"
                >
                  <div className="flippable-card-container">
                    <CSSTransition
                      in={showFrontStates[index]}  // Use showFrontStates[index] for each card
                      timeout={300}
                      classNames='flip'
                    >
                      <div className="cardd" onClick={() => handleCardClick(index)}>
                        <div className="card-back">
                          <div>{card.attributes.itemname}</div>
                          <div>{card.attributes.description}</div>
                        </div>
                        <div className="card-front">Front</div>
                      </div>
                    </CSSTransition>
                  </div>
                </Space>
              </Card>
            </Col>
          ))}
        </Row> */}
        </Card>

    );
};

export default Profile;

import { socketConnectionPromise } from "./socket.jsx";
import React from "react";
import { Col, Layout, Row } from "antd";
import AppHeader from "./components/Appheader/Appheader.jsx";
import AppRoutes from "./Routes";
import SpinApp from "./SpinApp";
import { BEARER } from "./constants.js";
import { getToken } from "./helpers.js";
import { useEffect } from "react";
const { Header, Content } = Layout;




const App = () => {



  return (
    <Layout>
      <Row gutter={[0, 32]}>
        <Col span={24}>
          <AppHeader />
        </Col>
        <Col span={22} offset={1}>
          <Content>
            <AppRoutes />
          </Content>
        </Col>
      </Row>
    </Layout>
  );
};

export default App;

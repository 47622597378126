import React, { useEffect, useRef, useState } from "react";

const CanvasBar = ({ numberOfSections, sectionNames, rotation, groottePercentage, ...props }) => {
    const canvasRef = useRef();
    const [currentRotation, setRotation] = useState(0); 
// console.log(groottePercentage);

    const endAngleFunc = (o, sliceAngle, startAngle) => {
        if(o === numberOfSections -1){
            return Math.PI*2
        } else {
            return startAngle + (sliceAngle * groottePercentage[o])
        }
    }

        useEffect(() => {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = 300;
        
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        
            const sliceAngle = (Math.PI * 2) / 100;

            
            
//             ctx.beginPath();
// ctx.moveTo(centerX, centerY);
// ctx.lineTo(centerX + radius, centerY); 
// ctx.lineWidth = 5;
// ctx.stroke();
//             ctx.fillStyle = "#FFFFFF";
//             ctx.beginPath();
//     ctx.arc(centerX, centerY, 30, 0, 2 * Math.PI);
//     ctx.lineWidth = 10;
//     ctx.stroke();
//     ctx.fill();
    // ctx.beginPath();
    // ctx.arc(centerX + radius, centerY, 20, 2*Math.PI/4, 2 * Math.PI/4*3);
    // ctx.lineWidth = 10;
    // ctx.stroke();
    // ctx.fillStyle = '#ff0000';
    //             ctx.fill();
    // ctx.fill();
    // Set the stroke style to red
ctx.strokeStyle = '#02a672';

// First line
// ctx.beginPath();
// ctx.moveTo(centerX + 30, centerY);
// ctx.lineTo(centerX + radius, centerY);
// ctx.lineWidth = 5;
// ctx.stroke();

// Second line
ctx.beginPath();
ctx.moveTo(centerX + radius - 10, centerY);
ctx.lineTo(centerX + radius + 20, centerY - 20);
ctx.lineTo(centerX + radius + 20, centerY + 20);
ctx.lineTo(centerX + radius - 10, centerY);
ctx.lineTo(centerX + radius + 20, centerY - 20);
// ctx.lineTo(centerX + radius + 20, centerY - 20);
// ctx.lineTo(centerX + radius - 10, centerY);
ctx.lineWidth = 7;
ctx.stroke();

ctx.fillStyle = "#02a672";
ctx.fill();

// ctx.beginPath();
// ctx.moveTo(centerX + radius + 20, centerY - 20);
// ctx.lineTo(centerX + radius - 10, centerY);
// ctx.lineWidth = 5;
// ctx.stroke();

// ctx.beginPath();
// ctx.moveTo(centerX + radius + 20, centerY + 20);
// ctx.lineTo(centerX + radius + 20, centerY - 20);
// ctx.lineWidth = 5;
// ctx.stroke();

// Fourth line
// ctx.beginPath();
// ctx.moveTo(centerX + 10, centerY - 12);
// ctx.lineTo(centerX + 30, centerY);
// ctx.lineWidth = 5;
// ctx.stroke();

// // Fifth line
// ctx.beginPath();
// ctx.moveTo(centerX + 10, centerY + 12);
// ctx.lineTo(centerX + 30, centerY);
// ctx.lineWidth = 5;
// ctx.stroke();

        }, [numberOfSections, sectionNames, groottePercentage]);

    
        
        
        useEffect(() => {
            if(rotation !== 0){
                const canvas = canvasRef.current;
            const rotationtotal = (10 * 360) + (rotation * 3.6);
            // const rotationtotal = 360;
            // console.log(rotationtotal);
            canvas.style.transform = `rotate(${-rotationtotal}deg)`;
            }
            
        }, [rotation]);

        return <canvas ref={canvasRef} width={600} height={600} {...props} style={{ transition: "transform 10s ease-in-out", position: 'absolute', zIndex: 2, width:'90%' }} />;
    };

    export default CanvasBar;

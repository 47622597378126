import React, { useState, useEffect } from "react";
import "./index.css";
import PropTypes from "prop-types";
import { BEARER, API } from "../../constants";
import { message } from "antd";
import { getToken } from "../../helpers";

class Wheel extends React.Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
    this.selectItem = this.selectItem.bind(this);
  }
  
  async selectItem2(data) {
    const selectedItem = data.data.attributes.IDinWinnersArray;
    this.props.onChange(selectedItem);
      if (this.props.selectedItem) {
        this.props.onSelectItem(selectedItem);
      }
      this.setState({ selectedItem });
  }
  
  async selectItem() {
    try {
      const response = await fetch(`${API}/users?populate=*`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${getToken()}`,
            },
      });
      const data = await response.json();
      const profiles = (data ?? []);
      const allMeeBestellenFalse = profiles.every(user => user.meeBestellen !== true);
    if (!allMeeBestellenFalse) {
      alert("vorige bestelling nog niet voltooid");
      console.log("vorige bestelling nog niet voltooid");
      return;
    } else {
      if (this.props.items.length === 0 || this.props.spinning === true) {
        alert("Can't spin a empty wheel or spinning wheel");
        console.log("Can't spin a empty wheel or spinning wheel");
        return;
    }


    // try {
    //   const response = await fetch(`${API}/users?filters[meedoen]=true`, {
    //     method: 'GET',
    //     headers: {
    //       'Authorization': `Bearer ${getToken()}`,
    //     }
    //   });
    //   if (response.ok) {
    //     const dataOrder = await response.json();
    //     const names = dataOrder.map(user => user.username);
    //     const items = dataOrder.map(user => user.id);
    //     const times = dataOrder.map(user => user.laatsteBestelTijd);
  
    //   }
    // } finally {

    // }
    // const kansen = localStorage.getItem("kansenList");
    // const bestellingen = localStorage.getItem("bestellingenList");
    // const procentBesteller = bestellingen / kansen * 100;
    // console.log(kansen.length);
    // console.log(kansen, bestellingen);
    // console.log(procentBesteller);
    const procentkansString = localStorage.getItem("procentkans");
    const procentkans = JSON.parse(procentkansString);
    console.log(procentkans);
    if (this.state.selectedItem === null) {
      // const selectedItem = Math.floor(Math.random() * this.props.items.length);
      const randomgenerator = Math.floor(Math.random() * 100);
      const range = procentkans.length;
      let totalvalue = 0
      let amount = 0
      
      while (totalvalue < randomgenerator) {
        const numberprocentkans = parseInt(procentkans[amount]);
        console.log(totalvalue);
        console.log(procentkans[amount]);
        console.log(numberprocentkans + parseInt(totalvalue));
        if((numberprocentkans + parseInt(totalvalue)) > randomgenerator){
          console.log("AGAIN");
          break;
        }
          console.log("AGAIN");
          totalvalue = (parseInt(totalvalue) + parseInt(procentkans[amount]));
          amount = (amount+1);
      }
      console.log(amount);
      const selectedItem = amount;
      console.log(randomgenerator);
      console.log(selectedItem);
      this.props.onChange(selectedItem);
      if (this.props.onSelectItem) {
        this.props.onSelectItem(selectedItem);
      }
      this.setState({ selectedItem });
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
    }
  } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
  } finally {
    
      
  }
    
  }
  wheelVarsFunction(items, selectedItem, notCentered, CalculateCentered2) {
    let borderItem
    if (items.length <= 2){
      borderItem = 0.2;
    } else if (items.length == 3){
      borderItem = 1.5;
    } else if (items.length <= 4){
      borderItem = 2.0;
    } else if (items.length == 5){
      borderItem = 2.2;
    } else if (items.length == 6){
      borderItem = 2.4;
    } else if (items.length >= 7){
      borderItem = 2.5;
    } else {
      borderItem = 2;
    }
    return {
        "--nb-item": items.length,
        "--selected-item": selectedItem,
        "--notcentered-item": `${notCentered}deg`,
        "--minus-item": `${CalculateCentered2}deg`,
        "--border-item": borderItem,
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;
    // console.log(items);
    // console.log(items.length);
    // console.log(selectedItem);
    const minimalCentered = 1;
    const CalculateCentered = 360/items.length;
    const CalculateCentered2 = 360/items.length/2;
    // const CalculateCentered = 360/items.length-Calculate1Centered;
    const notCentered = Math.floor(Math.random() * (CalculateCentered - minimalCentered)) + minimalCentered;
    
    const itemlength = items.length;
    // console.log(CalculateCentered);
    // console.log(notCentered);
    const wheelVars = this.wheelVarsFunction(items, selectedItem, notCentered, CalculateCentered2)
    // const wheelVars = {
    //   "--nb-item": items.length,
    //   "--selected-item": selectedItem,
    //   "--notcentered-item": `${notCentered}deg`,
    //   "--minus-item": `${CalculateCentered2}deg`,
    //   // "--border-item": borderItems,
    // };
    const spinning = selectedItem !== null ? "spinning" : "";
    let spinDuration = localStorage.getItem("duration");

    let cssProperties = {};

    cssProperties["--spinning-duration"] = `${spinDuration}s`;
    cssProperties["--wheel-color"] = `${this.props.wheelColor}`;
    cssProperties["--neutral-color"] = `${this.props.fontColor}`;

    if (cssProperties["--wheel-color"] === "null")
      cssProperties["--wheel-color"] = "#d38c12";

    if (cssProperties["--neutral-color"] === "null")
      cssProperties["--neutral-color"] = "#FFFFFF";

    return (
      <div style={cssProperties} ref={this.props.forwardRef}>
        <h1 className="text-center">Spin for Food 🍟🥞</h1>
        <div className="wheel-container">
          <div
            // lg={true}
            className={`wheel ${spinning}`}
            style={wheelVars}
            onClick={this.selectItem}
          >
            {items.map((item, index) => (
              <div
                className="wheel-item"
                key={index}
                style={{ "--item-nb": index }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

      </div>
    );
  }
}

// Define prop types
Wheel.propTypes = { items: PropTypes.array.isRequired };


const WheelWithRef = React.forwardRef((props, ref) => (
  <Wheel {...props} ref={ref} />
));

export default WheelWithRef;
// Define default prop values
// Wheel.defaultProps = {
//   items: [
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "5",
//     "10",
//     "5",
//     "0",
//     "0",
//     "0",
//     "0",
//     "0",
//     "20",
//   ],
// };
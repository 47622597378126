import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./NavMenu.css"; // Import your CSS file for styling

const NavMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={`menunav ${menuOpen ? "menu-open" : ""}`}>
            <div className="menu-toggle" onClick={handleToggleMenu}>
                ☰
            </div>
            <Nav.Link className="hoi" href="#patat">Patat</Nav.Link>
            <Nav.Link className="hoi" href="#raspatat">Raspatat</Nav.Link>
            <Nav.Link className="hoi" href="#snacks">Snacks</Nav.Link>
            <Nav.Link className="hoi" href="#halalsnacks">Halalsnacks</Nav.Link>
            <Nav.Link className="hoi" href="#burger">Burgers</Nav.Link>
            <Nav.Link className="hoi" href="#broodjes">Broodjes</Nav.Link>
            <Nav.Link className="hoi" href="#extras">Saus / Extra's</Nav.Link>
            <Nav.Link className="hoi" href="#milkshake">Milkshake / ijs</Nav.Link>
            <Nav.Link className="hoi" href="#dranken">Dranken</Nav.Link>
        </div>
    );
};

export default NavMenu;

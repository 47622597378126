import React, { useEffect, useState } from "react";
import { CgWebsite } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";
import "./appheader.css";
import { socketConnectionPromise } from "../../socket";


const AppHeader = () => {
    const { user, setUser } = useAuthContext();
    const [menuOpen, setMenuOpen] = useState(false);
    const [noMenu, setNoMenu] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        removeToken();
        setUser(undefined);
        navigate("/", { replace: true });
        setMenuOpen(false);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuOpen(false);

    };

    useEffect(() => {
        console.log(window.location.pathname);
        if(window.location.pathname === '/russianroulette'){
            setNoMenu(true);
        }
      }, []);

    return (
        <>
        {noMenu === false ? (
<nav className={`header_space ${menuOpen ? "menu-open" : ""}`}>
            <div><img src="./pizzaenfriet.png" height="50px" className="menupicture"/>
            </div>
            <div className={`auth_buttons ${menuOpen ? "show" : ""}`}>
            
                {user ? (
                    <>
                       
                        <button className="button" onClick={() => handleNavigation("/socialcards")}>
                            Gebruikers
                        </button>
                        <button className="button" onClick={() => handleNavigation("/stats")}>
                            Statistieken
                        </button>
                        <button className="button" onClick={() => handleNavigation("/spinapp")}>
                            Spinner
                        </button>
                        <button className="button" onClick={() => handleNavigation("/profile")}>
                            {user.username}
                        </button>
                        <button className="button" onClick={() => handleNavigation("/bestellingen")}>
                            Bestelling
                        </button>
                        {/* <button className="button" onClick={() => handleNavigation("/bestellingeninzicht")}>
                            Bestellinginzicht
                        </button> */}
                        <button className="button" onClick={() => handleNavigation("/bestellingeninzicht")}>
                            Bestellinginzicht
                        </button>
                        <button className="button" onClick={() => handleNavigation("/producten")}>
                            Menu
                        </button>
                        
                        <button className="button" onClick={() => handleNavigation("/betalingen")}>Betalingen</button>
                        <button className="button" onClick={handleLogout}>Log uit
                        </button>
                    </>
                ) : (
                        <>
                            <button className="button" onClick={() => handleNavigation("/")}>
                                Log in
                            </button>
                            <button className="button" onClick={() => handleNavigation("/signup")}>
                                Registreren
                            </button>
                    </>
                )}
            </div>
            <div className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                ☰
            </div>
        </nav>
        ) : (
<div></div>
        )
        }
        </>
    );
};

export default AppHeader;



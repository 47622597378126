import React, { useEffect, useState } from "react";
import { API } from "../../constants";
import { getToken } from "../../helpers";
import { message } from "antd";
import "./bestellinginzichtnieuw.css";
import Search from "antd/es/input/Search";
import CustomPagination from "./Pagination";
import { useAuthContext } from "../../context/AuthContext";

const BestellingenInzichtNieuw = () => {
    const [bestellingInzicht, setBestellingInzicht] = useState([]);
    const { user, setUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [details, setDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [showProductDetails, setShowProductDetails] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [inzichtItems, setInzichtItems] = useState([]);
    const [filteredInzichtItems, setFilteredInzichtItems] = useState(inzichtItems); // Initialize with inzichtItems
    const [currentPage, setCurrentPage] = useState(1);
    const [productUsers, setProductUsers] = useState({});
    const [profile, setProfiles] = useState([]);

    const fetchProfile = async () => {
        setIsLoading(true);
        try {
            if (user) { // Check if user is defined
                const response = await fetch(`${API}/users/${user.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getToken()}`,
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setProfiles(data ?? []);
                // console.log("Fetched Profile:", user);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);

        }
    };

    useEffect(() => {
        getToken();
        // if (user) { // Check if user is defined
        fetchProfile();

        // }

    }, [user]);


    useEffect(() => {
        // Initialize filteredInzichtItems with inzichtItems when the component mounts
        setFilteredInzichtItems(inzichtItems);
    }, [inzichtItems]); // Run this effect whenever inzichtItems changes

    const fetchBestellingInzicht = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/order-lists/all`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                }
            });
            const data = await response.json();

            // Sort the data in descending order based on the 'id' property
            const sortedData = data.sort((a, b) => b.id - a.id);
            setBestellingInzicht(sortedData ?? []);
            setInzichtItems(sortedData ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };



    const detailsBestelling = async (id, productId) => {
        try {
            const response = await fetch(`${API}/order-lists/${id}?populate=*`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                }
            });
            const data = await response.json();

            const orderDetails = data.data.attributes;
            setDetails(orderDetails);

            // Fetch and store usernames for each product in the order
            const productUserPromises = orderDetails.orders.data.map(async (order) => {
                const productResponse = await fetch(`${API}/orders/${order.id}?populate=*`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${getToken()}`,
                    }
                });
                const productData = await productResponse.json();
                return productData.data.attributes.users.data.attributes.username;
            });

            // Wait for all promises to resolve and store usernames in productUsers state
            const usernames = await Promise.all(productUserPromises);
            const productUserMap = orderDetails.orders.data.reduce((acc, order, index) => {
                acc[order.id] = usernames[index];
                return acc;
            }, {});
            setProductUsers(productUserMap);
            // console.log(productUserMap)
            setUserDetails(orderDetails);
            setShowProductDetails(false); // Hide product details when a new order is clicked
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        }
    };


    const detailsProduct = async (productId) => {
        try {
            const response = await fetch(`${API}/orders/${productId}?populate=*`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                }
            });
            const data = await response.json();
            setProductDetails(data.data.attributes);

            // setProductUsers(prevUsers => ({
            //     ...prevUsers,
            //     [productId]: data.data.attributes.users.data.attributes.username
            // }));
            // console.log(productUsers)
            setShowProductDetails(true); // Toon productdetails
        } catch (error) {
            console.error(error);
            message.error("Error while fetching product details!");
        }
    };

    useEffect(() => {
        fetchBestellingInzicht();
    }, []);


    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    const terugorders = () => {
        setDetails(null)
    }

    const terugordersproduct = () => {
        setProductDetails(null)
        setDetails(null)
    }

    const itemsPerPage = 10;
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;
    // const paginatedOrders = bestellingInzicht.slice(startIndex, endIndex);


    // const totalPages = Math.ceil(bestellingInzicht.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Perform actions based on the selected page, e.g., fetch data for the new page
    };

    const filterDate = (inzichtItems, search) => {
        // console.log("Search Term:", search);
        const filteredItems = inzichtItems.filter((inzicht) => {
            // Case-insensitive search for 'naam' property
            return inzicht.naam.toLowerCase().includes(search.toLowerCase());
        });
        // console.log("Filtered Items:", filteredItems);
        return filteredItems;
    };


    // const filteredInzichtItems = filterDate(inzichtItems, searchTerm);

    // const handleInputChange = (e) => {
    //     setSearchTerm(e.target.value);
    // };

    const handleInputChange = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        const filteredItems = filterDate(inzichtItems, searchValue);
        setFilteredInzichtItems(filteredItems);
        setCurrentPage(1); // Reset current page when search term changes
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedOrders = filteredInzichtItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredInzichtItems.length / itemsPerPage);

    // const groupedProducts = {};

    // if (details && details.orders && details.orders.data) {
    //     console.log("hoi", details.orders.data)
    //     details.orders.data.forEach((order) => {
    //         const username = productUsers[order.id];
    //         console.log(order.attributes.guestname)
    //         if (!groupedProducts[username]) {
    //             groupedProducts[username] = [];
    //         }
    //         groupedProducts[username].push(order);
    //     });
    // }

    // console.log(groupedProducts)


    const groupedProducts = {};

if (details && details.orders && details.orders.data) {
    console.log("hoi", details.orders.data);
    details.orders.data.forEach((order) => {
        const guestname = order.attributes.guestname; // Update here to use guestname
        console.log(guestname);
        if (!groupedProducts[guestname]) {
            groupedProducts[guestname] = [];
        }
        groupedProducts[guestname].push(order);
    });
}

console.log(groupedProducts);





    // {details.orders.data.map((order, index) => (
    //     // <div className="inzicht-order-details" key={index}>


    //     <div className="inzicht-product-name" key={index} onClick={() => detailsProduct(order.id)}>
    //         <p className="inzicht-product-name-p">{order.attributes.quantity} </p>
    //         <p className="inzicht-product-name-p">{order.attributes.productnaam}</p>
    //         <p className="inzicht-product-name-p">€{(order.attributes.totaalprijs * order.attributes.quantity).toFixed(2)}</p>
    //         <p className="inzicht-product-name-p"> Klik voor details!</p>

    //         {/* Access user information from productUsers */}
    //         {productUsers[order.id] && (
    //             <p className="inzicht-product-name-p">Persoon die het product besteld heeft: {productUsers[order.id]}</p>
    //         )}
    //     </div>


    //     // </div>
    // ))}

    // const copyOrder = async () => {
    //     if (profile.meeBestellen === true) {

    //         if (groupedProducts[user.username]) {
    //             console.log(groupedProducts[user.username])
    //         const products = groupedProducts[user.username];
    //         try {
    //             // Create an array to store the promises for each POST request
    //             const postRequests = products.map(async (product) => {
    //                 try {
    //                     const data = {
    //                         'data': {
    //                             productnaam: product.attributes.productnaam,
    //                             productprijs: product.attributes.productprijs,
    //                             lijst: product.attributes.lijst,
    //                             totaalprijs: product.attributes.totaalprijs,
    //                             users: {
    //                                 id: user.id
    //                             },
    //                             huidigeOrder: true,
    //                             guestname: user.username, 
    //                         }
    //                     };

    //                     const response = await fetch(`${API}/orders`, {
    //                         method: "POST",
    //                         headers: {
    //                             "Content-Type": "application/json",
    //                             'Authorization': `Bearer ${getToken()}`,
    //                         },
    //                         body: JSON.stringify(data),
    //                     });

    //                     const responseData = await response.json();

    //                     if (response.ok) {
    //                         message.success("Order list updated successfully!");
    //                     } else {
    //                         console.error("Error updating order list:", responseData);
    //                         message.error("Error While Updating the Order List!");
    //                     }
    //                 } catch (error) {
    //                     console.error(error);
    //                     message.error("Network Error While Updating the Order List!");
    //                 }
    //             });

    //             // const createdOrders = await Promise.all(postRequests);
    //             // console.log('Successfully copied orders:', createdOrders);
    //             // Perform any further actions after orders are copied, if needed
    //         } catch (error) {
    //             console.error(error);
    //             message.error('Error while copying orders!');
    //         }
    //     } else {
    //         console.log('No orders found for the current user');
    //     }
    //     } else {
    //         message.error('Je bestelt niet mee')
    //     }

    // };

    const copyOrder = async () => {
        if (profile.meeBestellen === true) {
            if (groupedProducts[user.username]) {
                console.log(groupedProducts[user.username]);

                const products = groupedProducts[user.username];

                // Filter products where user.username === guestname
                const filteredProducts = products.filter((product) => product.attributes.guestname === user.username);

                if (filteredProducts.length === 0) {
                    console.log('No matching products found for the current user');
                    return;
                }

                try {
                // Create an array to store the promises for each POST request
                    const postRequests = filteredProducts.map(async (product) => {
                        try {
                            const data = {
                                'data': {
                                    productnaam: product.attributes.productnaam,
                                    productprijs: product.attributes.productprijs,
                                    lijst: product.attributes.lijst,
                                    totaalprijs: product.attributes.totaalprijs,
                                    users: {
                                        id: user.id
                                    },
                                    huidigeOrder: true,
                                    guestname: user.username,
                                }
                            };

                            const response = await fetch(`${API}/orders`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    'Authorization': `Bearer ${getToken()}`,
                                },
                                body: JSON.stringify(data),
                            });

                            const responseData = await response.json();

                            if (response.ok) {
                                message.success("Order list updated successfully!");
                            } else {
                                console.error("Error updating order list:", responseData);
                                message.error("Error While Updating the Order List!");
                            }
                        } catch (error) {
                            console.error(error);
                            message.error("Network Error While Updating the Order List!");
                        }
                    });

                    // const createdOrders = await Promise.all(postRequests);
                    // console.log('Successfully copied orders:', createdOrders);
                    // Perform any further actions after orders are copied, if needed
                } catch (error) {
                    console.error(error);
                    message.error('Error while copying orders!');
                }
            } else {
                console.log('No orders found for the current user');
            }
        } else {
            message.error('Je bestelt niet mee');
        }
    };




    return (
        <div className="inzicht-bestellinginzicht-container">
            <div className="main-container">
                <div className="inzicht-top">
                    <h2 className="inzicht-title">Bestellingen Inzicht</h2>
                    {details === null && <input type="text" className="zoekfilter" placeholder="Zoek op naam/datum" onChange={handleInputChange} value={searchTerm} />
                    }
                </div>
                <div className="alles">
                    {details === null && (
                        <ul className="inzicht-order-list">
                            {(paginatedOrders ?? []).map((inzicht) => (
                                <li className="inzicht-order-item" key={inzicht.id} onClick={() => detailsBestelling(inzicht.id)}>
                                    {inzicht.naam}
                                </li>
                            ))}
                        </ul>
                    )}
                    {details === null && <CustomPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />}
                </div>

                {details && !showProductDetails && (

                    <div className="inzicht-details">
                        <button className="terug" onClick={terugorders}>Terug alle bestellingen</button>
                        <div className="producten-in-order">
                        <h2>Inzicht {details.naam}</h2>
                        {/* <p className="order-date">Order Date: {details.orderDate}</p> */}
                        {userDetails?.users.data.map((user, index) => (
                            <div className="inzicht-user-details" key={index}>
                                <h5>De besteller is: {user.attributes.username}</h5>

                            </div>

                        ))}

                            {Object.keys(groupedProducts).map((username) => (
                                <div className="grouped-products" key={username}>
                                    <h6 className="grouped-products-h6">{username}</h6>
                                    <div className="group-container">
                                        {groupedProducts[username].map((order, index) => (
                                            <div className="inzicht-product-name-container" key={index} onClick={() => detailsProduct(order.id)}>
                                                <p className="inzicht-product-name-p">Hoeveelheid: {order.attributes.quantity} </p>
                                                <p className="inzicht-product-name-p">{order.attributes.productnaam}</p>
                                                <p className="inzicht-product-name-p">€{(order.attributes.totaalprijs * order.attributes.quantity).toFixed(2)}</p>
                                                <p className="inzicht-product-name-p"> Klik voor details!</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}



                        <div className={`content-container ${isContentVisible ? 'expanded' : ''}`}>
                            <h6>Script</h6>
                            <p id="inzicht-script-content">{details.script}</p>
                        </div><br></br>
                        <button id="inzicht-read-more-btn" onClick={toggleContentVisibility}>
                            {isContentVisible ? 'Invouwen' : 'Volledig script'}
                            </button>
                        </div><br></br><br></br>
                        <p className="totaalprijs-line"></p><br></br>
                        <h4>totaalprijs bestelling: €{parseFloat(details.totaalprijs).toFixed(2)}</h4>
                        <button onClick={copyOrder} >Kopieer</button>
                    </div>
                )}

                {showProductDetails && productDetails ? (
                    <div className="inzicht-product-details">
                        <button className="terug-besteldetails-btn" onClick={() => setShowProductDetails(false)}>Terug naar besteldetails</button>
                        <div className="producten-informatie">
                        <h3> {productDetails.quantity} x {productDetails.productnaam} </h3>
                            <p className="producten-informatie-p" naam={productDetails.users.data.attributes.username}>Persoon die het product besteld heeft: {productDetails.users.data.attributes.username}</p>
                            <p className="producten-informatie-p">Product Naam: {productDetails.productnaam}</p>
                            <p className="producten-informatie-p">Toevoeging: <br />
                                {productDetails.lijst.split('\n').map((item, index) => (
                                    <p className="toevoeging" key={index}>{item.trim()}</p>
                                ))} </p>



                            <p className="product-totaalprijs-line"></p>
                            <p className="producten-informatie-p" quantity={productDetails.quantity}>Hoeveelheid: {productDetails.quantity}</p>
                            <p className="producten-informatie-p" productprijs={productDetails.productprijs}>Product Prijs: {productDetails.productprijs}</p>
                            <p className="producten-informatie-p">Prijs + Extra's: €{parseFloat(productDetails.totaalprijs).toFixed(2)}</p>
                            <p className="producten-informatie-p">Totaalprijs: €{parseFloat(productDetails.totaalprijs * productDetails.quantity).toFixed(2)}</p>
                        </div>
                        <button className="terug" onClick={terugordersproduct}>Terug alle bestellingen</button>

                    </div>
                ) : null}

            </div>

        </div>
    );
};

export default BestellingenInzichtNieuw;

// import React, { useEffect, useRef, useState } from "react";

// const CanvasStickman = ({ sectionColors, numberOfSections, sectionNames, rotation, groottePercentage, ...props }) => {
//     const canvasStickmanRef = useRef();

//     useEffect(() => {
//         var canvas = canvasStickmanRef.current;
// const context = canvas.getContext("2d");

// const centerX = canvas.width / 2;
//     const centerY = canvas.height / 2;

// context.beginPath();
// context.fillStyle = "bisque";
// context.arc(200, 50, 30, 0, Math.PI * 2, true); 
// context.fill();

// context.beginPath();
// context.strokeStyle = "red"; 
// context.lineWidth = 3;
// context.arc(200, 50, 20, 0, Math.PI, false); 
// context.stroke();

// context.beginPath();
// context.fillStyle = "green"; 
// context.arc(190, 45, 3, 0, Math.PI * 2, true); 
// context.fill();
// context.arc(210, 45, 3, 0, Math.PI * 2, true); 
// context.fill();

// context.beginPath();
// context.moveTo(200, 80);
// context.lineTo(200, 180);
// context.strokeStyle = "navy";
// context.stroke();

// context.beginPath();
// context.strokeStyle = "#0000ff";
// context.moveTo(200, 80);
// context.lineTo(150, 130);
// context.moveTo(200, 80);
// context.lineTo(250, 130);
// context.stroke();

// context.beginPath();
// context.strokeStyle = "orange";
// context.moveTo(200, 180);
// context.lineTo(150, 280);
// context.moveTo(200, 180);
// context.lineTo(250, 280);
// context.stroke();

//     }, [])

//     return <canvas ref={canvasStickmanRef} width={300} height={300} {...props} />;
// };

// export default CanvasStickman;


import React, { useEffect, useRef } from "react";
import revolver from '../../revolver.png';

const CanvasStickman = (props) => {
  const canvasStickmanRef = useRef();

  useEffect(() => {
    var canvas = canvasStickmanRef.current;
    const context = canvas.getContext("2d");

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    context.beginPath();
    context.fillStyle = "bisque";
    context.arc(centerX, centerY - 250, 150, 0, Math.PI * 2, true);
    context.fill();

    context.beginPath();
    context.strokeStyle = "red";
    context.lineWidth = 3;
    context.arc(centerX, centerY - 250, 120, 0, Math.PI, false);
    context.stroke();

    context.beginPath();
    context.fillStyle = "green";
    context.arc(centerX - 50, centerY - 285, 10, 0, Math.PI * 2, true);
    context.fill();
    context.arc(centerX + 50, centerY - 285, 10, 0, Math.PI * 2, true);
    context.fill();

    context.beginPath();
    context.lineWidth = 20;
    context.moveTo(centerX, centerY - 100);
    context.lineTo(centerX, centerY + 150);
    context.strokeStyle = "navy";
    context.stroke();

    context.beginPath();
    context.strokeStyle = "#0000ff";
    context.lineWidth = 20;
    context.moveTo(centerX, centerY - 90);
    context.lineTo(centerX - 250, centerY - 70);
    context.moveTo(centerX, centerY - 90);
    context.lineTo(centerX + 160, centerY + 90);
    context.stroke();

    context.beginPath();
    context.strokeStyle = "orange";
    context.moveTo(centerX, centerY + 150);
    context.lineTo(centerX - 130, centerY + 360);
    context.moveTo(centerX, centerY + 150);
    context.lineTo(centerX + 130, centerY + 360);
    context.stroke();

    const image = new Image();
    image.src = revolver;

    image.onload = () => {
        context.rotate(100);
      context.drawImage(image, centerX - 620, centerY - 140, 500, 250);
      context.rotate(-100);
    };
  }, []);

  return <canvas ref={canvasStickmanRef} width={400} height={400} {...props} />;
};

export default CanvasStickman;

import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { getToken } from "./helpers";
import { SOCKET_URL } from "./constants";

const SERVER_URL = SOCKET_URL;


function useSocketConnection() {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const JWT_TOKEN = getToken();
        const socketConnection = io(SERVER_URL, {
            auth: {
                token: JWT_TOKEN,
            },
        });

        setSocket(socketConnection)

        socketConnection.on("connect", () => {
            const socketId = socketConnection.id;
            localStorage.setItem("socketId", socketId);
        });

        // Clean up socket connection on component unmount
        return () => {
            if (socketConnection) {
                socketConnection.disconnect();
            }
        };
    }, []); // Empty dependency array ensures this effect runs once after the initial render

    return socket;
}

export default useSocketConnection;

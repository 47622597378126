import React, { useEffect, useState } from "react";
import Board from "./board";
import TopBoard from "./topboard";
import "./stats.css"

const Stats = () => {

    return (
        <div className="stats">
            <TopBoard />
            <Board />
        </div>
    )
}

export default Stats;
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constants";
import { setToken } from "../../helpers";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Spin,
    Typography,
} from "antd";

const ResetPassword = () => {
    const { isDesktopView } = useScreenSize();
    const navigate = useNavigate();
    const { setUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const onFinish = async () => {
        setIsLoading(true);
        try {
            // Validate password and confirmation
            if (password !== confirmPassword) {
                throw new Error("Passwords do not match.");
            }

            const response = await fetch(`${API}/auth/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code: new URLSearchParams(window.location.search).get("code"),
                    password: password,
                    passwordConfirmation: confirmPassword, // Include passwordConfirmation in the request body
                }),
            });

            const data = await response.json();
            if (data?.error) {
                throw data?.error;
            } else {
                // set the token
                setToken(data.jwt);

                // set the user
                setUser(data.user);

                message.success(`Password reset successfully!`);

                navigate("/profile", { replace: true });
            }
        } catch (error) {
            console.error(error);
            setError(error?.message ?? "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Fragment>
            <Row align="middle">
                <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
                    <Card title="Reset Password">
                        {error ? (
                            <Alert
                                className="alert_error"
                                message={error}
                                type="error"
                                closable
                                afterClose={() => setError("")}
                            />
                        ) : null}
                        <Form
                            name="resetPassword"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[{ required: true, message: "Please enter your new password." }]}
                            >
                                <Input.Password
                                    placeholder="New Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your new password.",
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login_submit_btn"
                                >
                                    Submit {isLoading && <Spin size="small" />}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Typography.Paragraph className="form_help_text">
                            Terug inloggen <Link to="/">Sign In</Link>
                        </Typography.Paragraph>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ResetPassword;

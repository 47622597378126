import useSocketConnection from "./socket";
import { useAuthContext } from "./context/AuthContext";
import React, { useEffect, useState, useRef } from "react";
import Canvas from "./components/Canvas/canvas.js";
import ItemForm from "./components/Tab/ItemForm/ItemForm";
import WheelWithRef from "./components/Wheel";
import EerdereOrders from "./components/Tab/Result";
import { Button, Modal, Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import axios from "axios";
import { getToken } from "./helpers";
import { API, AUTH_TOKEN } from "./constants";
import { Modal as antdModal, message, Switch } from "antd";
import { BEARER } from "./constants";
import CanvasBar from "./components/Canvas/CanvasBar.js";
import ColorPicker from "./components/Customize/ColorPicker.js";
import useProfileStats from "./components/Level/levels.js";
import GunCanvas from "./components/canvasGun/GunCanvas.js";
import pistol from './pistol.png';
import CanvasStickman from "./components/Canvas/CanvasStickman.js";
import revolver from './revolver.png';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);


function App() {
  const wheelRef = useRef(null);
  const [spinning, setSpinning] = useState(false);
  const [state, updateState] = useState(localStorage.getItem("wheelColor"));
  const [wheelColors, setWheelColors] = useState([]);
  const [state2, updateState2] = useState(localStorage.getItem("fontColor"));
  const [winners, setWinners] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [player, setPlayer] = useState();
  const [winnersData, setWinnersData] = useState([]);
  const [winnersDataBestellingen, setWinnersDataBestellingen] = useState([]);
  const [winnerName, setWinnerName] = useState("");
  // const [toggle, setToggle] = useState(false);
  const [switchChange, setSwitchChange] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const { user, setUser } = useAuthContext();
  const [counter, setCounter] = useState(60);
  const [updatedProducts, setUpdatedProducts] = useState(false);
  const [bestelling, setBestelling] = useState([]);
    const [options, setOptions] = useState([]);
    const [dataWheelID, setdataWheelID] = useState([]);
  const socket = useSocketConnection();
  const [kansPercentagePersoon, setKanspercentagepersoon] = useState([]); 
  const [rotation, setRotation] = useState(0);
  const [hoveredName, setHoveredName] = useState(null);
  const [poll, setPoll] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [emptyUsers, setEmptyUsers] = useState(false);
  const [booleanOn, setBooleanOn] = useState(false); 
  const [users, setUsers] = useState([]);

    // const [updatingDatabase, setUpdatingDatabase] = useState(false);
  // const toggler = () => {
  //   toggle ? setToggle(false): setToggle(true);
  // }
  const numberOfSections = 3; 

  if (window.localStorage.getItem("duration") === null)
    localStorage.setItem("duration", 10);

  if (window.localStorage.getItem("wheelColor") === null)
    localStorage.setItem("wheelColor", "#d38c12");

  if (window.localStorage.getItem("fontColor") === null)
    localStorage.setItem("fontColor", "#FFFFFF");

  const [duration, setDuration] = useState(
    window.localStorage.getItem("duration")
  );

  const [wheelColor, setWheelColor] = useState(
    window.localStorage.getItem("wheelColor")
  );

  const [fontColor, setFontColor] = useState(
    window.localStorage.getItem("fontColor")
  );
  
  // const [names, setNames] = useState(() => {
  //   var myItem = localStorage.getItem(AUTH_TOKEN);
    
  //   localStorage.clear();
  //   localStorage.setItem(AUTH_TOKEN, myItem)
  //  const valueNames = window.localStorage.getItem("namesList");
  //   console.log(valueNames);
  //   return valueNames !== null
  //     ? valueNames
  //     : [
  //       "No Names",
  //       "No Names",
  //       ];
        
  // });
  const [names, setNames] = useState([]);
  // const {dataOrders, setDataOrders} = useState(() => {
  //   async function getthatdata() {

  //   }
  // })

  const [items, setItems] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["itemsList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [times, setTimes] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["timesList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [namesMeedoen, setNamesMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const valueNames = window.localStorage.getItem("namesList");
    return valueNames !== null
      ? valueNames
      : [
        "No Names",
        "No Names",
        ];
  });

  const [namesNietMeedoenOpWheel, setNamesNietMeedoenOpWheel] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const valueNames = window.localStorage.getItem("namesListNietMeedoenOpWheel");
    return valueNames !== null
      ? valueNames
      : [
        "No Names",
        "No Names",
        ];
  });

  const [itemsMeedoen, setItemsMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["itemsList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  const [timesMeedoen, setTimesMeedoen] = useState(() => {
    var myItem = localStorage.getItem(AUTH_TOKEN);
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN, myItem)
    const value = window.localStorage.getItem(["timesList"]);
    return value !== null
      ? value
      : [
        "No Names",
        "No Names",
        ];
  });

  // const [url, setUrl] = useState(() => {
  //   const value = window.localStorage.getItem("urlYoutube");
  //   return value !== null ? `${value}` : null;
  // });

  // const updateKans = (groupID, data) => {
  //   axios.put(`http://localhost:1337/api/users/${groupID}`, { data })
  //     .catch(error => console.error('Error updating users: ', error));
//   const fetchHeleBestelling = async () => {
//     try {
//         const ordersResponse = await fetch(`${API}/products`, {
//           method: 'GET',
//           headers: {
//             'Authorization': `Bearer ${getToken()}`,
//           }
//         });
//         const datahele = await ordersResponse.json();
//         setBestelling(datahele.data);
//         if (!ordersResponse.ok) {
//           console.error('Error fetching products:', ordersResponse);
//           message.error('Error fetching products!');
//         }
// } catch {

// }
// }

const fetchUsersColors = async () => {
  try {
    // const responsemeedoen = await fetch(`${API}/users`, {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `Bearer ${getToken()}`,
    //   }
    // });

    // if (responsemeedoen.ok) {
      // const datameedoen = await responsemeedoen.json();
      // const names = datameedoen.map(user => user.username);
      // const items = datameedoen.map(user => user.id);
      // const times = datameedoen.map(user => user.laatsteBestelTijd);

      // setNamesMeedoen(names);
      // setItemsMeedoen(items);
      // setTimesMeedoen(times);

      // localStorage.setItem("namesListMeedoen", JSON.stringify(names));
      // localStorage.setItem("itemsListMeedoen", JSON.stringify(items));
      // localStorage.setItem("timesListMeedoen", JSON.stringify(times));
      // const dataColors = await responsemeedoen.json();
      const dataColors = users;
      const colorsMap = {};
      dataColors.forEach(user => {
        colorsMap[user.username] = user.wheelColor;
      });
      setWheelColors(colorsMap);
      console.log(wheelColors);
      // console.log(wheelColors);
      // console.log(dataColors);
    // }
  } catch (error) {
    console.log("Error fetching meedoen data:", error);
  }
}

  const updateBestellers = async () => {
    const bestellerNamesProduct = await JSON.parse(window.localStorage.getItem("itemsListMeedoen"));
    // console.log(bestellerNamesProduct);
    // console.log(localStorage.getItem("itemsList"));
    const bestellerNamesLenght = await bestellerNamesProduct.length;
    // console.log(bestellerNamesLenght);
    for (let i = 0; i < bestellerNamesLenght; i++) {
      try {
        const responseGET = await fetch(`${API}/users/${bestellerNamesProduct[i]}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        });

        const responseGETData = await responseGET.json();
//  console.log("ghsifughdifughdf", responseGETData);
      
 const data =
 responseGETData.meedoenOpWheel === false && responseGETData.laatsteBestelling === false
   ? {
       meeBestellen: true,
       klaarBestellen: false,
       meedoen: false,
       meedoenOpWheel: false,
     }
   : {
       meeBestellen: true,
       klaarBestellen: false,
       meedoen: false,
       meedoenOpWheel: false,
       kans: responseGETData.kans + 1,
       xp: responseGETData.xp + 50,
     };

      
      // console.log(data);
      const response = await fetch(`${API}/users/${bestellerNamesProduct[i]}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        message.success("bestellers updated successfully!");
      } else {
        console.error("Error updating bestellers:", responseData);
        message.error("Error While Updating the bestellers!");
      }
      
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating the bestellers!");
    }
    }
  }

  const updateKans = async (IDUser, data) => {
    try {
      const response = await fetch(`${API}/users/${IDUser}`, { // Add await keyword here
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json(); 
  
      if (response.ok) {
        // setUser(responseData);
        message.success("Data saved successfully!");
      } else {
        console.error("Error updating user:", responseData);
        message.error("Error While Updating the Profile!");
      }
    } catch (error) {
      console.error(error);
      message.error("Network Error While Updating the Profile!");
    } finally {
      // setLoading(false);
    }
  };

  const [controls, setControls] = useState(true);

  const [loop, setLoop] = useState(true);

  // function load(url) {
  //   setUrl(url);
  // }

  function handleToggleLoop() {
    setLoop(!loop);
  }

  function ref(player) {
    setPlayer(player);
  }

  function changeWheelAndFontColor(color) {
    setWheelColor(color.wheelColor);
    setFontColor(color.fontColor);
  }

  function cancelModal() {
    setOpenModal(false);
  }

  const pistolShot = async (e, index) => {
    console.log(e.target.value, names[index]);
    const procentkansString = localStorage.getItem("procentkans");
    const procentkans = JSON.parse(procentkansString);
    const personShotKans = procentkans[index];
    const personShot = names[index];
    const randomgenerator = Math.floor(Math.random() * 100);
    if(randomgenerator <= personShotKans) {
      console.log(personShotKans, randomgenerator, "works");
      POSTpistolShot(user?.username, personShot, true);
    } else {
      console.log(personShotKans, randomgenerator, "NO");
      POSTpistolShot(user?.username, personShot, false);
    }
  }

  async function POSTpistolShot(shooterName, victimName, pistolShot) {
    const dataPistol = {
      'data': {
        shooterName: shooterName,
        victimName: victimName,
        pistolShot: pistolShot,
      }
    };
    try{
      const response = await fetch(`${API}/roulettes`, {
        method: 'POST',
        headers:{
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(dataPistol),
      });
    } catch(error) {
      console.log("an error occured while posting to Roulette", error);
    }
    
  }

  function processData(dataOrder) {
    if (dataOrder.length === 0) {
      setEmptyUsers(true);
    }
    const usersMeedoenOpWheel = users.filter(user => user.meedoenOpWheel === true);
    const names = usersMeedoenOpWheel.map(user => user.username);
    const items = usersMeedoenOpWheel.map(user => user.id);
    const times = usersMeedoenOpWheel.map(user => user.laatsteBestelTijd);
    const kansen = usersMeedoenOpWheel.map(user => user.kans);
    const bestellingen = usersMeedoenOpWheel.map(user => user.bestellingen);
    const kanspercentagepersoon = kansen.map((kans, index) => {
      return 100 - ((bestellingen[index] / kans) * 100);
    });
    const totalKanspercentagePersoon = kanspercentagepersoon.reduce((acc, percentage) => acc + percentage, 0);
    const multiplier = 100 / totalKanspercentagePersoon;
    const berekendkanspercentagepersoon = kanspercentagepersoon.map((kans) => {
      return (kans * multiplier).toFixed(0);
    });
  
    localStorage.setItem("namesList", JSON.stringify(names));
    localStorage.setItem("itemsList", JSON.stringify(items));
    localStorage.setItem("timesList", JSON.stringify(times));
    localStorage.setItem("kansenList", JSON.stringify(kansen));
    localStorage.setItem("bestellingenList", JSON.stringify(bestellingen));
    localStorage.setItem("procentkans", JSON.stringify(berekendkanspercentagepersoon));
  
    setNames(names);
    setItems(items);
    setTimes(times);
    setKanspercentagepersoon(berekendkanspercentagepersoon);
  }
  
  function addData() {
    try {
      processData(users);
    } catch (error) {
      console.error("Error processing data:", error);
    } finally {
      const usersMeedoen = users.filter(user => user.meedoen === true);
      const namesMeedoen = usersMeedoen.map(user => user.username);
      const itemsMeedoen = usersMeedoen.map(user => user.id);
      const timesMeedoen = usersMeedoen.map(user => user.laatsteBestelTijd);
  
      setNamesMeedoen(namesMeedoen);
      setItemsMeedoen(itemsMeedoen);
      setTimesMeedoen(timesMeedoen);
  
      localStorage.setItem("namesListMeedoen", JSON.stringify(namesMeedoen));
      localStorage.setItem("itemsListMeedoen", JSON.stringify(itemsMeedoen));
      localStorage.setItem("timesListMeedoen", JSON.stringify(timesMeedoen));
  
      const usersNietMeedoenOpWheel = users.filter(user => user.meedoen === true && user.meedoenOpWheel === false);
      const namesNietMeedoenOpWheel = usersNietMeedoenOpWheel.map(user => user.username);
  
      setNamesNietMeedoenOpWheel(namesNietMeedoenOpWheel);
      localStorage.setItem("namesListNietMeedoenOpWheel", JSON.stringify(namesNietMeedoenOpWheel));
    }
  }
  

  // async function addData() {
  //   try {
  //     const response = await fetch(`${API}/users?filters[meedoenOpWheel]=true`, {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${getToken()}`,
  //       }
  //     });
  
  //     if (response.ok) {
  //       // console.log("OKRESPONSE");
  //       const dataOrder = await response.json();
  //       // console.log(dataOrder);
  //       if(dataOrder.length === 0){
  //         // console.log("TEST");
  //         setEmptyUsers(true);
  //       }
  //       const names = dataOrder.map(user => user.username);
  //       const items = dataOrder.map(user => user.id);
  //       const times = dataOrder.map(user => user.laatsteBestelTijd);
  //       const kansen = dataOrder.map(user => user.kans);
  //       const bestellingen = dataOrder.map(user => user.bestellingen);
  //       const kanspercentagepersoon = kansen.map((kans, index) => {
  //         return 100 - ((bestellingen[index] / kans) * 100);
  //       });
  //       const totalKanspercentagePersoon = kanspercentagepersoon.reduce((acc, percentage) => acc + percentage, 0);
  //       const multiplier = 100 / totalKanspercentagePersoon;
  //       const berekendkanspercentagepersoon = kanspercentagepersoon.map((kans) => {
  //         return (kans * multiplier).toFixed(0);
  //       });
  //       // console.log(names);
  //       localStorage.setItem("namesList", JSON.stringify(names));
  //       localStorage.setItem("itemsList", JSON.stringify(items));
  //       localStorage.setItem("timesList", JSON.stringify(times));
  //       localStorage.setItem("kansenList", JSON.stringify(kansen));
  //       localStorage.setItem("bestellingenList", JSON.stringify(bestellingen));
  //       localStorage.setItem("procentkans", JSON.stringify(berekendkanspercentagepersoon));
  //       // console.log(names);
  //       setNames(names);
  //       setItems(items);
  //       setTimes(times);
  //       setKanspercentagepersoon(berekendkanspercentagepersoon);
        
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     // console.log(names);
  //     try {
  //       const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true`, {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${getToken()}`,
  //         }
  //       });
  
  //       if (responsemeedoen.ok) {
  //         const datameedoen = await responsemeedoen.json();
  //         const names = datameedoen.map(user => user.username);
  //         const items = datameedoen.map(user => user.id);
  //         const times = datameedoen.map(user => user.laatsteBestelTijd);
  
  //         setNamesMeedoen(names);
  //         setItemsMeedoen(items);
  //         setTimesMeedoen(times);
  //         // console.log(items);
  //         localStorage.setItem("namesListMeedoen", JSON.stringify(names));
  //         localStorage.setItem("itemsListMeedoen", JSON.stringify(items));
  //         localStorage.setItem("timesListMeedoen", JSON.stringify(times));
  //       } else {
  //         console.error("WERKT NIET !");
  //       }
  //     } catch (error) {
  //       console.log("Error fetching meedoen data:", error);
  //     } finally {
  //       try {
  //         const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true&filters[meedoenOpWheel]=false`, {
  //           method: 'GET',
  //           headers: {
  //             'Authorization': `Bearer ${getToken()}`,
  //           }
  //         });
  
  //         if (responsemeedoen.ok) {
  //           const datameedoen = await responsemeedoen.json();
  //           const names = datameedoen.map(user => user.username);
  
  //           setNamesNietMeedoenOpWheel(names);
  //           localStorage.setItem("namesListNietMeedoenOpWheel", JSON.stringify(names));
  //         } else {
  //           console.error("WERKT NIET !!");
  //         }
  //       } catch (error) {
  //         console.log("Error fetching meedoen op wheel data:", error);
  //       }
  //     }
  //   }
  // }
  
  async function addData2(data) {
    try {
      const response = await fetch(`${API}/users?filters[meedoenOpWheel]=true`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getToken()}`,
        }
      });
  
      if (response.ok) {
        // console.log("OKRESPONSE");
        const dataOrder = await response.json();
        const names = dataOrder.map(user => user.username);
        const items = dataOrder.map(user => user.id);
        const times = dataOrder.map(user => user.laatsteBestelTijd);
        const kansen = dataOrder.map(user => user.kans);
        const bestellingen = dataOrder.map(user => user.bestellingen);
        const kanspercentagepersoon = kansen.map((kans, index) => {
          return 100 - ((bestellingen[index] / kans) * 100);
        });
        const totalKanspercentagePersoon = kanspercentagepersoon.reduce((acc, percentage) => acc + percentage, 0);
        const multiplier = 100 / totalKanspercentagePersoon;
        const berekendkanspercentagepersoon = kanspercentagepersoon.map((kans) => {
          return (kans * multiplier).toFixed(0);
        });
        // console.log(names, items);
        await selectResultEventHandler(data.data.attributes.IDinWinnersArray, items, names);
        localStorage.setItem("namesList", JSON.stringify(names));
        localStorage.setItem("itemsList", JSON.stringify(items));
        localStorage.setItem("timesList", JSON.stringify(times));
        localStorage.setItem("kansenList", JSON.stringify(kansen));
        localStorage.setItem("bestellingenList", JSON.stringify(bestellingen));
        localStorage.setItem("procentkans", JSON.stringify(berekendkanspercentagepersoon));
        setNames(names);
        setItems(items);
        setTimes(times);
        setKanspercentagepersoon(berekendkanspercentagepersoon);
        
      }
      if (!response.ok){
        // console.log("OHNEE");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // console.log(names, "huh");
      try {
        const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        });
  
        if (responsemeedoen.ok) {
          const datameedoen = await responsemeedoen.json();
          const names = datameedoen.map(user => user.username);
          const items = datameedoen.map(user => user.id);
          const times = datameedoen.map(user => user.laatsteBestelTijd);
  
          setNamesMeedoen(names);
          setItemsMeedoen(items);
          setTimesMeedoen(times);
  
          localStorage.setItem("namesListMeedoen", JSON.stringify(names));
          localStorage.setItem("itemsListMeedoen", JSON.stringify(items));
          localStorage.setItem("timesListMeedoen", JSON.stringify(times));
        }
      } catch (error) {
        console.log("Error fetching meedoen data:", error);
      } finally {
        try {
          const responsemeedoen = await fetch(`${API}/users?filters[meedoen]=true&filters[meedoenOpWheel]=false`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          });
  
          if (responsemeedoen.ok) {
            const datameedoen = await responsemeedoen.json();
            const names = datameedoen.map(user => user.username);
  
            setNamesNietMeedoenOpWheel(names);
            localStorage.setItem("namesListNietMeedoenOpWheel", JSON.stringify(names));
          }
        } catch (error) {
          console.log("Error fetching meedoen op wheel data:", error);
        } finally {
          // console.log(names);
          // console.log(data.data.attributes.IDinWinnersArray, data.data.attributes.rotation);
          // selectResultEventHandler(data.data.attributes.IDinWinnersArray, items, names);
          setRotation(data.data.attributes.rotation);
        }
      }
    }
  }


  // use arrow function so we don't have to .bind(this) in constructor
  function clearListEventHandler() {
    setWinners([]);
  }

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}-${date}-${year}`;
  }

  const currentDate = useState(getDate());

  async function removeWinnerModal() {
    // console.log(dataWheelID);
    try {
      const response = await fetch(`${API}/wheels/${dataWheelID.data.id}`, {
        headers:{
          Authorization: `Bearer ${getToken()}`,
        }
      });
      const data = await response.json();
      // console.log(data);
      const check = (data.data.attributes.ripclicked);
      // console.log(check);
      if(data.data.attributes.ripclicked === true){
        window.location = '/producten';
      }
  } catch (error) {
      console.error(error);
     
  }

  
try {
  const dataformeedoenOpWheel = {
    'data': {
    ripclicked: true,
    }
  }
  const response = await fetch(`${API}/wheels/${dataWheelID.data.id}`, { 
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(dataformeedoenOpWheel),
  });

  const responseData = await response.json();

  if (response.ok) {
    // console.log("updatakans");
  } else {
    console.error("Error updating user:", responseData);
    message.error("Error While Updating the Profile!");
  }
} catch (error) {
  console.error(error);
  message.error("Network Error While Updating the Profile!");
}

try{
  const dataSpinnerDone = {
    'data': {
      spinnerFinished: true,
    }
  }
  const response = await fetch(`${API}/polls/${poll.id}`, {
    method: "PUT",
    headers:{
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(dataSpinnerDone),
  });

} catch(error) {
  console.log(error)
}

  // console.log(winnersData);
    const winner = winners[winners.length - 1];
    // console.log(winner);
    // const index = items.indexOf(winner);
    const today = new Date();
    // items.splice(index, 1);
    setItems(items);
    setOpenModal(false);
    // console.log(winner);
    // const url = `http://localhost:1337/api/snackvreters/${winner}`;
    // fetch(url)
    //     .then(response => response.json())
    //     .then(persoonData => {
    //       setWinnersData(persoonData.data.attributes.kans);
    //       setWinnersDataBestellingen(persoonData.data.attributes.bestellingen);
    //       console.log(persoonData.data.attributes.kans);
    //     })
    //     .catch(error => {
    //       console.error("error fetching names data: ", error);
    //     })
    //     console.log(currentDate[0]);
    if (winnersData !== "") {
      // console.log(localStorage.getItem("namesList"));
      // console.log("hoi");
      // console.log(winner);
      // console.log(winnersData);
      // console.log(winnersDataBestellingen);
      // console.log("ifwinnersdata")
      updateBestellers();
      updateKans (winner, {
        bestellingen: winnersDataBestellingen + 1,
        laatsteBestelTijd: new Date().toJSON(),
        laatsteBestelling: true,
        meedoen: false,
        meedoenOpWheel: false,
      }
      )

    }
    // console.log(winnersDataBestellingen);
    // console.log(winnersData);
  }

  const fetchProfiles = async () => {
    setIsLoading(true);
    try {
        const response = await fetch(`${API}/users/${user.id}`, {
          headers:{
            Authorization: `Bearer ${getToken()}`,
          }
        });
        const data = await response.json();
        setProfiles(data);
    } catch (error) {
        // console.error(error);
       
    } finally {
        setIsLoading(false);
        // fetchAlleOptions();
        // fetchHeleBestelling();
        // console.log("fetvhprofukes")
    }
};

const saveColors = async () => {
  const dataPutColors = {
    wheelColor: state,
  }
  try{
    const response = await fetch(`${API}/users/${user.id}`, { 
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(dataPutColors),
        });
  } catch(error) {
console.log("oops", error);
  }
}

const handleInput = async (e) => {
  const existingData = localStorage.getItem("wheelColors");
const userColors = existingData ? JSON.parse(existingData) : {};
const username = user?.username;
const color = e.target.value;
userColors[username] = color;
localStorage.setItem("wheelColors", JSON.stringify(userColors));
  updateState(e.target.value);
  // console.log(e.target.value, state);
  localStorage.setItem("wheelColor", e.target.value);
};

// handle font color input
const handleInput2 = (e) => {
  updateState2(e.target.value);
  localStorage.setItem("fontColor", state2);
};

const spinCanvasWheel = async () => {
  try {
    const response = await fetch(`${API}/users?populate=*`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${getToken()}`,
          },
    });
    const data = await response.json();
    const profiles = (data ?? []);
    const allMeeBestellenFalse = profiles.every(user => user.meeBestellen !== true);
  if (!allMeeBestellenFalse) {
    alert("vorige bestelling nog niet voltooid");
    console.log("vorige bestelling nog niet voltooid");
    return;
  } else {
    if (items.length === 0 || spinning === true) {
      alert("Can't spin a empty wheel or spinning wheel");
      console.log("Can't spin a empty wheel or spinning wheel");
      return;
  }
  const procentkansString = localStorage.getItem("procentkans");
    const procentkans = JSON.parse(procentkansString);
    // console.log(procentkans);
    // if (selectedItem === null) {
      // const selectedItem = Math.floor(Math.random() * this.props.items.length);
      const randomgenerator = Math.floor(Math.random() * 100);
      const range = procentkans.length;
      let totalvalue = 0
      let amount = 0
      
      while (totalvalue < randomgenerator) {
        const numberprocentkans = parseInt(procentkans[amount]);
        // console.log(totalvalue);
        // console.log(procentkans[amount]);
        // console.log(numberprocentkans + parseInt(totalvalue));
        if((numberprocentkans + parseInt(totalvalue)) > randomgenerator){
          // console.log("AGAIN");
          break;
        }
          // console.log("AGAIN");
          totalvalue = (parseInt(totalvalue) + parseInt(procentkans[amount]));
          amount = (amount+1);
      }
      // console.log(amount);
      const selectedItem = amount;
      // console.log(randomgenerator);
      // console.log(selectedItem);
      // console.log(selectedItem);
      // selectResultEventHandler(selectedItem);
      // setRotation(randomgenerator); 
      wheelSpinData(selectedItem, randomgenerator);
      // if (this.props.onSelectItem) {
      //   this.props.onSelectItem(selectedItem);
      // }
      // this.setState({ selectedItem });
    // } else {
      // this.setState({ selectedItem: null });
      // setTimeout(this.selectItem, 500);
  //   // }
  //   console.log("klik");
  // const randomRotations = Math.floor(Math.random() * 5) + numberOfSections * 5;
  // const randomStopSection = names[Math.floor(Math.random() * numberOfSections)];

  // const targetIndex = names.indexOf(randomStopSection);
  // const targetRotation = (targetIndex * 360) / numberOfSections;

  
    }
  } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
  } finally {
    // console.log(names);
      
  }

  
};

const fetchUsers = async () => {
  setIsLoading(true);
  try {
      const response = await fetch(`${API}/users?populate=*`, {
        headers:{
          Authorization: `Bearer ${getToken()}`,
        }
      });
      const data = await response.json();
      console.log(data)
      setUsers(data);
  } catch (error) {
      // console.error(error);
     
  } finally {
      setIsLoading(false);
      // fetchAlleOptions();
      // fetchHeleBestelling();
      // console.log("fetvhprofukes")
  }
};

useEffect(() => {
  fetchUsers();
}, [user]);

useEffect(() => {
  if(users.length !== 0){
    fetchProfiles();
    fetchUsersColors();
    addData();
  }
}, [users]);

useEffect(() => {
  fetchLatestPoll();
}, [names]);

useEffect(() => {
  // console.log(poll);
  // console.log(poll?.length);
  makeBarChart();
}, [poll]);

const makeBarChart = () => {
  if(poll.length !== 0) {
  const labels = [poll.attributes.poll[0].antwoord, poll.attributes.poll[1].antwoord, poll.attributes.poll[2].antwoord];
const data = {
  labels: labels,
  datasets: [{
    label: 'votes',
    data: [poll.attributes.poll[0].votes, poll.attributes.poll[1].votes, poll.attributes.poll[2].votes],
    backgroundColor: [
      '#3e8e4176',
      '#3e8e4176',
      '#3e8e4176',
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      '#02a672',
      '#02a672',
      '#02a672',
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
};
const optionsChart = {
  onClick: (e, activeEls) => {
    // console.log(e, activeEls);
    if(activeEls.length !== 0){
      // console.log(e, activeEls);
    const datasetIndex = activeEls[0].datasetIndex;
    const dataIndex = activeEls[0].index;
    const datasetLabel = e.chart.data.datasets[datasetIndex].label;
    const value = e.chart.data.datasets[datasetIndex].data[dataIndex];
    const label = e.chart.data.labels[dataIndex];
    // console.log("In click", datasetLabel, label, value);
  }},
};
setOptions(optionsChart);
setBarChartData(data);
  } else {
    const labels = ["no data to show"];
const data = {
  labels: labels,
  datasets: [{
    label: 'No data to show',
    data: [0],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
};
const optionsChart = {
  onClick: function (event, elements) {
    if (elements.length > 0) {
      const label = event.chart.data.labels[elements[0]._index];
      const value = event.chart.data.datasets[elements[0]._datasetIndex].data[elements[0]._index];
      // console.log("HEY", label, value);
      // Handle your click event here
    }
  },
};
setOptions(optionsChart);
setBarChartData(data);
  }
}

const getSwitchDataBestellen = async () => {
    
  // setIsLoading(true);
  //     try {
  //         if (user) { 
  //             const response = await fetch(`${API}/users/${user.id}`);
              
  //             if (!response.ok) {
  //                 throw new Error(`HTTP error! Status: ${response.status}`);
  //             }
  
  //             const data = await response.json();
  //             setProfiles(data ?? []);
  //             console.log("Fetched Profile:", user);
  //         }
  //     } catch (error) {
  //         console.error(error);
  //     } finally {
        // console.log(profiles.meedoen);
          if(profiles.meedoenOpWheel === true){
            const dataformeedoenOpWheel = {
              meedoenOpWheel: false,
            }
          try {
            
            const response = await fetch(`${API}/users/${user.id}`, { 
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(dataformeedoenOpWheel),
            });
        
            const responseData = await response.json();
        
            if (response.ok) {
              // setUser(responseData);
              message.success("Data saved successfully!");
              // console.log("updatakans");
            } else {
              console.error("Error updating user:", responseData);
              message.error("Error While Updating the Profile!");
            }
          } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Profile!");
          } finally {
            // fetchProfiles();
            // addData();
          }
              } else if(profiles.meedoenOpWheel !== true){
                const dataformeedoenOpWheel = {
                  meedoenOpWheel: true,
                }
              try {
                
                const response = await fetch(`${API}/users/${user.id}`, { 
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                  body: JSON.stringify(dataformeedoenOpWheel),
                });
            
                const responseData = await response.json();
            
                if (response.ok) {
                  // setUser(responseData);
                  message.success("Data saved successfully!");
                  // console.log("updatakans");
                } else {
                  console.error("Error updating user:", responseData);
                  message.error("Error While Updating the Profile!");
                }
              } catch (error) {
                console.error(error);
                message.error("Network Error While Updating the Profile!");
              } finally {
                // fetchProfiles();
                // addData();
              }
              } 
            
          
              setIsLoading(false);
              
      // }
}

 const startShootingAnimation = (data) => {
  
 }

  const getSwitchData = async () => {
    
    // setIsLoading(true);
    //     try {
    //         if (user) { 
    //             const response = await fetch(`${API}/users/${user.id}`);
                
    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! Status: ${response.status}`);
    //             }
    
    //             const data = await response.json();
    //             setProfiles(data ?? []);
    //             console.log("Fetched Profile:", user);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
          // console.log(profiles.meedoen);
            if(profiles.meedoen === true){
              const dataformeedoen = {
                meedoen: false,
              }
            try {
              
              const response = await fetch(`${API}/users/${user.id}`, { 
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify(dataformeedoen),
              });
          
              const responseData = await response.json();
          
              if (response.ok) {
                // setUser(responseData);
                message.success("Data saved successfully!");
                // console.log("updatakans");
              } else {
                console.error("Error updating user:", responseData);
                message.error("Error While Updating the Profile!");
              }
            } catch (error) {
              console.error(error);
              message.error("Network Error While Updating the Profile!");
            } finally {

              // fetchProfiles();
              // addData();
            }
                } else if(profiles.meedoen !== true){
                  const dataformeedoen = {
                    meedoen: true,
                  }
                try {
                  
                  const response = await fetch(`${API}/users/${user.id}`, { 
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(dataformeedoen),
                  });
              
                  const responseData = await response.json();
              
                  if (response.ok) {
                    // setUser(responseData);
                    message.success("Data saved successfully!");
                    // console.log("updatakans");
                  } else {
                    console.error("Error updating user:", responseData);
                    message.error("Error While Updating the Profile!");
                  }
                } catch (error) {
                  console.error(error);
                  message.error("Network Error While Updating the Profile!");
                } finally {
                  // fetchProfiles();
                  // addData();
                }
                } 
              
            
                setIsLoading(false);
                
        // }
  }

  const doejemee = async () => {
    if(profiles.meedoen === true){

    }
  }

  const wheelSpinData = async (data, randomgenerator) => {
    setIsLoading(true);
    const data2 = {
      'data': {
        IDinWinnersArray: data,
        ripclicked: false,
        rotation: randomgenerator,
      }
    };

    try {
        const response = await fetch(`${API}/wheels`, {
          method: "POST",
          headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data2),
        });
    } catch (error) {
        console.error(error);
       
    } finally {
        setIsLoading(false);
    }
};

  function selectResultEventHandler(data, items, names) {
    if (items.length > 0 && spinning !== true) {
      if(names && names.length > 0){
 // var selectedIndex = data;
      // console.log(selectedIndex);
      // console.log(data, names, items);
      setWinnerName(names[data]);
      // console.log(names, items);
      // wheelSpinData(data);
      // console.log(winnerName);
      // console.log(localStorage.getItem("oldestDate"))
      // set this state to disable tab and wheel click when spinning
      setSpinning(true);
      setWinners(winners.concat(items[data]));
      const winner = items[data];
      // const winner = winners[winners.length - 1];
      setTimeout(() => {
        setSpinning(false);
      }, window.localStorage.getItem("duration") * 1000);
      setTimeout(() => {
        // console.log(winners);
        // console.log(winner);
        
        const url = `${API}/users/${winner}`;
            fetch(url, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${getToken()}`,
              }
            })
              .then(response => response.json())
              .then(persoonData => {
                setWinnersData(persoonData.kans);
                setWinnersDataBestellingen(persoonData.bestellingen);
                // console.log(persoonData.kans);
              })
              .catch(error => {
                console.error("error fetching names data: ", error);
              })
              // console.log(currentDate[0]);
        // setTimeout(() => {
        //   console.log(winners);
        //   const currentWinner = winners[winners.length - 1];
        //   console.log(currentWinner);
        //   setTimeout(() => {
        //     const url = `http://localhost:1337/api/snackvreters/${currentWinner}`;
        //     fetch(url)
        //       .then(response => response.json())
        //       .then(persoonData => {
        //         console.log(persoonData.data.attributes.naam);
        //         setWinnersData(persoonData.data);
        //     })
        //       .catch(error => {
        //       console.error("error fetching names data: ", error);
        //     })
        //   }, 100)
        // }, 100)
        setTimeout(() => {
          // console.log(winnersData);
        }, 110)
        
      }, window.localStorage.getItem("duration") * 1000);

      setTimeout(() => {
        setOpenModal(true);
      }, window.localStorage.getItem("duration") * 1010);
      } else {
        // console.log("OK");
        setTimeout(() => {
          selectResultEventHandler(data);
        }, 500)
      }
     
    }
  }

  const fetchWheelData = async () => {
    try{
      const response = await fetch(`${API}/wheels`, {
        method: "GET",
        headers:{
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responsedata = await response.json();
    } finally {

    }
  }

//   const fetchWebsiteTest = async () => {
//     if(infoWebsites.length !== 0) {
//       console.log(infoWebsites.length);
//       console.log(infoWebsites);
//     const obj = Object.entries(infoWebsites);
//     const length = obj.length;
//     const halfobj = (length/2).toFixed(0);
//     console.log(obj.length, 'ok', obj, 'ok', obj[0][0], obj[0][1].time_total);
//     // const labels = [obj[0][0], obj[halfobj][0], obj[length - 1][0]];
//     const labels = obj.slice(-60).map((item) => {
//       console.log(item[0]);
//       const secondsSinceEpoch = parseInt(item[0], 10);
// const date = new Date(secondsSinceEpoch * 1000); // Convert seconds to milliseconds

// const hours = date.getHours().toString().padStart(2, '0');
// const minutes = date.getMinutes().toString().padStart(2, '0');

// const formattedTime = `${hours}:${minutes}`;
// return formattedTime;
//     });
//     console.log(labels);
    
//     const datasets = [
//       {
//         label: "time total",
//         data: obj.slice(-60).map((item) => item[1].time_total), // Using all data values
//         fill: true,
//         backgroundColor: "rgba(75,192,192,0.1)",
//         borderColor: "rgba(75,192,192,1)",
//         tension: 0.3,
//       },
//       {
//         label: "time to first byte",
//         data: obj.slice(-60).map((item) => item[1].time_to_first_byte), // Using all data values
//         fill: true,
//         backgroundColor: "rgba(255,50,50,0.1)",
//         borderColor: "rgba(255,50,50,1)",
//         tension: 0.3,
//       },
//       {
//         label: "time dns",
//         data: obj.slice(-60).map((item) => item[1].time_dns), // Using all data values
//         fill: true,
//         backgroundColor: "rgba(175,150,50,0.1)",
//         borderColor: "rgba(175,150,50,1)",
//         tension: 0.3,
//       },
//       {
//         label: "time connect",
//         data: obj.slice(-60).map((item) => item[1].time_connect), // Using all data values
//         fill: true,
//         backgroundColor: "rgba(175,150,250,0.1)",
//         borderColor: "rgba(175,150,250,1)",
//         tension: 0.3,
//       },
//       // {
//       //   label: "cpu load 5",
//       //   data: obj5.slice(-60).map((item) => item[1]), // Using all data values
//       //   fill: true,
//       //   backgroundColor: "rgba(5,150,250,0.2)",
//       //   borderColor: "rgba(5,150,250,1)",
//       //   tension: 0.4,
//       // },
//     ];
// //     const dataToSave = {
// //   labels: [obj[0][0], "k", "k", obj[halfobj][0], "k", "k", obj[length-1][0]],
// //   datasets: [
// //     {
// //       label: "First dataset",
// //       data: [obj[0][1], 53, 85, obj[halfobj][1], 41, 44, obj[length-1][1]],
// //       fill: true,
// //       backgroundColor: "rgba(75,192,192,0.2)",
// //       borderColor: "rgba(75,192,192,1)",
// //       tension: 0.4
// //     },
// //     {
// //       label: "Second dataset",
// //       data: [33, 25, 35, 10, 51, 54, 76],
// //       fill: false,
// //       borderColor: "#742774",
// //       tension: 0.4
// //     }
// //   ]
// // };

// const dataToSave = {
//   labels: labels,
//   datasets: datasets,
// };

// console.log(dataToSave);
// setData3(dataToSave);
// // waitAMinute();
//     }
    
//   }


  const fetchLatestPoll = async () => {
    try {
      const response = await fetch(`${API}/polls?populate=*&filters[spinnerFinished][$eq]=false`, {
        headers:{
          Authorization: `Bearer ${getToken()}`,
        }
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Parse the response as JSON
      const data = await response.json();
      // console.log(data, names);
      

      // if(names.length !== 0 && data.data.length !== 0){
      
      if(data.data.length > 1){
        message.error("somehow there is multiple open polls");
        // console.log(data.data.length);
        // console.log("latest poll = ",  data.data[data.data.length - 1]);
        if(data.data[data.data.length - 1].attributes.users.data.length < data.data[data.data.length - 1].attributes.maxVotes){
          setBooleanOn(false);
        setPoll(data.data[data.data.length - 1]);
      } else {
        // console.log("AFGKYAGFJYFGSDYFVSYFGSYFGSYFEYISDFGYFGYEGFIgvydjrygesy");
        setPoll(data.data[data.data.length - 1]);
        setBooleanOn(false);
        // console.log(data.data[data.data.length - 1].attributes.users.data.length, data.data[data.data.length - 1].attributes.maxVotes)
        if(data.data[data.data.length - 1].attributes.pollDone !== true){

        
        finishPoll(data.data[data.data.length - 1]);
      }}
      } else {
        if(data.data.length !== 0){
        // console.log(data.data[0].attributes.users.data.length, data.data[data.data.length - 1].attributes.maxVotes);
        if(data.data[0].attributes.users.data.length < data.data[0].attributes.maxVotes){
          setBooleanOn(false);
          // console.log("OK");
          setPoll(data.data[0]);
        } else {
          // console.log(data.data[0]);
          setPoll(data.data[0]);
          setBooleanOn(false);
          // console.log(data.data[0].attributes.users.data.length, names.length)
          if(data.data[0].attributes.pollDone !== true){
          finishPoll(data.data[0]);
        }}
      } else {
        setBooleanOn(true);
      }
      
        
      } 
    // } 
    // } else {
    //   if(names.length === 0 && emptyUsers === false){
    //     setBooleanOn(true);
    //     setTimeout(() => {
    //       addData();
    //   }, 1500)
    //   }
      
    // }
    
      // Set the latest record in state
      // setLatestRecord(data[0]);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const finishPoll = async (poll) => {
    // console.log("JAJAJAJ", poll, poll.id);
    const mostVotes = poll.attributes.poll[0].votes > poll.attributes.poll[1].votes ? poll.attributes.poll[0].votes > poll.attributes.poll[2].votes ? {
      text: `${poll.attributes.poll[0].antwoord}`
    } : {
      text: `${poll.attributes.poll[2].antwoord}`
    } : poll.attributes.poll[1].votes > poll.attributes.poll[2].votes ? {
      text: `${poll.attributes.poll[1].antwoord}`
    } : {
      text: `${poll.attributes.poll[2].antwoord}`
    }

    // console.log(mostVotes);
    const dataPollFinished = {
      'data': {
        pollDone: true,
        mostVoted: mostVotes.text
      }
    }
    try {
      const response = await fetch(`${API}/polls/${poll.id}`, {
        method: "PUT",
        headers:{
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(dataPollFinished),
      });
  } catch (error) {
      console.error(error);
     
  } finally {
    // console.log("should be working");
      setIsLoading(false);
  }
  }

  const createPoll = async (ids) => {
    setIsLoading(true);
   
    const dataPollPost = {
      'data': {
        maxVotes: ids.length,
        pollQuestion: "testvraag",
        pollDone: false,
        poll: [
          {
            antwoord: "wheel",
            votes: 0,
          },
          {
            antwoord: "roulette",
            votes: 0,
          },
          {
            antwoord: "maakt niet uit",
            votes: 0,
          },
        ],
        spinnerFinished: false,
      }
    };

    try {
        const response = await fetch(`${API}/polls`, {
          method: "POST",
          headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(dataPollPost),
        });
    } catch (error) {
        console.error(error);
       
    } finally {
      // console.log("should be working");
        setIsLoading(false);
    }
    // console.log("createPollHere");
  }

  const votePoll = async (vote) => {
    setIsLoading(true);
    const dataPollPut = {
      'data': {
        users: poll.attributes.users.data
          ? [...poll.attributes.users.data, user?.id]
          : [user?.id],
        pollDone: false,
        poll: [
          {
            antwoord: "wheel",
            votes: vote === poll.attributes.poll[0].antwoord ? poll.attributes.poll[0].votes + 1 : poll.attributes.poll[0].votes,
            users: vote === poll.attributes.poll[0].antwoord ? user?.id : null,
          },
          {
            antwoord: "roulette",
            votes: vote === poll.attributes.poll[1].antwoord ? poll.attributes.poll[1].votes + 1 : poll.attributes.poll[1].votes,
            users: vote === poll.attributes.poll[1].antwoord ? user?.id : null,
          },
          {
            antwoord: "maakt niet uit",
            votes: vote === poll.attributes.poll[2].antwoord ? poll.attributes.poll[2].votes + 1 : poll.attributes.poll[2].votes,
            users: vote === poll.attributes.poll[2].antwoord ? user?.id : null,
          },
        ],
      }
    };

    try {
        const response = await fetch(`${API}/polls/${poll.id}`, {
          method: "PUT",
          headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(dataPollPut),
        });
    } catch (error) {
        console.error(error);
       
    } finally {
      // console.log("should be working");
        setIsLoading(false);
    }
    // console.log("createPollHere");
  }

  const startPollQuestion = async () => {
    try{
    const response = await fetch(`${API}/users?populate=*`, {
      method: "GET",
      headers: {
          Authorization: `Bearer ${getToken()}`,
        },
  });
  const data = await response.json();
  const profiles = (data ?? []);
  console.log(profiles);
  const filteredProfiles = profiles.filter(user => user.meedoenOpWheel);

 // Get only the ids of the users
 const userIds = filteredProfiles.map(user => user.id);

  const allMeeBestellenFalse = profiles.every(user => user.meeBestellen !== true);
if (!allMeeBestellenFalse) {
  alert("vorige bestelling nog niet voltooid");
  console.log("vorige bestelling nog niet voltooid");
  return;
} else {
  if (items.length === 0 || spinning === true) {
    alert("Can' t start without users and can't start when other poll isn't finished");
    console.log("Can't spin a empty wheel or spinning wheel");
    return;
}
    antdModal.confirm({
      title: 'Weet je zeker dat iedereen er klaar voor is? Mensen die nog niet meedoen kunnen niet meer meedoen nadat je op OK hebt gedrukt',
      onOk() {
        createPoll(userIds);
      },
      onCancel() {
        // console.log("hoi");
      },
    });
  }
} catch(error) {
  console.log(error);
}
}

function gotorussianroulette() {
  window.location = '/russianroulette';
}

  const voteConfirm = async (value) => {
    const vote = value
    const checkIfVoted = poll.attributes.users.data.some(userData => userData.id === user?.id);
    // console.log(checkIfVoted);
    console.log(vote, poll.attributes.poll[0].antwoord);
    if (checkIfVoted === false){
    antdModal.confirm({
      title: `Wil je echt voor ${vote} stemmen? Je kunt je stem niet meer aanpassen.`,
      onOk() {
        votePoll(vote);
      },
      onCancel() {
        // console.log("hoi");
      },
    });
  } else {
    alert(`you already have voted!`)
  }
  }

  function spinWheel(data) {
    // console.log(rotation);
    if(items.length > 0 && spinning !== true) {
      // selectResultEventHandler(data.data.attributes.IDinWinnersArray)
      setRotation(data.data.attributes.rotation);
      
      // console.log("OKESPIN");
    //   setSpinning(true);
    // setTimeout(() => {
    //   setSpinning(false);
    //   setOpenModal(true);
    //   // document.getElementById("inputTextArea").disabled = false;
    //   // document.getElementById("updateButton").disabled = false;
    //   // document.getElementById("inputSearchBar").disabled = false;
    //   // document.getElementById("shuffleButton").disabled = false;
    //   // document.getElementById("removeButton").disabled = false;
    //   // document.getElementById("clearListButton").disabled = false;
    // }, window.localStorage.getItem("duration") * 1000);
    }
    
  }

  useEffect(() => {
    // Listen for socket events and handle them
    if (socket) {
      // console.log(names);
      socket.on("connect", () => {
        console.log("HET WIEL");
        socket.on("users-permissions:update", (data) => {
          console.log("test op wiel")
          fetchUsers();
        });
        socket.on("wheel:create", (data) => {
          console.log(spinning, items, names, namesMeedoen, namesNietMeedoenOpWheel);
          // console.log("ALALLALALAL")
          setdataWheelID(data);
          // console.log(data);
          // spinWheel(data);

          // const valueNames = JSON.parse(window.localStorage.getItem("namesList"));
          // console.log(valueNames);
          // setNames(valueNames);
          // console.log(names);
          // selectResultEventHandler(data.data.attributes.IDinWinnersArray);
          // console.log(window.localStorage.getItem("namesList"));
          // console.log(data.data.attributes.IDinWinnersArray, data.data.attributes.rotation);
          addData2(data);
          // setSpinning(true);
          // selectResultEventHandler(data.data.attributes.IDinWinnersArray);
          
          
          // // setdataWheelID(data);
          // // console.log(data);
          // // console.log(names);
          // // setWinnerName(names[data.data.attributes.IDinWinnersArray]);
          // setRotation(data.data.attributes.rotation);


        });

        socket.on("roulette:create", (data) => {
          console.log(data.data.attributes);
          startShootingAnimation(data.data.attributes);
        });

        socket.on("poll:create", (data) => {
          fetchLatestPoll();
          // setPoll(data.data.attributes);
          // startShootingAnimation(data.data.attributes);
        });

        socket.on("poll:update", (data) => {
          fetchLatestPoll();
          // console.log(data);
          // setPoll(data.data.attributes);
          // startShootingAnimation(data.data.attributes);
        });

        socket.on("disconnect", () => {
          console.log("Socket disconnected");
        });
      });
    }
  }, [socket]);


  let newWinnerIndex = winners.length - 1;

  return (
    <div>
      
      <Modal show={openModal} onHide={cancelModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>We have a LOSER 😝</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{winnerName}</p>
        </Modal.Body>
        <Modal.Footer>                   
          <Button variant="primary" onClick={removeWinnerModal}>
            rip {winnerName}
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
      
        <Row><Col className="mt-4" lg="2" md="auto">
        
        {spinning === false && booleanOn === true ? (
          <>
          <div className="meedoendiv"><p>doe je mee?</p>
          <Switch style={{backgroundColor: profiles.meedoen ? '#02a672' : 'lightgrey'}} checked={profiles.meedoen} onChange={getSwitchData}/>
      <p>{profiles.meedoen}</p></div>
      <div className="meebestellendiv"><p>kan je bestellen?</p>
      <Switch style={{backgroundColor: profiles.meedoenOpWheel ? '#02a672' : 'lightgrey'}} checked={profiles.meedoenOpWheel} onChange={getSwitchDataBestellen}/>
      <p>{profiles.meedoenOpWheel}</p></div>
      </>
        ) : (
          <>
          <div className="meedoendiv"><p>doe je mee?</p>
          <Switch style={{backgroundColor: profiles.meedoen ? '#02a672' : 'lightgrey'}} checked={profiles.meedoen}/>
      <p>{profiles.meedoen}</p></div>
      <div className="meebestellendiv"><p>kan je bestellen?</p>
      <Switch style={{backgroundColor: profiles.meedoenOpWheel ? '#02a672' : 'lightgrey'}} checked={profiles.meedoenOpWheel}/>
      <p>{profiles.meedoenOpWheel}</p></div></>
        )}

      
          </Col>
          <Col className="mt-4" lg="6" md="auto">

            {/* <WheelWithRef
              ref={wheelRef}
              items={names}
              onChange={selectResultEventHandler}
              spinning={spinning}
              wheelColor={wheelColor}
              fontColor={fontColor}
              className="wheelbackground"

            /> */}<div style={{ position: 'relative', height: '600px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
           {/* <button>is iedereen er klaar voor?</button> */}
           {/* <GunCanvas /> */}
           {/* <div className="divleft" height="600px" style={{ display: 'flex',
    flexDirection: 'column',
    height: '600px',
    justifyContent: 'space-around' }}>
           {names?.map((namesMap, index) => (
            <p key={index}><img src={pistol} alt='Pistol Image' className="pistolimage" value={index} onClick={(e) => pistolShot(e, index)}/></p>
           ))}
           </div>
           <div className="divright" height="600px" style={{  display: 'flex',
    flexDirection: 'column',
    height: '600px',
    justifyContent: 'space-around' }}>
      {names?.map((namesMap, index) => (
        <p key={index}><p>{namesMap}</p><p>{kansPercentagePersoon[index]}% kans op schot</p></p>
      ))}
           
           </div> */}
{/* <div className="divleft" height="600px" style={{ display: 'flex',
    flexDirection: 'column',
    height: '600px',
    justifyContent: 'flex-start',
    marginTop: '70px' }}>
           <p><img src={revolver} alt='Pistol Image' width="300px" /></p>
</div>
<div className="divright" height="600px" style={{  display: 'flex',
    flexDirection: 'column',
    height: '600px',
    justifyContent: 'flex-start' }}>
      <CanvasStickman imageRevolver={revolver} />
    </div> */}

    {poll?.id ? (
    <>
        {poll.attributes.pollDone !== true ? (
          <div style={{ width: '100%' }}>
          <Bar data={barChartData} options={options} />
       <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: '26px' }}>
  <button value={poll.attributes.poll[0].antwoord} onClick={() => voteConfirm(poll.attributes.poll[0].antwoord)} className="aVoting"><span value={poll.attributes.poll[0].antwoord}>VOTE</span></button><button value={poll.attributes.poll[1].antwoord} onClick={() => voteConfirm(poll.attributes.poll[1].antwoord)} className="aVoting"><span value={poll.attributes.poll[1].antwoord}>VOTE</span></button><button value={poll.attributes.poll[2].antwoord} onClick={() => voteConfirm(poll.attributes.poll[2].antwoord)} className="aVoting"><span value={poll.attributes.poll[2].antwoord}>VOTE</span></button>
       {/* <button value={poll.attributes.poll[0].antwoord} onClick={voteConfirm}>vote</button><button value={poll.attributes.poll[1].antwoord} onClick={voteConfirm}>vote</button><button value={poll.attributes.poll[2].antwoord} onClick={voteConfirm}>vote</button> */}
       </div>
       </div>
        ) : (
<div style={{ width: '100%', height: '100%' }}>{poll.attributes.mostVoted === "roulette" ? (
  <div class="wrapperVotingRoulette">
  <a class="ctaVotingRoulette" onClick={gotorussianroulette}>
    <span className="spanVotingRoulette">ROULETTE</span>
    <span className="spanVotingRoulette" style={{ marginTop: '-10px'}}>
      <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path class="oneVotingRoulette" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path class="twoVotingRoulette" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path class="threeVotingRoulette" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span> 
  </a>
  {/* <button className="buttonStartVoting" onClick={startPollQuestion}>START</button> */}
      {/* <button onClick={gotorussianroulette}>{poll.attributes.mostVoted}</button> */}
</div>
  
) : (
<div>
  <CanvasBar onClick={spinCanvasWheel} className="wheelcanvas" width="650px" height="650px" style={{ zIndex: 1, display: 'flex', width:'50%' }}></CanvasBar>
       
            <Canvas onClick={spinCanvasWheel} sectionColors={wheelColors} numberOfSections={names.length} sectionNames={names} rotation={rotation} groottePercentage={kansPercentagePersoon} className="wheelcanvas" width="650px" height="650px" style={{ zIndex: 2, display: 'flex', width:'50%' }}></Canvas>
  </div>
  
)}</div>
        )}
       
       </>
    ) : (
      
      

<div class="wrapperVoting">
  <a class="ctaVoting" onClick={startPollQuestion}>
    <span className="spanVoting">START</span>
    <span className="spanVoting" style={{ marginTop: '-10px'}}>
      <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
        <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path class="oneVoting" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path class="twoVoting" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
          <path class="threeVoting" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
        </g>
      </svg>
    </span> 
  </a>
  {/* <button className="buttonStartVoting" onClick={startPollQuestion}>START</button> */}
      
</div>
      
    )}

    

              {/* <CanvasBar onClick={spinCanvasWheel} className="wheelcanvas" width="650px" height="650px" style={{ zIndex: 1, display: 'flex', width:'50%' }}></CanvasBar>
       
            <Canvas onClick={spinCanvasWheel} sectionColors={wheelColors} numberOfSections={names.length} sectionNames={names} rotation={rotation} groottePercentage={kansPercentagePersoon} className="wheelcanvas" width="650px" height="650px" style={{ zIndex: 2, display: 'flex', width:'50%' }}></Canvas> */}
</div>
          </Col>
          <Col lg="3" md="auto">
            <div id="Tabs" className="mt-4">
              <Tabs defaultActiveKey="entries" className="mb-3">
                <Tab eventKey="entries" title="Entries">
                  
                  <div>Wie doen mee op het wheel?</div>
                  {/* <div className="fullbar">
                    <div className="percentagebar" style={{ width: `20%` }}>20%</div>
                  </div> */}
                  {kansPercentagePersoon?.map((kansenmap, index) => (
  <div className="fullbar" key={index}>
    {kansenmap > 85 ? (
      <>
        <div className="percentagebar" style={{ width: kansenmap > 10 ? `${kansenmap}%` : '10%' }}>
        <p className="othername"><p className="othernameshorter">{names[index]}</p></p><p className="atpercentage">{kansenmap}%</p>
        </div>
        <div style={{ width: kansenmap > 10 ? `${100 - kansenmap}%` : '90%' }}>
          
        </div>
      </>
    ) : (
      <>
        <div className="percentagebar" style={{ width: kansenmap > 10 ? `${kansenmap}%` : '10%' }}>
          <p className="atpercentage">{kansenmap}%</p>
        </div>
        <div className="divnamesatpercentagebar" style={{ 
            width: kansenmap > 10 ? `${100 - kansenmap}%` : '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '10px',
            overflow: 'hidden'
          }}>
          <p style={{ height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
     overflow: 'auto',
        textOverflow: 'ellipsis',
     width: '95%',
     margin: 0, }} className="nameatpercentagebar">{names[index]}</p>
        </div>
      </>
    )}
  </div>
))}



{namesNietMeedoenOpWheel.length > 0 && (
  <div>Wie eten mee maar niet op het wheel?</div>
)}

{namesNietMeedoenOpWheel?.map((naam, index) => (
  <div className="fullbargrey" key={index}>
  <div className="percentagebargrey" style={{ width: `37px` }}>
    <p className="atpercentage">0%</p>
  </div>
  <div style={{ 
            width: '90%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '10px'
          }}>
    <p className="nameatpercentagebar">{naam}</p>
  </div>
</div>
))}
                  
                </Tab>
                <Tab eventKey="eerdereOrders" title="your color">
                <ColorPicker
          value={state}
          onChange={handleInput}
          // value2={state2}
          // onChange2={handleInput2}
        />
        <button style={{backgroundColor: `${state || 'green'}`, marginTop: '10px', padding: '8px 20px', border: 'none', borderRadius: '20px', color: 'white', cursor:'pointer'}} onClick={saveColors}>update</button>
                </Tab>
              </Tabs>
            </div>
          </Col>
          {/* <Col
            className="mt-4"
            xxl={{ span: 3, order: "first" }}
            xl={{ span: 3, order: "first" }}
            lg={{ span: 3, order: "first" }}
            md={{ order: "last" }}
                > */}
          {/* </Col> */}
        </Row>
      </Container>
      
    </div>
  );
}

export default App;
import useSocketConnection from "../../socket";
import React, { useEffect, useState } from "react";
import { API } from "../../constants";
import { message } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import "./topboard.css";
import { getToken } from "../../helpers";

const TopBoard = () => {
    const socket = useSocketConnection();
    const { user, setUser } = useAuthContext();
    const [originalStats, setOriginalStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [sortedTotalSpent, setSortedTotalSpent] = useState([]);
    const [userProfiles, setUserProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserProfiles = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/users/${user.id}?populate=*`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setUserProfiles(data ?? []);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching user profiles!");
        } finally {
            setIsLoading(false);
        }
    };


    const fetchStats = async () => {
        try {
            const response = await fetch(`${API}/statistics/all`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${getToken()}`,
                }
            });
            const data = await response.json();
            data.sort((a, b) => b.totaalUitgegeven - a.totaalUitgegeven);
            setOriginalStats(data);
            setFilteredStats(data);
        } catch (error) {
            console.error(error);
            message.error("Error while fetching profiles!");
        }
    };

    useEffect(() => {
        if (socket) {
            socket.on("connect", () => {
                socket.on("statistic:update", (data) => {
                    fetchStats();
                });
                socket.on("statistic:create", (data) => {
                    fetchStats();
                });
                socket.on("statistic:delete", (data) => {
                    fetchStats();
                });
            });
        }
    }, [socket]);

    const calculateTotalSpent = (data) => {
        const totalSpentByUser = {};

        data.forEach((order) => {
            const totalPrice = parseFloat(order.totaalUitgegeven);
            const username = order.users.username;

            if (!totalSpentByUser[username]) {
                totalSpentByUser[username] = 0;
            }

            totalSpentByUser[username] += totalPrice;
        });

        return totalSpentByUser;
    };

    const filter = (filterType) => {
        let startDate = new Date();

        if (filterType === "7") {
            const currentDayOfWeek = startDate.getDay();
            const daysUntilMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 0;
            startDate.setDate(startDate.getDate() - daysUntilMonday);

            // Adjust start date's time zone offset to match profileDateTime
            startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
        } else if (filterType === "30") {
            startDate.setDate(startDate.getDate() - 30);
        } else {
            startDate = new Date(0); // Start of Unix epoch (all-time)
        }

        // console.log("Start Date:", startDate);

        const filteredData = originalStats.filter(profile => {
            const profileDateTime = new Date(`${profile.datum}T${profile.tijd}Z`);
            // console.log("Profile Date Time:", profileDateTime);
            return profileDateTime >= startDate;
        });

        filteredData.sort((a, b) => b.totaalUitgegeven - a.totaalUitgegeven);
        setFilteredStats(filteredData);

        const totalSpentByUser = calculateTotalSpent(filteredData);
        const sortedTotalSpent = Object.entries(totalSpentByUser).sort((a, b) => b[1] - a[1]);
        setSortedTotalSpent(sortedTotalSpent);
    };




    useEffect(() => {
        fetchUserProfiles();
        fetchStats();
    }, []);

    useEffect(() => {
        if (originalStats.length > 0) {
            filter();
        }
    }, [originalStats]);

    const totalUitgegeven = originalStats.reduce((total, stats) => {
        if (user?.username === stats.users.username && stats.totaalUitgegeven > 0) {
            total += stats.totaalUitgegeven;
        }
        return total;
    }, 0);

    const xp = userProfiles?.xp || 0;
    const levels = userProfiles?.levels || [];
    const maxXPForCurrentLevel = levels[0]?.maxXP || 1000; // Assuming maxXP is a property in each level

    const percentage = (xp % maxXPForCurrentLevel) / maxXPForCurrentLevel * 100;
    const level = levels[0]?.level || 0;
    const nextLevel = levels[1]?.level || level + 1;

    const percentageWidth = percentage > 100 ? '100%' : `${percentage}%`;

    return (
        <div className="topBoard">
            <div className="fullbar">
                <div className="percentagebar" style={{ width: `${percentageWidth}` }}>
                    <p className="othername">
                        <p className="othernameshorter">Level: {level}</p>
                    </p>
                    <p className="atpercentage">{percentage.toFixed(2)}%</p>
                </div>
            </div>


            <button className="filter-btn" onClick={() => filter("7")}>7</button>
            <button className="filter-btn" onClick={() => filter("30")}>30</button>
            <button className="filter-btn" onClick={() => filter("all-time")}>all-time</button>

            <div className="toptable">
                <h1 className="topleaderboard">Leaderboard</h1> 
                <div className="top-table-header">
                    <span className="topheader">Naam</span>
                    <span className="topheader">totaalUitgegeven</span>
                </div>
                {sortedTotalSpent.slice(0, 3).map(([username, totalSpent], index) => (
                    <div className={`topprofiles ${index % 2 === 0 ? 'top-even-row' : 'top-odd-row'}`} key={index}>
                        <span>{username}</span>
                        <span>€{totalSpent.toFixed(2)}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopBoard;

import React from "react";
import PropTypes from "prop-types";
import "./Pagination.css";

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    const maxVisiblePages = 10;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (currentPage <= Math.floor(maxVisiblePages / 2) + 1) {
        startPage = 1;
        endPage = Math.min(totalPages, maxVisiblePages);
    } else if (currentPage >= totalPages - Math.floor(maxVisiblePages / 2)) {
        startPage = Math.max(1, totalPages - maxVisiblePages + 1);
        endPage = totalPages;
    }

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const handlePageClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    return (
        <div className="pagination">
            <button onClick={() => handlePageClick(Math.max(1, currentPage - 1))} disabled={currentPage === 1}>
                Terug
            </button>
            {pageNumbers.map((pageNumber) => (
                <span key={pageNumber} className={pageNumber === currentPage ? "active" : ""} onClick={() => handlePageClick(pageNumber)}>
                    {pageNumber}
                </span>
            ))}
            <button onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))} disabled={currentPage === totalPages}>
                Volgende
            </button>
        </div>
    );
};

CustomPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;

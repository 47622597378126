// import { useEffect, useRef } from "react"

// const Canvas = props => {
//     const ref=useRef();
//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d')
//         // context.fillStyle = 'grey'
//         // context.fillRect(10,10,100,100)
//         ctx.beginPath();

//         // First half (left side)
//         ctx.arc(350, 350, 340, Math.PI, 0);
//         ctx.lineWidth = 10;
//         ctx.strokeStyle = 'blue';
//         ctx.stroke();
//         ctx.fillStyle = 'rgba(0, 255, 0, 1)';
//         ctx.fill();

//         ctx.beginPath();

//         // Second half (right side)
//         ctx.arc(350, 350, 340, 0, Math.PI);
//         ctx.lineWidth = 10;
//         ctx.strokeStyle = 'red';
//         ctx.stroke();
//         ctx.fillStyle = 'rgba(255, 0, 0, 1)';
//         ctx.fill();
//     }, [])
// return <canvas ref={ref} {...props} />
// }
// export default Canvas

// import { useEffect, useRef } from "react";

// const Canvas = (props) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         ctx.beginPath();

//         // First third (blue)
//         ctx.arc(350, 350, 340, Math.PI, (4 / 3) * Math.PI);
//         ctx.lineWidth = 10;
//         ctx.strokeStyle = 'blue';
//         ctx.stroke();
//         ctx.fillStyle = 'rgba(0, 0, 255, 1)';
//         ctx.fill();

//         ctx.beginPath();

//         // Second third (green)
//         ctx.arc(350, 350, 340, (4 / 3) * Math.PI, (5 / 3) * Math.PI);
//         ctx.lineWidth = 10;
//         ctx.strokeStyle = 'green';
//         ctx.stroke();
//         ctx.fillStyle = 'rgba(0, 255, 0, 1)';
//         ctx.fill();

//         ctx.beginPath();

//         // Third third (red)
//         ctx.arc(350, 350, 340, (5 / 3) * Math.PI, Math.PI);
//         ctx.lineWidth = 10;
//         ctx.strokeStyle = 'red';
//         ctx.stroke();
//         ctx.fillStyle = 'rgba(255, 0, 0, 1)';
//         ctx.fill();
//     }, []);

//     return <canvas ref={ref} {...props} />;
// };

// export default Canvas;


// import { useEffect, useRef } from "react";

// const Canvas = (props) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300; // Increased radius for a larger circle

//         ctx.beginPath();

//         // First third (blue)
//         ctx.moveTo(centerX, centerY);
//         ctx.arc(centerX, centerY, radius, 0, (Math.PI * 2) / 3);
//         ctx.closePath();
//         ctx.lineWidth = 2;
//         ctx.strokeStyle = 'blue';
//         ctx.fillStyle = 'blue';
//         ctx.stroke();
//         ctx.fill();

//         // Second third (green)
//         ctx.beginPath();
//         ctx.moveTo(centerX, centerY);
//         ctx.arc(centerX, centerY, radius, (Math.PI * 2) / 3, (Math.PI * 4) / 3);
//         ctx.closePath();
//         ctx.lineWidth = 2;
//         ctx.strokeStyle = 'green';
//         ctx.fillStyle = 'green';
//         ctx.stroke();
//         ctx.fill();

//         // Third third (red)
//         ctx.beginPath();
//         ctx.moveTo(centerX, centerY);
//         ctx.arc(centerX, centerY, radius, (Math.PI * 4) / 3, Math.PI * 2);
//         ctx.closePath();
//         ctx.lineWidth = 2;
//         ctx.strokeStyle = 'red';
//         ctx.fillStyle = 'red';
//         ctx.stroke();
//         ctx.fill();
//     }, []);

//     return <canvas ref={ref} width={600} height={600} {...props} />;
// };

// export default Canvas;

// import { useEffect, useRef } from "react";

// const Canvas = ({ numberOfSections, ...props }) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / numberOfSections;

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, i * sliceAngle, (i + 1) * sliceAngle);
//             ctx.closePath();

//             // Set colors dynamically or use a random color for each section
//             const randomColor = `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
//             ctx.lineWidth = 2;
//             ctx.strokeStyle = randomColor;
//             ctx.fillStyle = randomColor;

//             ctx.stroke();
//             ctx.fill();
//         }
//     }, [numberOfSections]);

//     return <canvas ref={ref} width={600} height={600} {...props} />;
// };

// export default Canvas;


// import { useEffect, useRef } from "react";

// const Canvas = ({ numberOfSections, ...props }) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / numberOfSections;

//         // Generate an array of random colors for each section
//         let sectionColors = Array.from({ length: numberOfSections }, () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`);

//         // Shuffle colors randomly
//         for (let i = sectionColors.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [sectionColors[i], sectionColors[j]] = [sectionColors[j], sectionColors[i]];
//         }

//         // Check and rotate colors to avoid first and last being the same
//         while (sectionColors[0] === sectionColors[sectionColors.length - 1]) {
//             const lastColor = sectionColors.pop();
//             sectionColors.unshift(lastColor);
//         }

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, i * sliceAngle, (i + 1) * sliceAngle);
//             ctx.closePath();

//             const currentColor = sectionColors[i];

//             ctx.lineWidth = 2;
//             ctx.strokeStyle = currentColor;
//             ctx.fillStyle = currentColor;

//             ctx.stroke();
//             ctx.fill();
//         }
//     }, [numberOfSections]);

//     return <canvas ref={ref} width={600} height={600} {...props} />;
// };

// export default Canvas;


// import { useEffect, useRef } from "react";

// const Canvas = ({ numberOfSections, sectionNames, ...props }) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / numberOfSections;

//         // Generate an array of random colors for each section
//         let sectionColors = Array.from({ length: numberOfSections }, () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`);

//         // Shuffle colors randomly
//         for (let i = sectionColors.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [sectionColors[i], sectionColors[j]] = [sectionColors[j], sectionColors[i]];
//         }

//         // Check and rotate colors to avoid first and last being the same
//         while (sectionColors[0] === sectionColors[sectionColors.length - 1]) {
//             const lastColor = sectionColors.pop();
//             sectionColors.unshift(lastColor);
//         }

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, i * sliceAngle, (i + 1) * sliceAngle);
//             ctx.closePath();

//             const currentColor = sectionColors[i];
//             const currentName = sectionNames[i];

//             ctx.lineWidth = 2;
//             ctx.strokeStyle = currentColor;
//             ctx.fillStyle = currentColor;

//             ctx.stroke();
//             ctx.fill();

//             // Add text - section names
//             ctx.save();
//             ctx.translate(centerX, centerY);
//             ctx.rotate(i * sliceAngle + (sliceAngle / 2)); // Rotate text angle

//             ctx.textAlign = 'center';
//             ctx.fillStyle = 'white'; // Text color
//             ctx.font = '14px Arial';
//             ctx.fillText(currentName, radius / 2, 0); // Set the position of text
//             ctx.restore();
//         }
//     }, [numberOfSections, sectionNames]);

//     return <canvas ref={ref} width={600} height={600} {...props} />;
// };

// export default Canvas;


// import { useEffect, useRef } from "react";

// const Canvas = ({ numberOfSections, sectionNames, rotation, ...props }) => {
//     const ref = useRef();

//     useEffect(() => {
//         const canvas = ref.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / numberOfSections;

//         // Generate an array of random colors for each section
//         let sectionColors = Array.from({ length: numberOfSections }, () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`);

//         // Shuffle colors randomly
//         for (let i = sectionColors.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [sectionColors[i], sectionColors[j]] = [sectionColors[j], sectionColors[i]];
//         }

//         // Check and rotate colors to avoid first and last being the same
//         while (sectionColors[0] === sectionColors[sectionColors.length - 1]) {
//             const lastColor = sectionColors.pop();
//             sectionColors.unshift(lastColor);
//         }

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, i * sliceAngle + (rotation * (Math.PI / 180)), (i + 1) * sliceAngle + (rotation * (Math.PI / 180)));
//             ctx.closePath();

//             const currentColor = sectionColors[i];
//             const currentName = sectionNames[i];

//             ctx.lineWidth = 2;
//             ctx.strokeStyle = currentColor;
//             ctx.fillStyle = currentColor;

//             ctx.stroke();
//             ctx.fill();

//             // Add text - section names
//             ctx.save();
//             ctx.translate(centerX, centerY);
//             ctx.rotate(i * sliceAngle + (rotation * (Math.PI / 180)) + (sliceAngle / 2)); // Rotate text angle

//             ctx.textAlign = 'center';
//             ctx.fillStyle = 'white'; // Text color
//             ctx.font = '14px Arial';
//             ctx.fillText(currentName, radius / 2, 0); // Set the position of text
//             ctx.restore();
//         }
//     }, [numberOfSections, sectionNames, rotation]);

//     return <canvas ref={ref} width={600} height={600} {...props} />;
// };

// export default Canvas;


// import { useEffect, useRef } from "react";

// const Canvas = ({ numberOfSections, sectionNames, rotation, groottePercentage ...props }) => {
//     const canvasRef = useRef();

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / numberOfSections;

//         // Generate an array of random colors for each section
//         let sectionColors = Array.from({ length: numberOfSections }, () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`);

//         // Shuffle colors randomly
//         for (let i = sectionColors.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [sectionColors[i], sectionColors[j]] = [sectionColors[j], sectionColors[i]];
//         }

//         // Check and rotate colors to avoid first and last being the same
//         while (sectionColors[0] === sectionColors[sectionColors.length - 1]) {
//             const lastColor = sectionColors.pop();
//             sectionColors.unshift(lastColor);
//         }

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, i * sliceAngle + (rotation * (Math.PI / 180)), (i + 1) * sliceAngle + (rotation * (Math.PI / 180)));
//             ctx.closePath();

//             const currentColor = sectionColors[i];
//             const currentName = sectionNames[i];

//             ctx.lineWidth = 2;
//             ctx.strokeStyle = currentColor;
//             ctx.fillStyle = currentColor;

//             ctx.stroke();
//             ctx.fill();

//             // Add text - section names
//             ctx.save();
//             ctx.translate(centerX, centerY);
//             ctx.rotate(i * sliceAngle + (rotation * (Math.PI / 180)) + (sliceAngle / 2)); // Rotate text angle

//             ctx.textAlign = 'center';
//             ctx.fillStyle = 'white'; // Text color
//             ctx.font = '14px Arial';
//             ctx.fillText(currentName, radius / 2, 0); // Set the position of text
//             ctx.restore();
//         }
//     }, [numberOfSections, sectionNames, rotation]);

//     return <canvas ref={canvasRef} width={600} height={600} {...props} style={{ transition: "transform 2s ease-in-out" }} />;
// };

// export default Canvas;


// import { useEffect, useRef, useState } from "react";

// const Canvas = ({ numberOfSections, sectionNames, rotation, groottePercentage, ...props }) => {
//     const canvasRef = useRef();
//     const [rotatedDegrees, setRotatedDegrees] = useState(0);

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         const centerX = canvas.width / 2;
//         const centerY = canvas.height / 2;
//         const radius = 300;
//         const sliceAngle = (Math.PI * 2) / 100;
//         console.log(Math.PI);

//         // Clear canvas
//         ctx.clearRect(0, 0, canvas.width, canvas.height);

//         // Generate an array of random colors for each section
//         let sectionColors = Array.from({ length: numberOfSections }, () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`);

//         // Shuffle colors randomly
//         for (let i = sectionColors.length - 1; i > 0; i--) {
//             const j = Math.floor(Math.random() * (i + 1));
//             [sectionColors[i], sectionColors[j]] = [sectionColors[j], sectionColors[i]];
//         }

//         // Check and rotate colors to avoid first and last being the same
//         while (sectionColors[0] === sectionColors[sectionColors.length - 1]) {
//             const lastColor = sectionColors.pop();
//             sectionColors.unshift(lastColor);
//         }

//         for (let i = 0; i < numberOfSections; i++) {
//             ctx.beginPath();
//             ctx.moveTo(centerX, centerY);
//             ctx.arc(centerX, centerY, radius, sliceAngle * groottePercentage[i], (i + 1) * sliceAngle * groottePercentage[i]);
//             ctx.closePath();
//             // console.log(rotatedDegrees)
//             // const savedDegrees = rotatedDegrees;
//             // setRotatedDegrees(sliceAngle * groottePercentage[i]);
//             // console.log(sliceAngle, groottePercentage[i], rotatedDegrees, savedDegrees);
//             const currentColor = sectionColors[i];
//             const currentName = sectionNames[i];

//             ctx.lineWidth = 2;
//             ctx.strokeStyle = currentColor;
//             ctx.fillStyle = currentColor;

//             ctx.stroke();
//             ctx.fill();

           
//             ctx.save();
//             ctx.translate(centerX, centerY);
//             ctx.rotate(i * sliceAngle); 
//             ctx.textAlign = 'center';
//             ctx.fillStyle = 'white';
//             ctx.font = '14px Arial';
//             ctx.fillText(currentName, radius / 2.5, 150); 
//             ctx.restore();
//         }
//     }, [numberOfSections, sectionNames, rotatedDegrees, groottePercentage]);

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         canvas.style.transform = `rotate(${rotation}deg)`;
//     }, [rotation]);

//     return <canvas ref={canvasRef} width={600} height={600} {...props} style={{ transition: "transform 8s ease-in-out" }} />;
// };

// export default Canvas;

// THE ABOVE WAS WORKING CODE

import React, { useEffect, useRef, useState } from "react";

const Canvas = ({ sectionColors, numberOfSections, sectionNames, rotation, groottePercentage, ...props }) => {
    const canvasRef = useRef();
    const [currentRotation, setRotation] = useState(0); 
// console.log(groottePercentage);
    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     const ctx = canvas.getContext('2d');
    //     const centerX = canvas.width / 2;
    //     const centerY = canvas.height / 2;
    //     const radius = 300;
    
    //     ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    //     const sliceAngle = (Math.PI * 2) / 100;
    
    //     for (let i = 0; i < numberOfSections; i++) {
    //         ctx.beginPath();
    //         ctx.moveTo(centerX, centerY);
    //         if(i === 0){
    //             console.log("startAngle = ", 0, "End = ", sliceAngle * groottePercentage[i]);
    //             ctx.arc(centerX, centerY, radius,  0, sliceAngle * groottePercentage[i]);
    //         } else {
    //             let startAngle = 0;

    //     for (let j = 0; j < i; j++) {
    //         startAngle += sliceAngle * groottePercentage[j];
    //     }
    //             console.log("startAngle = ", startAngle, "End = ", startAngle + (sliceAngle * groottePercentage[i]));
    //             ctx.arc(centerX, centerY, radius, startAngle, startAngle + (sliceAngle * groottePercentage[i]));
    //         }
    //         // ctx.arc(centerX, centerY, radius, sliceAngle, sliceAngle + sliceAngle);

    //         ctx.closePath();
            
    //         const currentColor = `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
    //         // const textAngle = startAngle + (sliceAngle * groottePercentage[i] / 2);
    //         // const textX = centerX + Math.cos(textAngle) * (radius / 2);
    //         // const textY = centerY + Math.sin(textAngle) * (radius / 2);
    
    //         // ctx.translate(textX, textY);
    //         // ctx.rotate(textAngle);
    //         // ctx.textAlign = 'center';
    //         // ctx.fillStyle = 'white';
    //         // ctx.font = '14px Arial';
    //         // console.log(sectionNames);
    //         // ctx.fillText(sectionNames[i], 0, 0);
    //         // ctx.restore();
    //         ctx.fillStyle = currentColor;
    //         ctx.fill();
    //         ctx.save();
    //         ctx.translate(centerX, centerY);
    //         ctx.rotate(sliceAngle*groottePercentage[i]);
    //         ctx.textAlign = 'center';
    //         ctx.fillStyle = 'white';
    //         ctx.font = '14px Arial';
    //         console.log(sectionNames)
    //         ctx.fillText(sectionNames[i], radius / 2.5, 150);
    //         ctx.restore();
    //     }
    // }, [numberOfSections, sectionNames]); // useEffect(() => {
    //     if (rotation !== 0) {
    //         const canvas = canvasRef.current;
    //         const ctx = canvas.getContext('2d');
    //         const centerX = canvas.width / 2;
    //         const centerY = canvas.height / 2;
    //         const radius = 300;
    
    //         const totalRounds = 10;
    //         let roundsCounter = 0;
    //         let currentRotation = 0;
    
    //         const rotate = () => {
    //             if (roundsCounter < totalRounds) {
    //                 requestAnimationFrame(() => {
    //                     ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    //                     const sliceAngle = (Math.PI * 2) / 100;
    //                     let startAngle = 0;
    
    //                     for (let i = 0; i < numberOfSections; i++) {
    //                         ctx.save();
    
    //                         const rotationAngle = (currentRotation * Math.PI) / 180;
    //                         ctx.translate(centerX, centerY);
    //                         ctx.rotate(rotationAngle);
    //                         ctx.translate(-centerX, -centerY);
    
    //                         ctx.beginPath();
    //                         ctx.moveTo(centerX, centerY);
    
    //                         const endAngle = startAngle + (sliceAngle * groottePercentage[i]);
    
    //                         console.log("startAngle = ", startAngle, "End = ", endAngle);
    //                         ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    
    //                         ctx.closePath();
    
    //                         const currentColor = `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
    
    //                         ctx.fillStyle = currentColor;
    //                         ctx.fill();
    
    //                         const textAngle = startAngle + (sliceAngle * groottePercentage[i]) / 2;
    //                         const textX = centerX + Math.cos(textAngle) * (radius / 1.5);
    //                         const textY = centerY + Math.sin(textAngle) * (radius / 1.5);
    
    //                         ctx.fillStyle = 'white';
    //                         ctx.font = '14px Arial';
    //                         ctx.textAlign = 'center';
    //                         ctx.textBaseline = 'middle';
    //                         console.log(sectionNames);
    //                         ctx.fillText(sectionNames[i], textX, textY);
    
    //                         ctx.restore();
    
    //                         startAngle = endAngle;
    //                     }
    
    //                     currentRotation += rotationStep; // Adjust the rotation step as needed
    
    //                     rotate();
    //                 });
    //             } else {
    //                 // Stop the rotation after reaching the desired number of rounds
    //                 setRotation(0);
    //             }
    
    //             roundsCounter++;
    //         };
    
    //         const rotationStep = 3.6; // Adjust the rotation step as needed
    //         rotate();
    //     }
    // }, [rotation, numberOfSections, sectionNames, groottePercentage, setRotation]);
    const endAngleFunc = (o, sliceAngle, startAngle) => {
        if(o === numberOfSections -1){
            return Math.PI*2
        } else {
            return startAngle + (sliceAngle * groottePercentage[o])
        }
    }

    

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 300;
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        const sliceAngle = (Math.PI * 2) / 100;
    
        let startAngle = 0;
    
        for (let i = 0; i < numberOfSections; i++) {
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
    
            const endAngle = endAngleFunc(i, sliceAngle, startAngle);
            ctx.arc(centerX, centerY, radius, startAngle, endAngle);
            ctx.lineWidth = 10;
            ctx.stroke();
            // const storedData = localStorage.getItem("wheelColors");
// const userColors = storedData ? JSON.parse(storedData) : {};
const userColors = sectionColors;
// const targetUsername = "desiredUsername";
const targetColor = userColors[sectionNames[i]];
// console.log(targetColor, "YESSASDAS");
            const currentColor = targetColor || `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
            // const currentColor = `hsla(${Math.random() * 360}, 100%, 50%, 1)`;
//   const currentColor = '#bbff00';  

// console.log(currentColor, "YESSASDAS");
            ctx.fillStyle = currentColor;
            ctx.fill();
    
            const textAngle = startAngle + (sliceAngle * groottePercentage[i]) / 2;
            const textX = centerX + Math.cos(textAngle) * (radius / 1.5);
            const textY = centerY + Math.sin(textAngle) * (radius / 1.5);
    
            // Calculate rotation angle based on the quadrant
            let rotationAngle = textAngle;
            // if (textX < centerX) {
            //     rotationAngle += Math.PI; // Flip text on the left side
            // }
    
            ctx.save();
            ctx.translate(textX, textY);
            ctx.rotate(rotationAngle);
            ctx.fillStyle = 'white';
            ctx.font = '14px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(sectionNames[i], 0, 0);
            ctx.restore();
        
                startAngle = endAngle;
            }

            if(numberOfSections === 0) {
                ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            ctx.lineWidth = 10;
    ctx.stroke();
    ctx.fillStyle = "green";
            ctx.fill();
            }
            ctx.beginPath();
ctx.moveTo(centerX, centerY);
ctx.lineTo(centerX + radius, centerY); 
ctx.lineWidth = 5;
ctx.stroke();
            ctx.fillStyle = "#FFFFFF";
            ctx.beginPath();
    ctx.arc(centerX, centerY, 30, 0, 2 * Math.PI);
    ctx.lineWidth = 10;
    ctx.stroke();
    ctx.fill();
    // ctx.beginPath();
    // ctx.arc(centerX + radius, centerY, 20, 2*Math.PI/4, 2 * Math.PI/4*3);
    // ctx.lineWidth = 10;
    // ctx.stroke();
    // ctx.fillStyle = '#ff0000';
    //             ctx.fill();
    // ctx.fill();
//     ctx.beginPath();
// ctx.moveTo(centerX + 30, centerY);
// ctx.lineTo(centerX + radius, centerY); 
// ctx.lineWidth = 5;

// ctx.stroke();
//     ctx.beginPath();
//     ctx.moveTo(centerX + radius + 20, centerY + 15);
//     ctx.lineTo(centerX + radius, centerY);
//     ctx.lineWidth = 5;
// ctx.stroke();
//             ctx.beginPath();
//     ctx.moveTo(centerX + radius + 20, centerY + -15);
//     ctx.lineTo(centerX + radius, centerY);
//     ctx.lineWidth = 5;
// ctx.stroke();
// ctx.beginPath();
//     ctx.moveTo(centerX + 10, centerY + -12);
//     ctx.lineTo(centerX + 30, centerY);
//     ctx.lineWidth = 5;
// ctx.stroke();
// ctx.beginPath();
//     ctx.moveTo(centerX + 10, centerY + 12);
//     ctx.lineTo(centerX + 30, centerY);
//     ctx.lineWidth = 5;
// ctx.stroke();
        }, [numberOfSections, sectionNames, groottePercentage]);

    
        
        
        useEffect(() => {
            if(rotation !== 0){
                const canvas = canvasRef.current;
                const rotationpart = 360-rotation*3.6;
                
            const rotationtotal = (10 * 360) + rotationpart;
            // const rotationtotal = 360;
            // console.log(rotationtotal);
            canvas.style.transform = `rotate(${rotationtotal}deg)`;
            }
            
        }, [rotation]);

        return <canvas ref={canvasRef} width="100%" height="100%" {...props} style={{ transition: "transform 10s ease-in-out", position: 'absolute', zIndex: 1, width:'90%' }} />;
    };

    export default Canvas;

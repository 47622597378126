import {
    Modal,
    Button,
    Card,
    Col,
    Image,
    message,
    Row,
    Space,
    Spin,
    Typography,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { API, AVATAR_API, BEARER } from "../../constants";
import "./betalingen.css";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";
// import { setTimeout } from "timers/promises";

const Betalingen = () => {
    const { user, setUser } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [schulden, setSchulden] = useState([]);
    const [schuldenBetaald, setSchuldenBetaald] = useState([]);
    const [tegoeden, setTegoeden] = useState([]);
    const [tecontrolerentegoeden, setTecontrolerentegoeden] = useState([]);
    const [teontvangen, setTeontvangen] = useState(true);
    const [details, setDetails] = useState([]);
    const [detailsPersoon, setDetailsPersoon] = useState([]);
    const [schuldPersoon, setSchuldPersoon] = useState([]);
    const [schuldBijJou, setSchuldBijJou] = useState(0);
    const [tikkieData, setTikkieData] =  useState("");

    useEffect(() => {
        getToken();
        if (user) { 
            fetchProfile();
            // console.log("hoi");
        }
    }, [user]); 

    const fetchProfile = async () => {
        setIsLoading(true);
    
        try {
            if (user) { 
                const response = await fetch(`${API}/users/${user.id}`, {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${getToken()}`,
                  }
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                setProfiles(data ?? []);
                // console.log("Fetched Profile:", user);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            fetchTegoeden();
           
        }
    };

    const fetchTegoeden = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${API}/payments?filters[naamOntvanger][$eq]=${user.username}&filters[betalerBetaald][$ne]=true`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          })
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setTegoeden(data.data ?? []);
        console.log(data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          // console.log(tegoeden);
          console.log(totaalbedragtegoeden);
          fetchTecontrolerentegoeden();
        }
    }

    const totaalbedragtegoeden = tegoeden.reduce((total, tegoeden) => {
      return parseFloat(total) + parseFloat(tegoeden.attributes.teBetalen);
    }, 0);

    const fetchTecontrolerentegoeden = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API}/payments?filters[naamOntvanger][$eq]=${user.username}&filters[betalerBetaald][$eq]=true`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${getToken()}`,
          }
        })
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setTecontrolerentegoeden(data.data ?? []);
      setTeontvangen(true);
      console.log(data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        // console.log(tegoeden);
        console.log(totaalbedragtegoeden);
      }
  }

  const totaalbedragtecontrolerentegoeden = tecontrolerentegoeden.reduce((total, tegoeden) => {
    return parseFloat(total) + parseFloat(tegoeden.attributes.teBetalen);
  }, 0);

    const fetchSchulden = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${API}/payments?filters[naam][$eq]=${user.username}&filters[betalerBetaald][$ne]=true`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          })
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setSchulden(data.data ?? []);
        setTeontvangen(false);
        console.log(data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          // console.log(schulden);
          fetchSchuldenBetaald();
        }
      }

      const totaalbedragschulden = schulden.reduce((total, tegoeden) => {
        return parseFloat(total) + parseFloat(tegoeden.attributes.teBetalen);
      }, 0);

      const fetchSchuldenBetaald = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`${API}/payments?filters[naam][$eq]=${user.username}&filters[betalerBetaald][$eq]=true`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${getToken()}`,
            }
          })
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setSchuldenBetaald(data.data ?? []);
        setTeontvangen(false);
        console.log(data.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          // console.log(schulden);
        }
      }

      const totaalbedragschuldenbetaald = schuldenBetaald.reduce((total, tegoeden) => {
        return parseFloat(total) + parseFloat(tegoeden.attributes.teBetalen);
      }, 0);

      const setTrueBetaald = async (IDpayment, data) => {
        try {
            const response = await fetch(`${API}/payments/${IDpayment}`, { // Add await keyword here
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                // set the auth token to the user's jwt
                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(data),
            });
        
            const responseData = await response.json(); // Add await keyword here
        
            if (response.ok) {
              // setUser(responseData);
              message.success("Data saved successfully!");
              // console.log("updatakans");
            } else {
              console.error("Error updating user:", responseData);
              message.error("Error While Updating the Profile!");
            }
          } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Profile!");
          } finally {
            fetchProfile();
          }
      }

      const deleteBetaling = async (paymentID, bedrag, data) => {
        try {
            const response = await fetch(`${API}/users/${user.id}`, { 
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
              },
              body: JSON.stringify(data),
            });
        
            const responseData = await response.json();
        
            if (response.ok) {
              // setUser(responseData);
              message.success("Data saved successfully!");
              // console.log("updatakans");
            } else {
              console.error("Error updating user:", responseData);
              message.error("Error While Updating the Profile!");
            }
          } catch (error) {
            console.error(error);
            message.error("Network Error While Updating the Profile!");
          } finally {
        try {
            // Fetch the list of payments first
              const deleteResponse = await fetch(`${API}/payments/${paymentID}`, {
                method: 'DELETE',
                  headers: {
                    'Authorization': `Bearer ${getToken()}`,
                  }
              });
        
              if (deleteResponse.ok) {
                console.log(`Order ${paymentID} deleted successfully`);
              } else {
                console.error(`Error deleting order ${paymentID}:`, deleteResponse);
                message.error(`Error deleting order ${paymentID}!`);
              }
            
        
            message.success('payment deleted successfully!');
          } catch (error) {
            console.error(error);
            message.error('Network error while deleting payments!');
          } finally {
            fetchProfile();
          }
        }
      }

      const addUitgaven = async (bedrag) => {
        
      }

      function setPayed(e) {
        const id = e.target.getAttribute('idkey');
        // console.log("toppie", id)
        const data = {
            'data': {
            betalerBetaald: true,
            ontvangerBevestigd: false,
            }
          };
          setTrueBetaald(id, data);
      }
    
      function bevestigen(e) {
        const id = e.target.getAttribute('idkey');
        const truefalse = e.target.getAttribute('truefalse');
        const bedrag = e.target.getAttribute('bedrag');
        // console.log("toppie", id, truefalse)
        if (truefalse === 'false'){
             const data = {
            'data': {
            betalerBetaald: false,
            ontvangerBevestigd: false,
            }
          };
          setTrueBetaald(id, data);
        } else if(truefalse === 'true'){
            // console.log(profiles.totaalOntvangen);
            // console.log(bedrag);
            const nieuwBedrag = profiles.totaalOntvangen + parseFloat(bedrag);
            // console.log(nieuwBedrag);
        
            deleteBetaling(id, bedrag, {
                totaalOntvangen: nieuwBedrag.toFixed(2),
            });
            addUitgaven(bedrag);
        }
       
      }

       const saveTikkieURL = async (e) => {
        console.log("YEP", details.id, "ja", tikkieData);
        const data = {
          'data': {
          tikkieURL: tikkieData,
          }
        };
        try {
          const response = await fetch(`${API}/payments/${details?.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(data),
          });
      
          const responseData = await response.json();
      
          if (response.ok) {
            // setUser(responseData);
            message.success("Data saved successfully!");
            // console.log("updatakans");
          } else {
            console.error("Error updating user:", responseData);
            message.error("Error While Updating the Profile!");
          }
        } catch (error) {
          console.error(error);
          message.error("Network Error While Updating the Profile!");
        } finally {
          console.log("ITWORKED??")
        }
      }

      async function makeDetailsNone() {
        setDetails([]);
      }

      async function getDetails(e) {
        // const id = e.id;
        // console.log(e.id);
        // const naam = e.username;
        console.log("werktgwn");
        const id = e.target.getAttribute('idpayment');
        const naam = e.target.getAttribute('naaminfo');
        try {
          const response = await fetch(`${API}/payments/${id}`, { // Add await keyword here
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // set the auth token to the user's jwt
              Authorization: `Bearer ${getToken()}`,
            },
          });
      
          const responseData = await response.json(); // Add await keyword here
      
          if (response.ok) {
            // setUser(responseData);
            // console.log("updatakans");
            console.log(responseData.data);
            setDetails(responseData.data);
          } else {
            console.error("Error updating user:", responseData);
            message.error("Error While Updating the Profile!");
          }
        } catch (error) {
          console.error(error);
          
        } finally {
          try {
            const response = await fetch(`${API}/users?filters[username][$eq]=${naam}`, { // Add await keyword here
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                // set the auth token to the user's jwt
                Authorization: `Bearer ${getToken()}`,
              },
            });
        
            const responseData = await response.json(); // Add await keyword here
        
            if (response.ok) {
              // setUser(responseData);
              console.log("updatakans");
              setDetailsPersoon(responseData);
              console.log(responseData);
            } else {
              console.error("Error updating user:", responseData);
              message.error("Error While Updating the Profile!");
            }
          } catch (error) {
            console.error(error);
            
          } finally {
            try {
              const response = await fetch(`${API}/payments?filters[naam][$eq]=${naam}`, { // Add await keyword here
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  // set the auth token to the user's jwt
                  Authorization: `Bearer ${getToken()}`,
                },
              });
          
              const responseData = await response.json(); // Add await keyword here
          
              if (response.ok) {
                // setUser(responseData);
                console.log("updatakans");
                setSchuldPersoon(responseData.data);
                

                console.log(responseData.data);
              } else {
                console.error("Error updating user:", responseData);
                message.error("Error While Updating the Profile!");
              }
            } catch (error) {
              console.error(error);
              
            } finally {
               
            }
          }
        }
      }

      const handleChange = (e) => {
        const tikkieData = e.target.value;
        setTikkieData(tikkieData);
    };

   return (
    <div id="betalingcontent">
        {/* <div className="schulden">
        <label>schulden bevestigd door jou:</label>
      <ul>
      {
          (schulden ?? []).map((schuld) => 
          {if (schuld.attributes.betalerBetaald === true && schuld.attributes.ontvangerBevestigd === false){
            return(<li key={schuld.id} className="listschulden">je hebt bevestigd dat je <b> €{schuld.attributes.teBetalen} </b> hebt betaald aan {schuld.attributes.naamOntvanger}. Nu wachten op bevestiging door {schuld.attributes.naamOntvanger}</li>)
          }} 
          )
        }
        </ul>
        <label>te betalen schulden:</label>
      <ul>
      {
          (schulden ?? []).map((schuld) => 
          {if (schuld.attributes.betalerBetaald !== true){
            return(<li key={schuld.id} className="listschuldenorange">je hebt <b> €{schuld.attributes.teBetalen} </b> schuld bij {schuld.attributes.naamOntvanger} door een bestelling op  {schuld.attributes.createdAt.split("T")[0]} <button idkey={schuld.id} className="buttonbetaald" onClick={setPayed}>Ik heb betaald</button></li>)
          }} 
          )
        }
      </ul>
      
        <label>betalingen afgewezen door ontvanger:</label>
        <ul>
        {
          (schulden ?? []).map((schuld) => 
          {if (schuld.attributes.ontvangerBevestigd === true){
            return(<li key={schuld.id} className="listschuldenred">je hebt bevestigd dat je <b> €{schuld.attributes.teBetalen} </b> hebt betaald aan {schuld.attributes.naamOntvanger}. Maar het is afgewezen door {schuld.attributes.naamOntvanger}<button idkey={schuld.id} className="buttonbetaald" onClick={setPayed}>Betaal echt</button></li>)
          }} 
          )
        }
        </ul>
        </div><br></br>
        <div className="tegoeden">
            <label>te controleren:</label>
            <ul>
            {
          (tegoeden ?? []).map((tegoed) => 
          {if (tegoed.attributes.betalerBetaald === true && tegoed.attributes.ontvangerBevestigd !== true){
            return(<li key={tegoed.id} className="listschulden">{tegoed.attributes.naam} heeft €{tegoed.attributes.teBetalen} aan je betaald. kun je dit controleren?<button bedrag={tegoed.attributes.teBetalen} idkey={tegoed.id} truefalse={'true'} className="controleerknop" onClick={bevestigen}>klopt</button><button bedrag={tegoed.attributes.teBetalen} idkey={tegoed.id} truefalse={'false'} className="controleerknopfout" onClick={bevestigen}>klopt niet</button></li>)
          }} 
          )
        }
        </ul>
        <label>te ontvangen:</label>
            <ul>
            {
          (tegoeden ?? []).map((tegoed) => 
          {if (tegoed.attributes.betalerBetaald !== true){
            return(<li key={tegoed.id} className="listschulden">{tegoed.attributes.naam} moet je nog €{tegoed.attributes.teBetalen} betalen van een order op {tegoed.attributes.createdAt.split("T")[0]}. </li>)
          }} 
          )
        }
        </ul>
        </div> */}
        {teontvangen === true && (
  <div className={ details.id ? "betalingleftdetails" : "betalingleft"}>
    <div className="betalingheader">
      <p className="ontvangen" onClick={fetchTegoeden}>te ontvangen:</p>
      <p className="betalen" onClick={fetchSchulden}>te betalen:</p>
    </div>
    {
      (tegoeden ?? []).map((tegoed) => {
        if (tegoed.attributes.betalerBetaald !== true) {
          console.log(details?.id, tegoed?.id);
          return (
            <div className={details.id === tegoed.id ? "listbetalingenhover" : "listbetalingen"} naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails}>
              <p naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails} className="naam">{tegoed.attributes.naam}</p>
              <p naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails} className="bedrag">€{tegoed.attributes.teBetalen.toFixed(2)}</p>
            </div>
          );
        }
      })
    }
    <div className="totaal">
      <p className="totaaltext">Totaal:</p>
      <p className="totaalbedrag">€{totaalbedragtegoeden.toFixed(2)}</p>
    </div>

    <div className="betalingheader">
      <p className="headerunderline">te controleren:</p>
    </div>
    {
      (tecontrolerentegoeden ?? []).map((tegoed) => {
        if (tegoed.attributes.betalerBetaald === true && tegoed.attributes.ontvangerBevestigd !== true) {
          console.log(details?.id, tegoed?.id);
          return (
            <div className={details.id === tegoed.id ? "listbetalingenhover" : "listbetalingen"} naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails}>
              <p naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails} className="naam">{tegoed.attributes.naam}</p>
              <p naaminfo={tegoed.attributes.naam} idpayment={tegoed.id} onClick={getDetails} className="bedrag">€{tegoed.attributes.teBetalen.toFixed(2)}</p>
            </div>
          );
        }
      })
    }
    <div className="totaal">
      <p className="totaaltext">Totaal:</p>
      <p className="totaalbedrag">€{totaalbedragtecontrolerentegoeden.toFixed(2)}</p>
    </div>
  </div>
)}
{teontvangen === false && (
  <div className={ details.id ? "betalingleftdetails" : "betalingleft"}>
    <div className="betalingheader">
      <p className="betalen" onClick={fetchTegoeden}>te ontvangen:</p>
      <p className="ontvangen" onClick={fetchSchulden}>te betalen:</p>
    </div>
    {
      (schulden ?? []).map((tegoed) => {
        if (tegoed.attributes.betalerBetaald !== true) {
          return (
            <div className={details.id === tegoed.id ? "listbetalingenhover" : "listbetalingen"} naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails}>
              <p naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails} className="naam">{tegoed.attributes.naamOntvanger}</p>
              <p naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails} className="bedrag">€{tegoed.attributes.teBetalen.toFixed(2)}</p>
            </div>
          );
        }
      })
    }
    <div className="totaal">
      <p className="totaaltext">Totaal:</p>
      <p className="totaalbedrag">€{totaalbedragschulden.toFixed(2)}</p>
    </div>

    <div className="betalingheader">
      <p className="headerunderline">wacht op bevestiging:</p>
    </div>
    {
      (schuldenBetaald ?? []).map((tegoed) => {
        if (tegoed.attributes.betalerBetaald === true && tegoed.attributes.ontvangerBevestigd !== true) {
          return (
            <div className={details.id === tegoed.id ? "listbetalingenhover" : "listbetalingen"} naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails}>
              <p naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails} className="naam">{tegoed.attributes.naamOntvanger}</p>
              <p naaminfo={tegoed.attributes.naamOntvanger} idpayment={tegoed.id} onClick={getDetails} className="bedrag">€{tegoed.attributes.teBetalen.toFixed(2)}</p>
            </div>
          );
        }
      })
    }
    <div className="totaal">
      <p className="totaaltext">Totaal:</p>
      <p className="totaalbedrag">€{totaalbedragschuldenbetaald.toFixed(2)}</p>
    </div>
  </div>
)}
<div className={details.id ? "betalingrightwithdetails" : "betalingright"}>
  {/* te ontvangen */}
  <div className="betalingheader">
    <p className="arrowbackbetalingen" onClick={makeDetailsNone}>&#x2190;</p>
    <p className="headerunderline">details:</p>
  </div>
  <div className="betalingheader">
      <p className="detailsleft">order info:</p>
      


    </div>
    <div className="betalingheader">
    {details ? (
  <div className="detailsleftlist">
    <div className="detailsleftdiv">
    <p className="onderwerp">bedrag:</p>
  <p className="">€{details.attributes?.teBetalen.toFixed(2)}</p>
  </div>
  <div className="detailsleftdiv">
    <p className="onderwerp">van:</p>
  <p className="">{details.attributes?.naam}</p>
  </div>
  <div className="detailsleftdiv">
    <p className="onderwerp">aan:</p>
  <p className="">{details.attributes?.naamOntvanger}</p>
  </div>
  <div className="detailsleftdiv">
    <p className="onderwerp">op:</p>
    {details.attributes?.createdAt && (
        <p className="">{new Date(details.attributes?.createdAt).toLocaleDateString('nl-NL')}</p>
      )}
  </div>
  <div className="detailsleftdiv">
    <p className="onderwerp">tikkie URL:</p>
    {details.attributes?.tikkieURL === null && (
      <p className="">nog niet ingevoerd</p>
    )}
    {details.attributes?.tikkieURL !== null && (
      <p className="tikkieurlafgekort" ><a target="_blank" href={details.attributes?.tikkieURL}>{details.attributes?.tikkieURL}</a></p>
    )}

  </div>
  </div>
  
) : (
  <div className="detailsleft">No details</div>
)}

      {/* {detailsPersoon && detailsPersoon.length > 0 ? (
  <div className="detailsrightlist">
    <div className="detailsrightdiv">
    <p className="onderwerp">schulden:</p> 
    <p>{schuldPersoon.length}</p>
    </div>
    <div className="detailsrightdiv">
    <p className="onderwerp">bij jou:</p> 
    <p>{schuldPersoon.reduce((total, tegoeden) => {
                if (tegoeden.attributes.naamOntvanger === user.username) {
                  return parseFloat(total) + 1;
                } else {
                return parseFloat(total);
                }
              }, 0)}</p>
    </div>
    <div className="detailsrightdiv">
    <p className="onderwerp">in euros:</p> 
    <p>€{schuldPersoon.reduce((total, tegoeden) => {
                if (tegoeden.attributes.naamOntvanger === user.username) {
                  return parseFloat(total) + parseFloat(tegoeden.attributes.teBetalen);
                } else {
                return parseFloat(total);
                }
              }, 0).toFixed(2)}</p>
    </div>
    </div>
) : (
  <div className="detailsrightlist">No details available</div>
)} */}

    </div>
    <div className="detailsuitleg">status</div>
    {details.attributes?.betalerBetaald === false && (
    <div>{details.attributes?.naam} heeft nog niet betaald: {details.attributes?.naamOntvanger === user?.username && (
      <a href={`mailto:${detailsPersoon?.email}`}>stuur e-mail</a>
    )}
    {details.attributes?.naam === user?.username && details.attributes.tikkieURL !== null && (
      <a href={details.attributes?.tikkieURL}>betaal nu</a>
    )} </div>
    )}
    {details.attributes?.betalerBetaald === true && (
    <div>{details.attributes?.naam} heeft al betaald, <br /> {details.attributes?.naamOntvanger} moet het bevestigen</div>
    )}
    {/* {details.attributes?.tikkieURL === null && (
      <div className="tikkiecopie">{details.attributes?.naamOntvanger} heeft nog geen tikkie URL ingevoerd</div>
    )} */}
    {/* {details.attributes?.tikkieURL !== null && (
      <div className="tikkiecopie">tikkie url: {details.attributes?.tikkieURL}</div>
    )} */}
    {details.attributes?.naam === user?.username && details.attributes?.betalerBetaald === false ? (
      <div>
      <div className="detailsuitleg">betalen</div>
      <div className="betalingheader"><p className="betalingcontent">Heb je betaald?</p><p className="betalingcontent"><button idkey={details.id} onClick={setPayed} className="buttonbetalingen">bevestigen</button></p></div>
      </div>
    ) : details.attributes?.naamOntvanger === user?.username && details.attributes?.betalerBetaald === true ? (
      <div>
      <div className="detailsuitleg">controleren</div>
      <div className="betalingheadergrey">heeft {details.attributes.naam} echt {details.attributes.teBetalen.toFixed(2)} aan je betaald?</div>
      <div className="detailsbuttons">
      <button bedrag={details.attributes.teBetalen} idkey={details.id} truefalse={'false'} className="controlerenknopnee" onClick={bevestigen}>klopt niet</button><button bedrag={details.attributes.teBetalen} idkey={details.id} truefalse={'true'} className="controlerenknopja" onClick={bevestigen}>klopt</button>
      </div></div>
      
    ) : details.attributes?.naam !== undefined && details.attributes?.naamOntvanger === user?.username ? (
      <div>
      <div className="detailsuitleg">aanpassen</div>
      <div className="tikkieinvoer">tikkie URL: 
      {/* <form class="c-form" action="">
    <input class="c-form__input" placeholder="vul URL in" value={tikkieData || details.attributes?.tikkieURL || ""} onChange={handleChange} type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required />
    <label class="c-form__buttonLabel" for="checkbox">
      <button class="c-form__button" type="button">Send</button>
    </label>
  </form> */}
  <div class="c-formContainer">
  <form class="c-form" action="">
    <input class="c-form__input" placeholder="vul URL in" value={tikkieData || details.attributes?.tikkieURL || ""} onChange={handleChange} required />
    <label class="c-form__buttonLabel" for="checkbox">
      <button class="c-form__button" type="button" datakey={details?.id} key={details?.id} value={details?.id} onClick={saveTikkieURL}>Save</button>
    </label>
  </form>
</div>
  {/* <input type="text" placeholder="vul URL in" value={tikkieData || details.attributes?.tikkieURL || ""} onChange={handleChange} /><button className="smallsavebutton" datakey={details?.id} key={details?.id} value={details?.id} onClick={saveTikkieURL}>save</button> */}
  </div>
      <div className="betalingheadergrey">heeft {details.attributes.naam} al {details.attributes.teBetalen.toFixed(2)} aan je betaald?</div>
      <div className="detailsbuttons">
      <button bedrag={details.attributes.teBetalen} idkey={details.id} truefalse={'false'} className="controlerenknopnee" onClick={bevestigen}>nee</button><button bedrag={details.attributes.teBetalen} idkey={details.id} truefalse={'true'} className="controlerenknopja" onClick={bevestigen}> ja </button>
      </div></div>
    ) : (
      <div>
      <div className="detailsuitleg">aanpassen</div>
      <div className="betalingheadergrey">je bent niet gemachtigt dit aan te passen</div>
      </div>
    )}
    <div className="opties"></div>
</div>
</div>
);
}

export default Betalingen;